import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { ReactComponent as BlackArrow } from 'assets/svgs/BlackDropdownArrow.svg';
import ProfileDropdown from 'components/shared/ProfileDropdown/ProfileDropdown';
import { routes } from 'shared/routes';

import ProfileInfo from '../ProfileInfo/ProfileInfo';

import styles from './ProfileIcon.module.scss';
export interface IProfileProps {
  imageSrc?: string;
  name: string;
  organizationName?: string;
  className?: string;
  profileImgStyling?: string;
  isProfileSet?: boolean;
  isNonProfilePage?: boolean;
}

const Profile = ({
  imageSrc,
  name,
  className,
  profileImgStyling,
  organizationName,
  isProfileSet = true,
  isNonProfilePage = true,
}: IProfileProps): JSX.Element => {
  const location = useLocation();

  return (
    <ProfileDropdown isProfileSet={location.pathname === routes.organizations ? false : isProfileSet}>
      <div className={classNames(styles.profileContainer, className)}>
        {isNonProfilePage ? (
          location.pathname === routes.organizations ? (
            <ProfileInfo imageSrc={imageSrc} name={name} profileImgStyling={profileImgStyling} />
          ) : (
            <ProfileInfo
              imageSrc={imageSrc}
              organizationName={organizationName}
              name={name}
              profileImgStyling={profileImgStyling}
            />
          )
        ) : (
          <BlackArrow className={styles.WebDropDownArrow} />
        )}
      </div>
    </ProfileDropdown>
  );
};

export default Profile;
