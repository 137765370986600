import { useTranslation } from 'react-i18next';

import AgentPills, { PillTypes } from 'components/shared/AgentPills/AgentPills';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import { formatAmount } from 'helpers/user';
import { IncomeCapsuleProps } from 'shared/types/applicantsType';

import styles from './IncomeCapsule.module.scss';
export const IncomeCapsule = ({ income, hasIncomeStatus, onIncomeClickHandler }: IncomeCapsuleProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.CapsuleContainer}>
      <Tooltip
        placement="top"
        text={t('agent.viewDetails')}
        icon={
          <button className={styles.IncomeButton} onClick={income ? onIncomeClickHandler : undefined}>
            {income ? (
              <div className={styles.Income}>{`$${formatAmount(income)}`}</div>
            ) : (
              <div className={styles.EmptyState}>{'___'}</div>
            )}
            <div className={styles.LabelContainer}>
              <div className={styles.CapsuleLabel}>{t('agent.applications.applicant.occupant.income')}</div>
              {!!hasIncomeStatus && (
                <AgentPills
                  pillContainerClassName={styles.PillContainer}
                  pillsData={[{ heading: t('rental.historyInformation.pending'), type: PillTypes.WARNING }]}
                />
              )}
            </div>
          </button>
        }
        customIconClassName={styles.CountContainer}
      />
    </div>
  );
};
