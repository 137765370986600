import { FunctionComponent, SVGProps } from 'react';

import { ReactComponent as ArchivedIcon } from '../assets/svgs/ArchivedIcon.svg';
import { ReactComponent as CheckIcon } from '../assets/svgs/CheckIcon.svg';
import { ReactComponent as DeniedIcon } from '../assets/svgs/DeniedIcon.svg';
import { ReactComponent as HomeIcon } from '../assets/svgs/HomeIcon.svg';
import { ReactComponent as InvitedIcon } from '../assets/svgs/InvitedIcon.svg';
import { ReactComponent as ProfileIcon } from '../assets/svgs/ProfileIcon.svg';

import { ApplicationRequestValues } from './applicationConstants';
export interface OptionProps {
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  name: string;
}
export interface StatusProps {
  label: string;
  name: string;
}
/*This code defines an array of objects that represent applicant options with their
 * corresponding icons and names.
 */
export const ApplicantOptions: OptionProps[] = [
  { Icon: ProfileIcon, name: 'Active' },
  { Icon: CheckIcon, name: 'Approved' },
  { Icon: DeniedIcon, name: 'Denied' },
  { Icon: ArchivedIcon, name: 'Archived' },
  { Icon: InvitedIcon, name: 'Leads' },
  { Icon: InvitedIcon, name: 'Legacy' },
];
export const ApplicantStatus = [
  { label: 'Active', name: 'Active' },
  { label: 'Approved', name: 'Approved' },
  { label: 'Denied', name: 'Denied' },
  { label: 'Archived', name: 'Archived' },
  { label: 'Leads', name: 'Leads' },
  { label: 'Legacy', name: 'Legacy' },
];

export const ApplicationRequestTypes = [
  { label: 'Application & reports', value: ApplicationRequestValues.APPLICATION_AND_REPORTS },
  { label: 'Reports only', value: ApplicationRequestValues.REPORTS_ONLY },
  { label: 'Verify identity', value: ApplicationRequestValues.VERIFY_IDENTITY },
];

/*This code defines an array of objects that represent properties options with their
 * corresponding icons and names.
 */
export const PropertyOptions: OptionProps[] = [
  { Icon: HomeIcon, name: 'Published' },
  { Icon: CheckIcon, name: 'Draft' },
  { Icon: ArchivedIcon, name: 'Archived' },
];
export const PropertyStatus = [
  { label: 'Published', name: 'Published' },
  { label: 'Draft', name: 'Draft' },
  { label: 'Archived', name: 'Archived' },
];
export const PropertyActions = [
  { label: 'Archive', name: 'Archieve property' },
  { label: 'Clone', name: 'Clone property details' },
];

// accepted status active, inactive, archived, denied, approved
export const ApplicationActionOptions = [
  { value: 'export', label: 'Export' },
  { value: 'move-files', label: 'MOVE FILE TO', isDummy: true },
  { value: 'active', label: 'Active' },
  { value: 'approved', label: 'Approved' },
  { value: 'denied', label: 'Denied' },
  { value: 'archived', label: 'Archived' },
];

export const DenialApplicationActionOptions = [
  { value: 'export', label: 'Export' },
  { value: 'move-files', label: 'MOVE FILE TO', isDummy: true },
  { value: 'active', label: 'Active' },
  { value: 'approved', label: 'Approved' },
  { value: 'denied', label: 'Denied' },
  { value: 'archived', label: 'Archived' },
  { value: 'denialLetter', label: 'Denial Letter' },
];

export const ExportResumeOptions = [
  { value: 'pdf', label: 'PDF' },
  { value: 'csv', label: 'CSV' },
];
export const PropertyActionStatus = [
  { value: 'published', label: 'Published' },
  { value: 'draft', label: 'Draft' },
  { value: 'archived', label: 'Archived' },
];
export const PhoneNumOptions = [
  { value: 'published', label: 'Published' },
  { value: 'draft', label: 'Draft' },
  { value: 'archived', label: 'Archived' },
];
