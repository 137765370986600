import { Dispatch, SetStateAction, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { pdf } from '@react-pdf/renderer';
import classNames from 'classnames';
import { Packer } from 'docx';
import saveAs from 'file-saver';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { t } from 'i18next';
import lodash from 'lodash';
import { AdversePDF } from 'pages/AgentPofileForm/AdverseForm/AdversePDF';
import { AdverseWord } from 'pages/AgentPofileForm/AdverseForm/AdverseWord';
import { selectAgentProfileInformation } from 'redux/selectors/agentSelector';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { removePropertyApplication } from 'redux/slices/agentSlice';
import { adverseFormvalidationSchema } from 'schema/agentSchema';

import GreyCross from 'assets/images/GreyCross.svg';
import { ReactComponent as OpenInNew } from 'assets/svgs/open_in_new.svg';
import { ReactComponent as Vector } from 'assets/svgs/Vector.svg';
import { RenterInformationHeader } from 'components/Agent/Applications/ApplicationDetails/components/RenterIncomeReports/RenterInformationHeader/RenterInformationHeader';
import AgentModal from 'components/Agent/components/AgentModal/AgentModal';
import RCButton from 'components/shared/Button/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import Spinner from 'components/shared/Spinner/Spinner';
import { adverseReasonActions, downloadOptions } from 'constants/agentConstants';
import { formatRenterName } from 'helpers/agentHelper';
import { denialLetterUpload, sendDenialLetterEmail } from 'services/agentService';
import { Notification } from 'shared/Notification/Notification';
import { ApplicationActions, PropertyApplicationStatus } from 'shared/types/agentTypes';
import { ApplicationType } from 'shared/types/applicantsType';
import { RenterRoles } from 'shared/types/renterTypes';

import styles from './AdverseActionLetterForm.module.scss';

type AdverseActionLetterFormProps = {
  totalOccpants: number | string;
  propertyAddress?: string;
  status: PropertyApplicationStatus | undefined | string;
  application: ApplicationType;
  renterFirstName: string | undefined;
  renterLastName: string | undefined;
  setIsAdverseFormModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsStatusModalOpen: Dispatch<SetStateAction<boolean>>;
  applicationId: number;
  propertyId: number | undefined | string;
  currentAction: ApplicationActions;
  setCurrentAction: Dispatch<SetStateAction<ApplicationActions>>;
};

type RenterOptionsType = {
  value: string;
  label: string;
};

export const AdverseActionLetterForm = ({
  totalOccpants,
  propertyAddress,
  status,
  application,
  renterFirstName,
  renterLastName,
  setIsAdverseFormModalOpen,
  setIsStatusModalOpen,
  propertyId,
  applicationId,
  currentAction,
  setCurrentAction,
}: AdverseActionLetterFormProps): JSX.Element => {
  const dispatch = useDispatch();
  const modalNavigationHandler = (): void => {
    setIsAdverseFormModalOpen(false);
    setIsStatusModalOpen(true);
  };
  const profileInformation = useSelector(selectAgentProfileInformation);
  const { id: organizationId } = useSelector(selectedOrganization);
  const selectedRenterAppName = (app: ApplicationType, index: number): string => {
    const name = formatRenterName(
      (app?.firstName || app?.renterFirstName) ?? '',
      '',
      (app?.lastName || app?.renterLastName) ?? ''
    );
    const role = lodash.capitalize(app.renterRole);

    return app.renterRole === RenterRoles.OCCUPANTS && app?.adults?.length > 1
      ? `${name} | ${role} ${index + 1}`
      : `${name} | ${role}`;
  };
  const renterOptions: RenterOptionsType[] = [
    {
      value: 'option1',
      label: `${renterFirstName} ${renterLastName} | ${
        application.renterRole === RenterRoles.PRIMARY
          ? t('agent.adverseForm.primaryOccupant')
          : lodash.capitalize(application.renterRole)
      }`,
    },
    ...(application?.associatedApplications
      ? application.associatedApplications.map((app, index) => ({
          value: `option${index + 2}`,
          label: `${selectedRenterAppName(app, index)}`,
        }))
      : []),
  ];
  const VerifierDetailsOnVerificationInitialValues = {
    renterField: renterOptions.map((renter) => renter.label),
    checkedActions: [] as string[],
    otherField: '',
    comments: '',
    downloadType: t('agent.adverseForm.downloadPDF'),
  };
  const [isSendDenialDisable, setIsSendDenialDisable] = useState(false);
  const [isSubmitting2, setIsSubmitting2] = useState(false);
  const sendDenialLetterHandler = (isValid: boolean): void => {
    setIsSubmitting2(true);

    if (!isValid) {
      Notification({
        message: 'All required fields must be filled out before sending the email.',
        type: 'info',
      });
      setIsSubmitting2(false);

      return;
    }

    sendDenialLetterEmail(applicationId, organizationId)
      .then(() => {
        setIsSendDenialDisable(true);
        Notification({
          message: 'The email has been sent successfully.',
          type: 'info',
        });
      })
      .catch((err) => {
        console.log(err);
        Notification({
          message: 'An error occurred while sending the email.',
          type: 'info',
        });
      })
      .finally(() => {
        setIsSubmitting2(false);
      });
  };

  return (
    <AgentModal
      show
      onHide={() => {
        dispatch(removePropertyApplication({ propertyId, applicationId }));
        setIsStatusModalOpen(false);
        setIsAdverseFormModalOpen(false);
        setCurrentAction(ApplicationActions.ACTIVE);
        setIsSendDenialDisable(false);
      }}
      size="sm"
      title={`Generate Denial/Adverse Action Letter`}
      buttonText={t('application.cancel')}
      crossButtonClassName={styles.CustomButton}
      customTitleClassName={styles.Title}
      customContainerClassName={styles.CustomCLass}
      customHeaderClassName={styles.CustomHeader}
    >
      <>
        <RenterInformationHeader
          renterName={`${renterFirstName ? renterFirstName : '____'} ${renterLastName ? renterLastName : ''}`}
          totalOccupants={totalOccpants}
          propertyAddress={propertyAddress}
          status={status}
          applicationStatus={application.fileApplication.status}
          customStatusClassName={styles.Denied}
        />
        <Formik
          initialValues={VerifierDetailsOnVerificationInitialValues}
          validationSchema={adverseFormvalidationSchema}
          validateOnBlur={true}
          validateOnMount={true}
          onSubmit={(values) => {
            let blob: Blob;
            const pdfUploadPromises = values.renterField.map(async (renter, index) => {
              const Documentbody = {
                ...values,
                renterField: renter,
                profileInformation: profileInformation,
                propertyAddress: propertyAddress ?? '',
              };

              if (values.downloadType === 'Download PDF') {
                blob = await pdf(<AdversePDF values={Documentbody} />).toBlob();
              } else {
                blob = await Packer.toBlob(
                  AdverseWord({
                    values: Documentbody,
                  })
                );
              }

              const associatedApplicationId =
                index === 0 ? application.id : application.associatedApplications[index - 1].id;

              await denialLetterUpload(associatedApplicationId, organizationId, blob)
                .then(() => {
                  saveAs(blob, `${renter.split(' | ')[0]} ${t('agent.adverseForm.adverseForms')}`);
                })
                .catch((err) => {
                  console.error(err);
                });
            });

            Promise.all(pdfUploadPromises).then(() => {
              dispatch(removePropertyApplication({ propertyId, applicationId }));
              setIsStatusModalOpen(false);
              setIsAdverseFormModalOpen(false);
              setIsSendDenialDisable(false);
              Notification({ message: 'The Adverse Form/s have been downloaded', type: 'info' });
            });
          }}
        >
          {({ values, setFieldValue, errors, touched, isSubmitting, isValid }) => (
            <Form className={styles.Formcontainer}>
              <div className={styles.TimeLogcontainer}>
                <p className={styles.TimeLogTitle}>{t('agent.adverseForm.fileLogs')}</p>
                <div className={styles.InnerTimeLogcontainer}>
                  <div className={styles.MiniTimeLogcontainer}>
                    <p className={styles.TimeLogP1}>{t('agent.adverseForm.movedDenied')}</p>
                    <p className={styles.TimeLogP2}>by Teresa Jones</p>
                  </div>
                  <p className={styles.TimeLogP2}>Sept 01 at 12:31 PM</p>
                </div>
                <div className={styles.InnerTimeLogcontainer}>
                  <div className={styles.MiniTimeLogcontainer}>
                    <p className={styles.TimeLogP1}>{t('agent.adverseForm.movedDenied')}</p>
                    <p className={styles.TimeLogP2}>by Teresa Jones</p>
                  </div>
                  <p className={styles.TimeLogP2}>Sept 01 at 12:31 PM</p>
                </div>
              </div>
              <p className={styles.FormHeading}>{t('agent.adverseForm.addressLetter')}</p>
              <p className={styles.FormPara}>{t('agent.adverseForm.actionLetterStatement')}</p>
              <div>
                <div className={styles.DropDownContainer}>
                  <div className={styles.RenterText}>Renter</div>
                  <div>
                    <ReactSelect
                      options={renterOptions}
                      value={{ label: 'Select', value: 'Select' }}
                      className={styles.ReactSelect1}
                      onChange={(input) => {
                        if (input) {
                          if (!values.renterField.includes(input.label)) {
                            setFieldValue('renterField', [...values.renterField, input.label]);
                          }
                        }
                      }}
                    />
                    <ErrorMessage name="renterField" component="div" className={styles.ErrorMessageFormik} />
                  </div>
                </div>
                <div className={styles.CrossOptions}>
                  {values.renterField.map((renter, index) => {
                    const [name, role] = renter.split(' | ');

                    return (
                      <div key={index + 'outter'} className={styles.OptionButtonContainer}>
                        <button
                          className={styles.OptionButtonDesign}
                          onClick={() =>
                            setFieldValue(
                              'renterField',
                              values.renterField.filter((item) => item !== renter)
                            )
                          }
                        >
                          <img src={GreyCross} alt="cross" />
                        </button>
                        <div key={`cross-${index}`}>
                          <span className={styles.NameStyle}>{name} </span>
                          <span className={styles.RoleStyle}>| {role}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <p className={styles.FormHeading}>{t(`agent.adverseForm.reasonsAction`)}</p>
              <p className={styles.FormPara}>
                Only selected options will be shown on the action letter. At least one Action is required.
              </p>
              <Row style={{ marginLeft: '0px' }}>
                {adverseReasonActions.map((action, index) => (
                  <Col md={4} sm={12} key={`adverse action: ${index}`} className={styles.CheckBoxFields}>
                    <Field
                      as={Checkbox}
                      className={styles.CheckboxLabel}
                      name="checkedActions"
                      label={action.label}
                      value={action.value}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        e.target.checked
                          ? setFieldValue('checkedActions', [...values.checkedActions, e.target.value])
                          : setFieldValue(
                              'checkedActions',
                              values.checkedActions.filter((item) => item !== e.target.value)
                            )
                      }
                      checked={values.checkedActions?.includes(action.value)}
                    />
                  </Col>
                ))}
              </Row>
              <p className={styles.FormHeading}>{t(`agent.adverseForm.adverseOther`)}</p>
              <div className={styles.InputFieldOutterDiv}>
                <Field
                  id="other"
                  name="otherField"
                  type="text"
                  className={classNames(styles.InputField, {
                    [styles.InputError]: errors.otherField && touched.otherField,
                  })}
                />
                <ErrorMessage name="otherField" component="div" className={styles.ErrorMessageFormik} />
              </div>
              <p className={styles.FormHeading}>{t(`agent.adverseForm.optional`)}</p>
              <p className={styles.FormPara}>{t(`agent.adverseForm.textWillIncluded`)}</p>
              <div className={styles.InputFieldOutterDiv}>
                <Field
                  id="AdComments"
                  name="comments"
                  type="text"
                  className={classNames(styles.InputField, {
                    [styles.InputError]: errors.comments && touched.comments,
                  })}
                />
                <ErrorMessage name="comments" component="div" className={styles.ErrorMessageFormik} />
              </div>
              <p className={styles.FormPara}>{t(`agent.adverseForm.statement`)}</p>
              <div className={styles.buttonOutterDiv2}>
                <div className={styles.buttonInnerDiv}>
                  <button
                    className={isValid ? styles.submitButton : styles.submitButtonDisabled}
                    type="submit"
                    disabled={isSubmitting || !isValid}
                  >
                    {!isSubmitting ? values.downloadType : <Spinner />}
                  </button>
                  <ReactSelect
                    options={downloadOptions}
                    onChange={(input) => {
                      if (input) {
                        setFieldValue('downloadType', input.label);
                      }
                    }}
                    className={styles.ReactSelect2}
                    {...(isValid
                      ? {
                          dropDownIcon: <Vector />,
                          dropDownIconContainer: styles.dropDownIconContainer,
                          borderStyle: '1px solid #303333',
                        }
                      : {})}
                  />
                </div>
              </div>
              <br />
              <div
                className={classNames(styles.buttonOutterDiv, {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  'justify-content-end': currentAction === ApplicationActions.DENIAL_LETTER,
                })}
              >
                <RCButton
                  className={classNames(styles.ReportButton, {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'd-none': currentAction === ApplicationActions.DENIAL_LETTER,
                  })}
                  variant="outline"
                  onClick={modalNavigationHandler}
                  disabled={isSubmitting}
                >
                  {t(`agent.adverseForm.goBack`)}
                </RCButton>
                <RCButton
                  className={classNames(styles.SendDenialButton, {
                    [styles.SendDenialButtonDisabled]: isSendDenialDisable,
                  })}
                  variant="outline"
                  disabled={isSendDenialDisable}
                  onClick={() => {
                    sendDenialLetterHandler(isValid);
                  }}
                >
                  {isSubmitting2 ? (
                    <Spinner />
                  ) : (
                    <>
                      {t('agent.adverseForm.send')} <OpenInNew />
                    </>
                  )}
                </RCButton>
              </div>
            </Form>
          )}
        </Formik>
      </>
    </AgentModal>
  );
};
