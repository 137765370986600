import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import {
  getAddressVerificationInformation,
  getRenterApplication,
  getRenterResume,
  getVerifierAddresses,
} from 'redux/slices/agentSlice';
import { AppThunkDispatch } from 'redux/store';

import { VerifierDetailsModal } from 'components/Agent/Applications/ApplicationDetails/components/RenterAddressHistory/components/VerifierDetailsModal/VerifierDetailsModal';
import { IncomeReportModal } from 'components/Agent/Applications/ApplicationDetails/components/RenterIncomeReports/IncomeReportModal/IncomeReportModal';
import { AdverseActionLetterForm } from 'components/Agent/components/shared/AdverseActionLetterForm/AdverseActionLetterForm';
import { ChangeApplicantStatusModal } from 'components/Agent/components/shared/ChangeApplicantStatusModal/ChangeApplicantStatusModal';
import Spinner from 'components/shared/Spinner/Spinner';
import {
  calculateTotalAnimals,
  calculateTotalMinors,
  flattenApplicationList,
  getAnimalsAddedByText,
  getMinorAddedByText,
  sortApplicationsByRole,
} from 'helpers/applicantsHelper';
import { IsMobileApplicantsPage } from 'helpers/IsMobile';
import { renterPresentAddress } from 'helpers/renterHelper';
import { routes } from 'shared/routes';
import {
  AddressVerificationResponse,
  ApplicationActions,
  PropertyApplicationStatus,
  VerifierAddressObject,
} from 'shared/types/agentTypes';
import { ApplicationViewProps } from 'shared/types/applicantsType';
import { AddressInformationResponse, RenterProfileInformationProps, RenterRoles } from 'shared/types/renterTypes';

import { ApplicantInformation } from '../ApplicantInformation/ApplicantInformation';
import { ApplicationHeader } from '../ApplicationHeader/ApplicationHeader';

import styles from './ApplicationView.module.scss';

export const ApplicationView = ({
  application,
  desiredMoveInDate,
  propertyId,
  propertyRent,
  isCustomAddress,
  propertyAddress,
}: ApplicationViewProps): JSX.Element => {
  let occupantCount = 0;
  const flattenedApplicationsList = flattenApplicationList([application]);
  const isMobileView = IsMobileApplicantsPage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch<AppThunkDispatch>();
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isAdverseFormModalOpen, setIsAdverseFormModalOpen] = useState(false);
  const [incomeRenterId, setIncomeRenterId] = useState(-1);
  const [currentAction, setCurrentAction] = useState<ApplicationActions>(ApplicationActions.ACTIVE);
  const [addressRenterId, setAddressRenterId] = useState(-1);
  const [isAddressDataLoading, setIsAddressDataLoading] = useState(false);
  const [selectedApplicationId, setSelectedApplicationId] = useState(-1);
  const [selectedAddressApplicationId, setSelectedAddressApplicationId] = useState(-1);
  const [addressVerificationInformation, setAddressVerificationInformation] = useState<AddressVerificationResponse[]>(
    []
  );
  const [addressInformation, setAddressInformation] = useState<RenterProfileInformationProps>(
    {} as RenterProfileInformationProps
  );
  const presentAddress = useMemo((): AddressInformationResponse | undefined => {
    if (addressInformation.addresses) {
      return renterPresentAddress(addressInformation.addresses);
    }

    return undefined;
  }, [addressInformation.addresses]);
  const [selectedApplicationStatus, setSelectedApplicationStatus] = useState<PropertyApplicationStatus>(
    PropertyApplicationStatus.NOT_STARTED
  );
  const [selectedStatus, setSelectedStatus] = useState<ApplicationActions>(ApplicationActions.ACTIVE);
  const selectedOrganizationInformation = useSelector(selectedOrganization);
  const navigate = useNavigate();
  const onRenterResumeSelect = (): void => {
    navigate(routes.generateRenterResume(Number(application.id), propertyId));
  };
  const getApplicationStatus = (confirmedFlag: boolean): PropertyApplicationStatus =>
    confirmedFlag ? PropertyApplicationStatus.COMPLETED : PropertyApplicationStatus.IN_PROGRESS;
  const onIncomeClickHandler = (renterId: number, status: PropertyApplicationStatus, applicationId: number): void => {
    setIsModalOpen(true);
    setIncomeRenterId(renterId);
    setSelectedApplicationId(applicationId);
    setSelectedApplicationStatus(status);
  };
  const onStatusClickHandler = (status: ApplicationActions): void => {
    setIsStatusModalOpen(true);
    setSelectedStatus(status);
  };
  const totalOccupants = useMemo(
    () => (application?.adultsCount ?? 0) + (application?.minorsCount ?? 0),
    [application?.adultsCount, application?.minorsCount]
  );
  const onAddressClickHandler = async (renterId: number, applicationId: number): Promise<void> => {
    setAddressRenterId(renterId);
    setIsAddressDataLoading(true);
    setSelectedAddressApplicationId(applicationId);
    dispatch(
      getRenterApplication({
        organizationId: selectedOrganizationInformation.id,
        applicationId: Number(applicationId),
      })
    ).then((res) => {
      dispatch(
        getRenterResume({
          renterId: res?.payload?.renterProfileId,
          organizationId: selectedOrganizationInformation.id,
        })
      )
        .unwrap()
        .then((response) => {
          setAddressInformation(response);
        });
    });

    try {
      const addresses = await dispatch(
        getVerifierAddresses({ organizationId: selectedOrganizationInformation.id, renterId: renterId })
      ).unwrap();
      const addressInfoResponses = await Promise.all(
        addresses.map((verifierAddress: VerifierAddressObject) =>
          dispatch(
            getAddressVerificationInformation({
              organizationId: selectedOrganizationInformation.id,
              renterId: renterId,
              addressId: verifierAddress.id,
            })
          ).unwrap()
        )
      );

      setAddressVerificationInformation(addressInfoResponses);

      setIsAddressModalOpen(true);
    } catch (error) {
      console.error('Error fetching address verification information:', error);
    } finally {
      setIsAddressDataLoading(false); // Reset loading state
    }
  };

  return (
    <div className={styles.Container}>
      {isAddressDataLoading && (
        <div className={styles.Overlay}>
          <Spinner />
        </div>
      )}
      <ApplicationHeader
        moveInDate={desiredMoveInDate}
        propertyRent={propertyRent}
        activeDate={application?.fileApplication?.updatedAt as string}
        minorCount={calculateTotalMinors(flattenedApplicationsList)}
        minorAddedByText={getMinorAddedByText(flattenedApplicationsList)}
        occupantCount={(application?.adultsCount ?? 0) + (application.minorsCount ?? 0)}
        petCount={calculateTotalAnimals(flattenedApplicationsList)}
        petAddedByText={getAnimalsAddedByText(flattenedApplicationsList)}
        budgetAmount={application.groupIncome}
        onRenterResumeSelect={onRenterResumeSelect}
        applicationFileStatus={application?.fileApplication?.status}
        onStatusClickHandler={onStatusClickHandler}
      />
      {application &&
        sortApplicationsByRole(flattenedApplicationsList).map((applicationItem, index) => {
          if (applicationItem.renterRole === RenterRoles.OCCUPANTS) {
            occupantCount = occupantCount + 1;
          }

          return (
            <React.Fragment key={applicationItem.id}>
              {applicationItem.renterRole !== RenterRoles.GUARANTOR && (
                <>
                  <ApplicantInformation
                    onIncomeClickHandler={onIncomeClickHandler}
                    applicationInformation={applicationItem}
                    isLast={index === flattenApplicationList([application]).length - 1}
                    occupantNumber={occupantCount}
                    applicationStatus={getApplicationStatus(applicationItem.isConfirmed)}
                    key={`Application-${index}-${applicationItem.id}`}
                    propertyId={propertyId}
                    onAddressClickHandler={onAddressClickHandler}
                  />
                  {!!applicationItem.associatedGuarantorApplication && (
                    <div
                      className={styles.AssociatedApplication}
                      key={`Application-${index}-${applicationItem.associatedGuarantorApplication.id}`}
                    >
                      {!isMobileView && <div className={styles.VerticalLine} />}
                      <div className={styles.Application}>
                        <ApplicantInformation
                          applicationStatus={getApplicationStatus(
                            applicationItem.associatedGuarantorApplication.isConfirmed
                          )}
                          applicationInformation={applicationItem.associatedGuarantorApplication}
                          propertyId={propertyId}
                          onIncomeClickHandler={onIncomeClickHandler}
                          onAddressClickHandler={onAddressClickHandler}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
              {applicationItem.renterRole === RenterRoles.GUARANTOR && (
                <div className={styles.AssociatedApplication} key={`Application-${index}-${applicationItem.id}`}>
                  {!isMobileView && <div className={styles.VerticalLine} />}
                  <div className={styles.Application}>
                    <ApplicantInformation
                      applicationStatus={getApplicationStatus(applicationItem.isConfirmed)}
                      applicationInformation={applicationItem}
                      propertyId={propertyId}
                      onIncomeClickHandler={onIncomeClickHandler}
                      onAddressClickHandler={onAddressClickHandler}
                    />
                  </div>
                </div>
              )}
            </React.Fragment>
          );
        })}
      {isModalOpen && (
        <IncomeReportModal
          applicationId={selectedApplicationId}
          renterId={incomeRenterId}
          organizationId={selectedOrganizationInformation.id}
          setIsModalOpen={setIsModalOpen}
          propertyRent={propertyRent}
          isCustomProperty={isCustomAddress}
          totalOccpants={totalOccupants}
          propertyAddress={propertyAddress || ''}
          status={selectedApplicationStatus}
        />
      )}
      {isStatusModalOpen && (
        <ChangeApplicantStatusModal
          applicationId={application.id}
          propertyId={propertyId}
          selectedStatus={selectedStatus}
          totalOccpants={totalOccupants}
          propertyAddress={propertyAddress || ''}
          status={selectedApplicationStatus}
          application={application}
          setIsStatusModalOpen={setIsStatusModalOpen}
          renterLastName={application.renterLastName}
          renterFirstName={application.renterFirstName}
          setIsAdverseFormModalOpen={setIsAdverseFormModalOpen}
        />
      )}
      {isAdverseFormModalOpen && (
        <AdverseActionLetterForm
          totalOccpants={totalOccupants}
          propertyAddress={propertyAddress || ''}
          status={selectedApplicationStatus}
          application={application}
          renterFirstName={application.renterFirstName}
          renterLastName={application.renterLastName}
          setIsAdverseFormModalOpen={setIsAdverseFormModalOpen}
          setIsStatusModalOpen={setIsStatusModalOpen}
          applicationId={application.id}
          propertyId={propertyId}
          currentAction={currentAction}
          setCurrentAction={setCurrentAction}
        />
      )}
      {isAddressModalOpen && presentAddress && !isAddressDataLoading && (
        <VerifierDetailsModal
          isModalOpen={isAddressModalOpen}
          onHideHandler={() => setIsAddressModalOpen(false)}
          title={t('renter.ConfirmAddressHistory.addressHistory.')}
          currentOrganization={selectedOrganizationInformation.id}
          renterId={addressRenterId}
          addressVerificationInformation={addressVerificationInformation}
          address={presentAddress}
          addressApplicationId={selectedAddressApplicationId}
        />
      )}
    </div>
  );
};
