import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import {
  selectCurrentProperty,
  selectedRenterDetails,
  selectVerifierEmploymentInformation,
} from 'redux/selectors/agentSelector';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { getEmploymentVerificationInformation, getVerifierEmployments } from 'redux/slices/agentSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as WorkIcon } from 'assets/svgs/WorkIconDarkBorder.svg';
import styles from 'components/Agent/Applications/ApplicationDetails/ApplicationDetails.module.scss';
import { SectionTitle } from 'components/Agent/Applications/ApplicationDetails/components/shared';
import { retrieveRenterTransactionReport } from 'services/agentService';
import { VerifierEmploymentObject } from 'shared/types/agentTypes';
import { FinancialData } from 'shared/types/reportTypes';

import { FinancialReport } from '../RenterIncomeReports/FinancialReport/FinancialReport';

import EmploymentContainer from './EmploymentContainer/EmploymentContainer';
import IncomeReport from './IncomeReport/IncomeReport';

import renterIncomeStyles from './RenterIncome.module.scss';

const RenterIncome = (): JSX.Element => {
  const { t } = useTranslation();
  const { applicationId } = useParams();
  const [hasVerifierEmployments, setHasVerifierEmployments] = useState(false);
  const renterDetails = useSelector(selectedRenterDetails);
  const { id: organizationId } = useSelector(selectedOrganization);
  const selectedProperty = useSelector(selectCurrentProperty);
  const employmentVerificationInformation = useSelector(selectVerifierEmploymentInformation);
  const [areTransactionsFetched, setAreTransactionsFetched] = useState(false);
  const isApiRequested = useRef(false);
  const [transactionalInformation, setTransactionalInformation] = useState<FinancialData>({} as FinancialData);
  const [transactionalError, setTransactionalError] = useState<string | undefined | number>('');
  const dispatch = useDispatch<AppThunkDispatch>();
  const isIncomeReportFetched = useMemo(
    (): boolean => areTransactionsFetched && !!transactionalInformation?.incomeSummary?.averageMonthly,
    [areTransactionsFetched, transactionalInformation?.incomeSummary?.averageMonthly]
  );

  useEffect(() => {
    if (!isApiRequested.current && renterDetails?.userId) {
      isApiRequested.current = true;
      setAreTransactionsFetched(false);
      retrieveRenterTransactionReport({
        organizationId: organizationId,
        renterId: renterDetails.userId,
        applicationId: Number(applicationId),
      })
        .then((res: AxiosResponse) => {
          setTransactionalInformation(res.data.payload);
        })
        .catch((error: AxiosError) => {
          setTransactionalError(error?.response?.status);
        })
        .finally(() => {
          setAreTransactionsFetched(true);
        });
    }
  }, [applicationId, dispatch, organizationId, renterDetails.userId]);

  useEffect(() => {
    if (organizationId && renterDetails.userId) {
      dispatch(getVerifierEmployments({ organizationId: organizationId, renterId: renterDetails.userId }))
        .unwrap()
        .then((res: VerifierEmploymentObject[]) => {
          if (res?.length > 0) {
            setHasVerifierEmployments(true);
          }

          res?.map((verifierEmployment: VerifierEmploymentObject) => {
            dispatch(
              getEmploymentVerificationInformation({
                organizationId: organizationId,
                renterId: renterDetails.userId,
                employmentId: verifierEmployment.id,
              })
            );
          });
        });
    }
  }, [organizationId, dispatch, renterDetails.userId]);

  if (hasVerifierEmployments && isEmpty(employmentVerificationInformation)) {
    return <div />;
  }

  return (
    <div className={classNames(styles.container, styles.ApplicationDetails)}>
      <div className={styles.infoDiv}>
        <div>
          <SectionTitle
            mainTitle={t('agent.applicants.renterResume.sourceOfIncome')}
            iconElement={<WorkIcon height={24} width={24} />}
            shouldShowCount={false}
          />
          <EmploymentContainer />
          <SectionTitle
            description={t('agent.applicants.renterResume.sourceOfIncome.incomeReport')}
            iconElement={<WorkIcon height={24} width={24} />}
            shouldDrawLine
            shouldShowMainTitle={false}
          />
          {transactionalError === 400 && (
            <div className={renterIncomeStyles.NotFound}>
              {t('agent.applicants.renterResume.sourceOfIncome.incomeReport.doesNotHaveBankAccount')}
            </div>
          )}

          {isIncomeReportFetched && (
            <div className={renterIncomeStyles.FinancialInstitutions}>
              <div className={renterIncomeStyles.FinancialReports}>
                <div className={renterIncomeStyles.FinancialReportHeader}>
                  <div className={renterIncomeStyles.FinancialHeading}>{t('incomeReport.financialInstitutions')} </div>
                  <div className={renterIncomeStyles.InstitutionCount}>
                    {transactionalInformation.accountDetails.length}
                  </div>
                </div>
                {transactionalInformation.accountDetails.map((accounts, index) => (
                  <FinancialReport
                    key={`Financial-Reports-${accounts}-${index}`}
                    accountInformation={accounts.account}
                    accountTransactions={accounts.transactions}
                    shouldShowInformationByDefault={false}
                  />
                ))}
              </div>
            </div>
          )}
          {isIncomeReportFetched && transactionalError === 200 && (
            <IncomeReport
              past6months={transactionalInformation.incomeSummary.pastSixMonths}
              past3months={transactionalInformation.incomeSummary.pastThreeMonths}
              averageMonthly={transactionalInformation.incomeSummary.averageMonthly}
              projected12Months={transactionalInformation.incomeSummary.projectedTwelveMonthly}
              propertyRent={selectedProperty.monthlyRentAmount ?? 1}
              isCustomProperty={selectedProperty.isCustomAddress ?? false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RenterIncome;
