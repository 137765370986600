import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Col, Modal as BModal, Row, Spinner } from 'react-bootstrap';
import { Field, Formik } from 'formik';
import { t } from 'i18next';

import { ReactComponent as CrossIcon } from 'assets/svgs/CrossIcon.svg';
import AgentModal from 'components/Agent/components/AgentModal/AgentModal';
import RCButton from 'components/shared/Button/Button';
import Button from 'components/shared/Button/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import { resumeFilters } from 'constants/agentConstants';
import { ResumeFilters } from 'shared/types/agentTypes';

import styles from './ExportModal/ExportModal.module.scss';

interface PdfSectionModalProps {
  isModalOpenForPdfSection: boolean;
  generatePdfDocument: (values: ResumeFilters) => void;
  isPreparingPdf: boolean;
  setIsModalOpenForPdfSection: Dispatch<SetStateAction<boolean>>;
  handleBackButton: () => void;
}

export const PdfSectionModal = ({
  isModalOpenForPdfSection,
  generatePdfDocument,
  isPreparingPdf,
  setIsModalOpenForPdfSection,
  handleBackButton,
}: PdfSectionModalProps): JSX.Element => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [allChecked, setAllChecked] = useState(false);
  const initialValues: ResumeFilters = {
    includeCoverPage: false,
    includeAllInHousehold: false,
    includeApplicationDetails: false,
    includeIDVerification: false,
    includeRentalHistoryVerification: false,
    includeEmploymentVerification: false,
    includeAnimalDocuments: false,
    includeIncomeDocuments: false,
    includeDocumentDigest: false,
    includeCreditReport: false,
    includeCriminalRecordSearch: false,
    includeHousingCourtRecordSearch: false,
  };

  useEffect(() => {
    const isAllChecked = selectedOptions.length > 0;

    setAllChecked(isAllChecked);
  }, [selectedOptions]);
  const handleOptionsChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: boolean) => void,
    values: ResumeFilters
  ): void => {
    const { checked, value } = event.target;

    if (value === 'selectAll') {
      Object.keys(values).forEach((key) => {
        if (key !== 'selectAll') {
          setFieldValue(key, checked);
        }
      });

      if (checked) {
        setSelectedOptions(
          resumeFilters.filter((option) => option.value !== 'selectAll').map((option) => option.value)
        );
      } else {
        setSelectedOptions([]);
      }
    } else {
      setFieldValue(value, checked);

      if (checked) {
        setSelectedOptions((prev) => [...prev, value]);
      } else {
        setSelectedOptions((prev) => prev.filter((option) => option !== value));
        setFieldValue('selectAll', false);
      }
    }
  };
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleRemoveOption = (option: string, setFieldValue: (field: string, value: boolean) => void) => {
    setFieldValue(option, false);
    setSelectedOptions((prev) => prev.filter((selectedOption) => selectedOption !== option));
  };
  const handlePdfDownload = (values: ResumeFilters): void => {
    generatePdfDocument(values);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={(values) => handlePdfDownload(values)}>
      {({ values, setFieldValue }) => (
        <AgentModal
          show={isModalOpenForPdfSection}
          onHide={() => setIsModalOpenForPdfSection(false)}
          size="lg"
          buttonText={t('application.cancel')}
          title="Export Renter Resume"
          titleDescription="The exported file will be posted in your Messaging Center."
          crossButtonClassName={styles.Cancelbutton}
          customModalClassName={styles.CustomModal}
          customHeaderClassName={styles.CustomHeaderContainer}
          customHeaderContent={styles.CustomHeaderContent}
        >
          <div className={styles.container}>
            <BModal.Body className={styles.PdfSectionModalBody}>
              <Row>
                <Col className={styles.SectionContainer}>
                  {resumeFilters.map((option, index) => (
                    <div className={`${styles.OptionsContainer} ${index === 0 ? styles.FirstOption : ''}`} key={index}>
                      <Field
                        as={Checkbox}
                        className={styles.checkboxLabel}
                        label={option.label}
                        value={option.value}
                        checked={values[option.value as keyof ResumeFilters]}
                        type="checkbox"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleOptionsChecked(e, setFieldValue, values)
                        }
                      />
                    </div>
                  ))}
                </Col>
                <Col>
                  <div className={styles.SelectedHeading}>Selected Sections</div>
                  <div className={styles.SelectedOptionContainer}>
                    {selectedOptions.map((option) => (
                      <div key={option} className={styles.SelectedOption}>
                        <span className={styles.SelectedOptionName}>
                          {resumeFilters.find((item) => item.value === option)?.label}
                        </span>
                        <div className={styles.RemoveButton}>
                          <CrossIcon onClick={() => handleRemoveOption(option, setFieldValue)} width={20} height={20} />
                        </div>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </BModal.Body>
            <BModal.Footer>
              <div className={styles.ButtonsContainer}>
                <Button variant="outline" type="button" className={styles.NextButton} onClick={handleBackButton}>
                  Back
                </Button>
                <RCButton
                  onClick={() => handlePdfDownload(values)}
                  disabled={!allChecked || isPreparingPdf}
                  className={styles.NextButton}
                >
                  {isPreparingPdf ? (
                    <span>
                      <Spinner className={styles.PdfSpinner} /> Preparing...
                    </span>
                  ) : (
                    t('application.next')
                  )}
                </RCButton>
              </div>
            </BModal.Footer>
          </div>
        </AgentModal>
      )}
    </Formik>
  );
};
