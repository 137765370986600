// DownloadContext.tsx
import React, { createContext, useCallback, useContext, useState } from 'react';
import axios from 'axios';

import { Notification } from 'shared/Notification/Notification';

interface DownloadContextProps {
  isDownloading: boolean;
  progress: number;
  downloadFile: (url: string, filename: string) => Promise<void>;
}

const DownloadContext = createContext<DownloadContextProps | undefined>(undefined);

export const useDownload = (): DownloadContextProps => {
  const context = useContext(DownloadContext);

  if (!context) {
    throw new Error('useDownload must be used within a DownloadProvider');
  }

  return context;
};

export const DownloadProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [progress, setProgress] = useState(0);
  const downloadFile = useCallback(async (url: string, filename: string) => {
    setIsDownloading(true);
    setProgress(0);

    try {
      const response = await axios.get(url, {
        responseType: 'blob', // Ensures we get a Blob for binary data
        onDownloadProgress: (progressEvent) => {
          const totalLength = progressEvent.total;

          if (totalLength) {
            setProgress((progressEvent.loaded / totalLength) * 100);
          }
        },
      });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = downloadUrl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      Notification({ message: 'Error downloading PDF', type: 'error' });
    } finally {
      setIsDownloading(false);
      setProgress(0);
    }
  }, []);

  return (
    <DownloadContext.Provider value={{ isDownloading, progress, downloadFile }}>{children}</DownloadContext.Provider>
  );
};
