import { Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { isLoading, selectAllOrganizations } from 'redux/selectors/organizationSelector';
import { getLastVisitedOrganization } from 'redux/slices/organizationSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as ThreeDotIcon } from 'assets/svgs/ThreeDotsIcon.svg';
import Button from 'components/shared/Button/Button';
import navbarStyles from 'components/shared/Navbar/RenterResumeNavbar/RenterResumeNavbar.module.scss';
import Spinner from 'components/shared/Spinner/Spinner';
import { OrganizationNavbarKeys } from 'constants/superAdminConstants';
import { formatDate } from 'helpers/agentHelper';
import { IsMobile } from 'helpers/IsMobile';
import { routes } from 'shared/routes';

import styles from './OrganizationPage.module.scss';

export const OrganizationsPage = (): JSX.Element => {
  const [params] = useSearchParams();
  const { t } = useTranslation();
  const activeTab = params.get('activeTab') ?? OrganizationNavbarKeys.ORGANIZATIONS;
  const organizations = useSelector(selectAllOrganizations);
  const isOrganizationApiLoading = useSelector(isLoading);
  const isMobileView = IsMobile();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppThunkDispatch>();
  const handleOrgClick = (id: number): void => {
    dispatch(getLastVisitedOrganization(id))
      .unwrap()
      .then(() => navigate(routes.applicants));
  };

  return (
    <>
      {isMobileView && (
        <div className={classNames(styles.MobilePage, '')}>
          <div className={styles.Heading}>{'Intellirent'}</div>
          <Navbar expand="lg" className={classNames(navbarStyles.NavBar, styles.Navbar)}>
            <div className={navbarStyles.Container}>
              <Nav
                onClick={() => params.set('activeTab', 'Organizations')}
                className={classNames(navbarStyles.NavLinks, {
                  [navbarStyles.active]: activeTab === OrganizationNavbarKeys.ORGANIZATIONS,
                })}
              >
                {`Organizations`}
              </Nav>
            </div>
          </Navbar>

          <div className={styles.MobileHeadingContainer}>
            <div className={styles.MobileHeadingRow}>
              <div className={styles.OrganizationHeading}>{t('application.organizations')}</div>
              <div className="text-start">{`${organizations.length || 0} ${t('application.organizations')} N/A ${t(
                'application.users'
              )}`}</div>
            </div>
            <Button variant="primary">{t('agent.userIndexPage.inviteUser')}</Button>
          </div>
        </div>
      )}
      {!isMobileView && (
        <div className={classNames(styles.Page, '')}>
          <div className={styles.Heading}>{'Intellirent'}</div>
          <Navbar expand="lg" className={classNames(navbarStyles.NavBar, styles.Navbar)}>
            <div className={navbarStyles.Container}>
              <Nav
                onClick={() => params.set('activeTab', 'Organizations')}
                className={classNames(navbarStyles.NavLinks, {
                  [navbarStyles.active]: activeTab === OrganizationNavbarKeys.ORGANIZATIONS,
                })}
              >
                {`Organizations`}
              </Nav>
            </div>
          </Navbar>

          <div className={styles.HeadingContainer}>
            <div className={styles.HeadingRow}>
              <div className={styles.OrganizationHeading}>{t('application.organizations')}</div>
              <div>{`${organizations.length || 0} ${t('application.organizations')} N/A ${t(
                'application.users'
              )}`}</div>
            </div>
            <Button variant="primary">{t('agent.userIndexPage.inviteUser')}</Button>
          </div>
        </div>
      )}

      <div className={classNames(styles.OrganizationCardContainer, { [styles.CardMargin]: isMobileView })}>
        <table className={classNames(styles.CustomTable, 'table')}>
          <thead className="gap-2">
            <tr>
              <th scope="col" className="text-center">
                ORGANIZATION NAME
              </th>
              <th scope="col" className="text-center">
                CREATED ON
              </th>
              <th scope="col" className="text-center">
                CUSTOMER AS
              </th>
              <th scope="col" className="text-center">
                LAST ACTIVE
              </th>
              <th scope="col" className="text-center">
                # OF USER
              </th>
              <th scope="col" className="text-center">
                # OF APPS
              </th>
            </tr>
          </thead>
          <tbody>
            {!isOrganizationApiLoading &&
              organizations.map((organization) => (
                <tr key={organization.id}>
                  <td className={styles.CursorPointer} onClickCapture={() => handleOrgClick(organization.id)}>
                    <div className={classNames('mb-2', styles.Text)}>{organization.housingProviderCategory}</div>
                    {organization.name}
                  </td>
                  <td>{formatDate(organization.createdAt) || 'N/A'}</td>
                  <td>{formatDate(organization.customerAs) || 'N/A'}</td>
                  <td>{formatDate(organization.updatedAt) || 'N/A'}</td>
                  <td>
                    <span className={styles.NumberColor}>{organization.users || 'N/A'}</span>
                  </td>
                  <td>
                    <span className={styles.NumberColor}>{organization.apps || 'N/A'}</span>
                  </td>
                  <td>
                    <ThreeDotIcon />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {isOrganizationApiLoading && (
          <div className={styles.spinnerContainer}>
            <Spinner />
          </div>
        )}
      </div>
    </>
  );
};
