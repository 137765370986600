import { ChangeEvent, Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Field, useField, useFormikContext } from 'formik';

import RCButton from 'components/shared/Button/Button';
import CustomErrorMessage from 'components/shared/CustomErrorMessage/ErrorMessage';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import TextField from 'components/shared/TextField/TextField';
import { SSN_LENGTH } from 'shared/constants';

import styles from './RenterSSN.module.scss';
interface RenterSsnDetailsProps {
  ssn: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  updateSSNNumber: () => void;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
}

const RenterSsnInputDetails = ({
  ssn,
  handleChange,
  updateSSNNumber,
  setIsDirty,
}: RenterSsnDetailsProps): JSX.Element => {
  const { dirty: isDirty } = useFormikContext();
  const [, meta, helpers] = useField({ name: 'ssnTailDigits' });
  const { t } = useTranslation();
  const [blurred, setBlurred] = useState(false);
  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      helpers.setValue(event.target.value.replaceAll('-', '').slice(0, SSN_LENGTH));
      setBlurred(false);

      handleChange(event);
      setIsDirty(true);
    },
    [helpers, handleChange, setIsDirty]
  );
  const handleInputBlur = (): void => {
    setBlurred(true);
    helpers.setTouched(true); // Set field as touched when blurred
  };

  return (
    <>
      <div className={styles.textField}>
        <FormCloseWarning isDirty={isDirty} />
        <Field
          as={TextField}
          className={`${classNames(styles.ssnTextField, blurred && meta.error && styles.error)} fs-mask`}
          name="ssnTailDigits"
          value={ssn}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          placeholder={'000-00-0000'}
        />
      </div>
      {blurred && !!meta.error && <CustomErrorMessage message={meta.error} />}

      <div className={styles.renterButtonContainer}>
        <RCButton
          className={classNames(styles.renterButton, styles.renterButtonSmall)}
          variant="outline"
          onClick={updateSSNNumber}
          disabled={!!meta.error || !meta.value}
        >
          {t('renter.noCreditHistory.SaveAndContinue')}
        </RCButton>
      </div>
    </>
  );
};

export default RenterSsnInputDetails;
