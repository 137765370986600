import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import classNames from 'classnames';
import { useField } from 'formik';
import { isEmpty } from 'lodash-es';

import { DEFAULT_PHONE_NUMBER_CODE } from 'constants/renterConstants';
import { phoneMasks } from 'shared/constants';

import CustomErrorMessage from '../CustomErrorMessage/ErrorMessage';

import styles from './PhoneNumber.module.scss';

export interface IPhoneProps {
  customCountryCode?: string;
  placeholder?: string;
  label?: string;
  name: string;
  country?: string;
  className?: string;
  isCountryCodeDisabled?: boolean;
  onlyCountry?: string[];
  isCountryCodeEditable?: boolean;
  onChangeHandler?: (inputValue: string) => void;
  specialLabel?: string;
  onBlurHandler?: () => void;
  isTouchedOnChange?: boolean;
  onFocusHandler?: () => void;
  isAutoFocus?: boolean;
  isDisabled?: boolean;
  isErrorOnEmpty?: boolean;
  isShowCountryCode?: boolean;
}
const FormikPhoneNumber = ({
  name,
  label,
  className,
  country = 'us',
  isCountryCodeDisabled = true,
  isCountryCodeEditable = true,
  customCountryCode,
  isTouchedOnChange,
  onlyCountry = ['us'],
  onChangeHandler,
  specialLabel = '',
  onBlurHandler,
  onFocusHandler,
  isAutoFocus,
  isDisabled,
  isErrorOnEmpty = false,
  placeholder,
  isShowCountryCode = true,
  ...props
}: IPhoneProps): JSX.Element => {
  const [isValid, setIsValid] = useState(true);
  const [isChanged, setIsChange] = useState(false);
  const [{ value }, { touched, error }, { setValue, setTouched, setError }] = useField(name);
  const inputRef = useRef<HTMLInputElement>(null); // Create a ref for the input field
  const onChange = useCallback(
    (data: string, countryData: CountryData): void => {
      if (isTouchedOnChange) {
        setTouched(true);
      }

      if (customCountryCode) {
        setIsChange(true);

        if (isEmpty(data)) {
          setIsChange(false);
        }
      }

      const { countryCode, dialCode } = countryData;
      const format = phoneMasks[countryCode];
      const countryString = format.match(/#/g);

      if (isCountryCodeDisabled) {
        setIsValid(true);
        setValue(data);

        return;
      }

      if (isErrorOnEmpty && data === dialCode) {
        setIsValid(true);
      } else if (countryString?.length === data.length) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }

      if (inputRef.current) {
        inputRef.current.focus();
      }

      setValue(data);
    },
    [customCountryCode, isCountryCodeDisabled, isErrorOnEmpty, isTouchedOnChange, setTouched, setValue]
  );
  const errorMessage = useMemo(() => {
    if (!touched) {
      return null;
    }

    if (error) {
      return error;
    }

    if (!isValid && !isCountryCodeDisabled) {
      setError('invalid phone number');

      return 'invalid phone number';
    }

    setError(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isValid, touched]);

  useEffect(() => {
    // Focus the input field when the component mounts
    if (inputRef.current && touched && value === DEFAULT_PHONE_NUMBER_CODE) {
      inputRef.current.focus();
    }
  }, [touched, value]);

  return (
    <div className={classNames(styles.container)}>
      <div className={styles.label}>{label || 'Phone Number'}</div>
      {customCountryCode && (
        <div className={classNames(styles.customCountryCode, { [styles.fieldColor]: isChanged })}>
          {customCountryCode}
        </div>
      )}

      <PhoneInput
        {...props}
        inputStyle={{ height: '56px', paddingLeft: isShowCountryCode ? '50px' : '12px' }}
        value={isCountryCodeDisabled ? value : value || DEFAULT_PHONE_NUMBER_CODE}
        specialLabel={specialLabel}
        placeholder={placeholder}
        disableCountryCode={isCountryCodeDisabled}
        onChange={onChangeHandler ?? onChange}
        onBlur={() => {
          setTouched(true);

          if (onBlurHandler) {
            onBlurHandler();
          }
        }}
        onFocus={onFocusHandler}
        country={country}
        buttonClass={styles.isDropDownDisable}
        inputClass={classNames(styles.phoneNumber, { [styles.invalid]: !!errorMessage }, className)}
        onlyCountries={onlyCountry}
        enableSearch
        countryCodeEditable={isCountryCodeEditable}
        // eslint-disable-next-line @typescript-eslint/naming-convention
        inputProps={{ autoFocus: isAutoFocus, ref: inputRef, 'data-testid': 'formikPhoneNumber' }}
        disabled={isDisabled}
        disableCountryGuess
      />
      {errorMessage && <CustomErrorMessage message={errorMessage} />}
    </div>
  );
};

export default FormikPhoneNumber;
