import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader } from '@googlemaps/js-api-loader';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { ErrorMessage } from 'formik';
import { isEmpty } from 'lodash-es';

import CustomErrorMessage from 'components/shared/CustomErrorMessage/ErrorMessage';
import { getAddressMissingMessage } from 'helpers/agentHelper';
import { getLocationTimezone } from 'services/propertyService';
import { AddressGeoLocation } from 'shared/types/agentTypes';
import { IGoogleAddressLookupProps } from 'shared/types/googleAddressLookupTypes';
import { AddPropertyParams } from 'shared/types/propertyType';

import styles from './PlacesAutoCompleteComponent.module.scss';

export interface PlacesError {
  placeholder: string;
  labelField: string;
  shouldAcceptCustomAddress: boolean;
  setIsError: Dispatch<React.SetStateAction<boolean>>;
  addressField: keyof IGoogleAddressLookupProps;
  className?: string;
  handleBlur?: (params: AddPropertyParams) => void;
  shouldTrimAddress?: boolean;
  cityField?: keyof IGoogleAddressLookupProps;
  stateField?: keyof IGoogleAddressLookupProps;
  zipField?: keyof IGoogleAddressLookupProps;
  setGeoLocationInformation?: Dispatch<SetStateAction<AddressGeoLocation>>;
  disabled?: boolean;
}
export type AddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};

export const PlacesAutocompleteComponent = ({
  placeholder,
  labelField,
  shouldAcceptCustomAddress,
  addressField,
  handleBlur,
  setIsError,
  className,
  shouldTrimAddress,
  setGeoLocationInformation,
  cityField = 'city',
  stateField = 'state',
  zipField = 'zipCode',
  disabled = false,
}: PlacesError): JSX.Element => {
  const searchOptions = {
    componentRestrictions: { country: 'us' },
  };
  const [isloadedMap, setIsLoadedMap] = useState(false);
  const [isGoogleAddress, setIsGoogleAddress] = useState<boolean | undefined>(undefined);
  const [isAddressInvalid, setIsAddressInvalid] = useState(false);
  const [addressError, setAddressError] = useState('');

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAP_API as string,
      libraries: ['places'],
    });

    loader.load().then(() => setIsLoadedMap(true));
  });
  const { setFieldValue, setFieldTouched, values, errors, touched } = useFormikContext<IGoogleAddressLookupProps>();
  const error = errors[addressField];
  const handleFieldValue = (fieldValue: string): void => {
    setFieldValue(addressField, fieldValue);
    setIsGoogleAddress(false);

    if (!shouldAcceptCustomAddress) {
      setIsError(true);
    }

    setIsAddressInvalid(false);
  };
  const handleFieldTouched = useCallback(() => {
    setFieldTouched(addressField, true);

    if (!isAddressInvalid && handleBlur && isGoogleAddress) {
      handleBlur({
        [addressField]: values[addressField],
        [cityField]: values[cityField],
        [stateField]: values[stateField],
        [zipField]: values[zipField],
      });
    }
  }, [
    setFieldTouched,
    addressField,
    values,
    isAddressInvalid,
    handleBlur,
    isGoogleAddress,
    cityField,
    stateField,
    zipField,
  ]);
  const addressDetails = (addressComponents: Array<AddressComponent>, value: string): string =>
    addressComponents.find((component: AddressComponent) => component?.types?.includes(value))?.long_name || '';
  const handleSelectAddress = useMemo(
    () =>
      async (address: string): Promise<void> => {
        setIsGoogleAddress(true);
        setIsError(false);
        const results = await geocodeByAddress(address);

        if (setGeoLocationInformation) {
          const latitude = parseFloat(results[0]?.geometry?.location?.lat()?.toFixed(6));
          const longitude = parseFloat(results[0]?.geometry?.location?.lng()?.toFixed(6));
          const timezone = await getLocationTimezone({
            latitude,
            longitude,
          });

          setGeoLocationInformation({
            latitude,
            longitude,
            timezone,
          });
        }

        const addressComponents = results[0].address_components;
        const zipCode = addressDetails(addressComponents, 'postal_code');
        const cityName = addressDetails(addressComponents, 'locality');
        const stateName =
          addressComponents.find((component) => component?.types?.includes('administrative_area_level_1'))
            ?.short_name || '';

        setFieldValue(stateField, stateName);
        setFieldValue(cityField, cityName);
        setFieldValue(zipField, zipCode);
        setFieldValue(addressField, shouldTrimAddress ? address.split(',')[0] : address);

        if (shouldAcceptCustomAddress) {
          return;
        }

        if (!stateName || !zipCode || !cityName) {
          setIsAddressInvalid(true);
          setAddressError(getAddressMissingMessage(stateName, zipCode, cityName));
          setIsError(true);
        } else {
          setIsAddressInvalid(false);
        }
      },
    [
      addressField,
      cityField,
      setFieldValue,
      setGeoLocationInformation,
      setIsError,
      shouldAcceptCustomAddress,
      shouldTrimAddress,
      stateField,
      zipField,
    ]
  );

  return (
    <div className={classNames(styles.container, styles.placeholderPaddingRemoved)}>
      {isloadedMap ? (
        <PlacesAutocomplete
          value={values[addressField] || ''}
          onChange={handleFieldValue}
          onSelect={handleSelectAddress}
          searchOptions={searchOptions}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading: isLoading }) => (
            <div className={styles.placesDropDown}>
              <div className={styles.floatingContainer}>
                <div className={className === 'boldLabel' ? styles.boldLabel : styles.label}>{labelField}</div>
                <input
                  {...getInputProps({
                    placeholder: placeholder,
                    className: classNames(styles.placesAutocomplete, className, {
                      [styles.invalid]:
                        (touched[addressField] && error) ||
                        (touched[addressField] && isGoogleAddress === false && !shouldAcceptCustomAddress && !error) ||
                        isAddressInvalid,
                    }),
                    onBlur: handleFieldTouched,
                    disabled: disabled,
                  })}
                />
              </div>

              {!isEmpty(suggestions) && (
                <div className={values[addressField] ? styles.suggestionInput : styles.noSuggestionInput}>
                  {isLoading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => (
                    <div {...getSuggestionItemProps(suggestion, {})} key={suggestion.description}>
                      <span className={styles.suggestionOptions}>
                        <FontAwesomeIcon icon={faLocationDot} /> {suggestion.description}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </PlacesAutocomplete>
      ) : (
        <Spinner />
      )}

      <ErrorMessage
        render={() => (
          <CustomErrorMessage
            className={classNames(styles.alignError, styles.customErrorForAbsolutePosition)}
            message={error || ''}
          />
        )}
        name={addressField}
      />

      {!error && isAddressInvalid && (
        <CustomErrorMessage
          className={classNames(styles.alignError, styles.customErrorForAbsolutePosition)}
          message={addressError}
        />
      )}
      {!error && isGoogleAddress === false && !shouldAcceptCustomAddress && (
        <CustomErrorMessage
          className={classNames(styles.alignError, styles.customErrorForAbsolutePosition)}
          message={''}
        />
      )}
    </div>
  );
};
