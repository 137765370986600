import { useTranslation } from 'react-i18next';

import { ReportItem } from 'components/shared/ReportItem/ReportItem';
import { addressReportLabels } from 'constants/reportConstants';
import { convertResponseToObject, getValueFormated } from 'helpers/reportsHelper';
import { AddressInformationReportItem } from 'shared/types/reportTypes';

import styles from './AddressReport.module.scss';
export const AddressReport = ({ addressReport }: { addressReport: AddressInformationReportItem }): JSX.Element => {
  const convertedAddressInformation = convertResponseToObject(addressReport);
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <div className={styles.UpdateInformation}>
        <div className={styles.LastUpdated}>
          <div className={styles.Label}>{t('agent.creditReport.firstReported')}</div>
          <div className={styles.Value}>{getValueFormated(convertedAddressInformation['FirstReportedDate'])}</div>
        </div>
        <div className={styles.LastUpdated}>
          <div className={styles.Label}>{t('agent.creditReport.lastUpdated')}</div>
          <div className={styles.Value}>{getValueFormated(convertedAddressInformation['LastUpdatedDate'])}</div>
        </div>
        <div className={styles.DwellingType}>
          <div className={styles.Label}>{t('agent.creditReport.dwellingType')}</div>
          <div className={styles.Value}>{getValueFormated(convertedAddressInformation['DwellingType'])}</div>
        </div>
      </div>
      <div className={styles.ReportTable}>
        {Object.keys(addressReportLabels).map((label, index) => (
          <ReportItem
            key={`address-report-${label}-${convertedAddressInformation[label]}-${index}}`}
            isValueBold
            label={addressReportLabels[label]}
            value={getValueFormated(convertedAddressInformation[label])}
            hasGreyBackground={index === 0}
          />
        ))}
      </div>
    </div>
  );
};
