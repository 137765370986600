import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Modal2FA } from 'pages/Modal2FA/Modal2FA';
import { select2Fa } from 'redux/selectors/authSelector';

import { ReactComponent as Check } from 'assets/svgs/check.svg';
import { ReactComponent as OpenInNew } from 'assets/svgs/open_in_new.svg';
import { ReactComponent as Security2 } from 'assets/svgs/security2.svg';
import RCButton from 'components/shared/Button/Button';

import styles from '../TwoFactorSetup/TwoFactorSetup.module.scss';

export const TwoFactorSetup = (): JSX.Element => {
  const twoFactorEnabled = useSelector(select2Fa);
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const AuthMsgContainer = (): JSX.Element => {
    if (twoFactorEnabled) {
      return (
        <div className={styles.LearnMoreContainer2}>
          <div className={styles.MiniContainer}>
            <Check />
            <p className={classNames(styles.FontStyle3, 'text-start')}>{t('otpModal.2FAEnabled')}</p>
          </div>
          <div className={styles.MiniContainer}>
            <p className={styles.FontStyle3}>{t('otpModal.2FAlearnMore')}</p>
            <OpenInNew />
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.LearnMoreContainer}>
          <div className={styles.MiniContainer}>
            <Security2 />
            <p className={classNames(styles.FontStyle3, 'text-start')}>{t('otpModal.2FAacount')}</p>
          </div>
          <div className={styles.MiniContainer}>
            <p className={styles.FontStyle3}>{t('otpModal.2FAlearnMore')}</p>
            <OpenInNew />
          </div>
        </div>
      );
    }
  };

  return (
    <div className={styles.AccountPreferencesContainer}>
      <div className={styles.MainContainer}>
        <div className={classNames(styles.LeftContainer, 'col-lg-4', 'col-9')}>
          <p className={styles.FontStyle1}>{t('otpModal.2FAauth')}</p>
          <p className={styles.FontStyle2}>
            Subsection description Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
        <div className={classNames(styles.RightContainer, 'col-lg-7', 'col-9')}>
          <AuthMsgContainer />
          <div className={styles.TextMsgContainer}>
            <div>
              <p className={styles.FontStyle4}>{t('otpModal.2FAsms')}</p>
              <p className={styles.FontStyle2}>{t('otpModal.2FAeachSMS')}</p>
            </div>
            <RCButton
              onClick={() => {
                setIsModalOpen(true);
              }}
              disabled={twoFactorEnabled}
              variant="outline"
              className={styles.ManageButton}
            >
              {'Manage'}
            </RCButton>
          </div>
        </div>
      </div>
      {isModalOpen && <Modal2FA setIsModalOpen={setIsModalOpen} />}
    </div>
  );
};
