import { Col, Row } from 'react-bootstrap';

import { ReportItem } from 'components/shared/ReportItem/ReportItem';
// import { publicProfileFirstColumnLabels, publicProfileSecondColumnLabels } from 'constants/reportConstants';
import { getCodeFormated, getValueFormated } from 'helpers/reportsHelper';
import { PublicRecordReportProps } from 'shared/types/reportTypes';

import styles from './PublicRecordReport.module.scss';

export const PublicRecordReport = ({ publicRecord, isRenterFlow = false }: PublicRecordReportProps): JSX.Element => {
  const convertedPublicRecord = publicRecord;
  const firstColumnValues = [
    { name: 'Status Date', value: convertedPublicRecord.StatusDate },
    { name: 'Filing Date', value: convertedPublicRecord.FilingDate },
    { name: 'Amount', value: convertedPublicRecord.Amount },
    { name: 'Ref #', value: convertedPublicRecord.ReferenceNumber },
    { name: 'Court', value: convertedPublicRecord.Court?.desc },
    { name: 'Plain tiff', value: convertedPublicRecord.PlaintiffName },
  ];
  const secondColumnValues = [
    { name: 'Assest', value: convertedPublicRecord.Bankruptcy.AssetAmount },
    { name: 'Liabilities', value: convertedPublicRecord.Bankruptcy.LiabilitiesAmount },
    { name: 'Repayment', value: convertedPublicRecord.Bankruptcy.RepaymentPercent },
    { name: 'Adjustment', value: convertedPublicRecord.Bankruptcy.AdjustmentPercent },
  ];

  return (
    <div className={styles.Container}>
      <Row className={styles.RowContainer}>
        <Col md={12} lg={isRenterFlow ? 12 : 6} className={styles.StatusInformation}>
          {firstColumnValues.map((label, index) => (
            <ReportItem
              key={`public-first-${index}`}
              label={label.name}
              value={getValueFormated(label.value ?? null)}
              isValueBold={true}
              code={getCodeFormated(label.value ?? null)}
            />
          ))}
        </Col>
        <Col md={12} lg={isRenterFlow ? 12 : 6} className={styles.BankruptcyInformation}>
          {secondColumnValues.map((label, index) => (
            <ReportItem
              key={`public-second-${index}`}
              label={label.name}
              value={getValueFormated(label.value)}
              isValueBold={true}
              code={getCodeFormated(label.value)}
            />
          ))}
        </Col>
      </Row>
    </div>
  );
};
