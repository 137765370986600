import { useCallback, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { t } from 'i18next';
import { upperFirst } from 'lodash-es';
import { selectAllOrganizations, selectedOrganization } from 'redux/selectors/organizationSelector';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as OrganizationIcon } from 'assets/svgs/defaultOrganizationIcon.svg';
import { IsMobile } from 'helpers/IsMobile';
import { updateCurrentOrganization } from 'helpers/organizationHelper';
import { OrganizationProps } from 'shared/types/organizationTypes';

import styles from './ProfileDropdown.module.scss';
const ManageOrganization = (): JSX.Element => {
  const selectedOrg = useSelector(selectedOrganization);
  const isMobilewidth = IsMobile();
  const placement = isMobilewidth ? 'bottom' : 'left';
  const organizationData = useSelector(selectAllOrganizations);
  const navigate = useNavigate();
  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false);
  const dispatch = useDispatch<AppThunkDispatch>();
  const location = useLocation();
  const organizationOnClick = useCallback(
    (organization: OrganizationProps): void => {
      updateCurrentOrganization(organization.id, dispatch, navigate);
    },
    [dispatch, navigate]
  );
  const renderTooltip = (
    <Popover
      id="popover-positioned-left"
      onMouseLeave={() => setIsPopoverVisible(false)}
      onMouseEnter={() => setIsPopoverVisible(true)}
      className={classNames(styles.popoverContainer, { [styles.toolTipMarginDiv]: !isMobilewidth })}
    >
      <Popover.Body className={styles.popoverBody}>
        <div className={classNames(styles.profileDropDownOptions, styles.paddingAlighment)}>
          {t('agent.organizations')}
        </div>
        {organizationData.map((organization, index) => (
          <Link
            key={index}
            to={`${location.pathname}${location.search}`}
            onClick={() => organizationOnClick(organization)}
            className={classNames(styles.popoverBtn, styles.organiztionDiv)}
          >
            <span className={styles.iconDiv}>
              <OrganizationIcon />
            </span>
            <div className={styles.organiztionNameDiv}>{upperFirst(organization.name)}</div>
          </Link>
        ))}
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger show={isPopoverVisible} placement={placement} overlay={renderTooltip}>
      <div onMouseEnter={() => setIsPopoverVisible(true)} onMouseLeave={() => setIsPopoverVisible(false)}>
        <Link to="#" className={classNames(styles.popoverBtn, styles.selectedOrganization)}>
          <div className={classNames(styles.selectedOrgDiv, styles.profileDropDownOptions)}>
            <span className={styles.iconDiv}>
              <OrganizationIcon />
            </span>
            {upperFirst(selectedOrg?.name)}
          </div>
          <FontAwesomeIcon icon={faAngleRight} />
        </Link>
      </div>
    </OverlayTrigger>
  );
};

export default ManageOrganization;
