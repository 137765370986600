import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { select2FaToken } from 'redux/selectors/authSelector';
import { enable2FA } from 'redux/slices/authSlice';
import { AppThunkDispatch } from 'redux/store';
import { PhoneNumberSchema } from 'schema/authSchema';

import RCButton from 'components/shared/Button/Button';
import FormikPhoneNumber from 'components/shared/PhoneNumber/FormikPhoneNumber';
import Spinner from 'components/shared/Spinner/Spinner';
import { StepsOptions2FA } from 'constants/agentConstants';
import { extractErrorMessage } from 'helpers/helper';
import { Notification } from 'shared/Notification/Notification';
import { AddPhoneProps } from 'shared/types/sharedTypes';

import styles from '../Modal2FA.module.scss';

export const AddPhoneNumber = memo(({ setModalSteps, setIsModalOpen, setPhoneNumber }: AddPhoneProps): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const authToken2FA = useSelector(select2FaToken);

  return (
    <>
      <div className={styles.ModalBodyText}>
        <div className={styles.SuccessContainer}>
          <p className={styles.FontStyle2}>2FA SMS Setup</p>
          <p className={styles.FontStyle4}>Step 1</p>
        </div>
        <p className={styles.FontStyle3}>
          Subsection description Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua.
        </p>
      </div>
      <Formik
        initialValues={{ phoneNumber: '' }}
        validationSchema={PhoneNumberSchema}
        onSubmit={({ phoneNumber }, { setSubmitting, setErrors }) => {
          const body = {
            phoneNumber: `+${phoneNumber}`,
            token: authToken2FA ?? '',
          };

          setPhoneNumber(phoneNumber);
          dispatch(enable2FA(body))
            .unwrap()
            .then(() => setModalSteps(StepsOptions2FA.OTP))
            .catch((error) => {
              if (error.hasOwnProperty(null)) {
                Notification({ message: extractErrorMessage(error), type: 'error' });
                setIsModalOpen(false);
              } else {
                setErrors(error);
              }
            })
            .finally(() => setSubmitting(false));
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <div className={styles.phoneNumberContainer}>
              <FormikPhoneNumber
                label="Phone Number"
                name="phoneNumber"
                isCountryCodeDisabled={false}
                onlyCountry={[]}
                placeholder="(  )  -"
                isCountryCodeEditable={false}
              />
            </div>
            <div className={styles.ButtonContainer2FA}>
              <RCButton
                onClick={() => {
                  setIsModalOpen(false);
                }}
                variant="outline"
                disabled={isSubmitting}
                className={styles.CancelButton}
              >
                {'Cancel'}
              </RCButton>
              <RCButton variant="primary" type="submit" disabled={isSubmitting || !isValid} className={styles.Continue}>
                {isSubmitting ? (
                  <span>
                    <Spinner />
                  </span>
                ) : (
                  'Continue'
                )}
              </RCButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
});
