import {
  AlignmentType,
  CheckBox,
  Document,
  Footer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType,
} from 'docx';

import { todaysDateDMY } from 'helpers/agentHelper';
import { formatRenterName } from 'helpers/agentHelper';
import { AgentProfileInformationProps } from 'shared/types/agentTypes';

type AdversePDFProps = {
  values: {
    renterField: string;
    checkedActions: string[];
    otherField: string;
    comments: string;
    downloadType: string;
    profileInformation: AgentProfileInformationProps;
    propertyAddress: string;
  };
};

const stylesWord = {
  datestyle: { font: 'Open Sans', size: 22 },
  heading1: { font: 'Open Sans', size: 22, color: '5E6566' },
  heading2: { font: 'Open Sans', size: 22 },
  heading3: { font: 'Open Sans', size: 20, color: '5E6566' },
  heading4: { font: 'Open Sans', size: 24 },
  MainContentFont: { font: 'Open Sans', size: 21, color: '#4a4f4f' },
  ContentHeadlines: { font: 'Open Sans', size: 21, color: '#5E6566' },
  CheckBoxText: { font: 'Open Sans', size: 21, color: '#4a4f4f' },
  footer: { font: 'OpenSans', size: 22, bold: true },
};

export const AdverseWord = ({ values }: AdversePDFProps): Document => {
  const rows = [];

  for (let i = 0; i < values.checkedActions.length; i += 2) {
    const row = new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              children: [
                new CheckBox({ checked: true }),
                new TextRun({
                  text: ` ${values.checkedActions[i]}`,
                  ...stylesWord.CheckBoxText,
                }),
              ],
              spacing: {
                before: 200,
                after: 200,
              },
            }),
          ],
          width: { size: 50, type: WidthType.PERCENTAGE },
          borders: {
            top: { style: 'single', size: 0, space: 0, color: 'FFFFFF' },
            bottom: { style: 'single', size: 0, space: 0, color: 'FFFFFF' },
            left: { style: 'single', size: 0, space: 0, color: 'FFFFFF' },
            right: { style: 'single', size: 0, space: 0, color: 'FFFFFF' },
          },
        }),
        new TableCell({
          children: [
            new Paragraph({
              children: [
                ...(values.checkedActions[i + 1]
                  ? [
                      new CheckBox({ checked: true }),
                      new TextRun({
                        text: ` ${values.checkedActions[i + 1]}`,
                        ...stylesWord.CheckBoxText,
                      }),
                    ]
                  : []),
              ],
              spacing: {
                before: 200,
                after: 200,
              },
            }),
          ],
          width: { size: 50, type: WidthType.PERCENTAGE },
          borders: {
            top: { style: 'single', size: 0, space: 0, color: 'FFFFFF' },
            bottom: { style: 'single', size: 0, space: 0, color: 'FFFFFF' },
            left: { style: 'single', size: 0, space: 0, color: 'FFFFFF' },
            right: { style: 'single', size: 0, space: 0, color: 'FFFFFF' },
          },
        }),
      ],
    });

    rows.push(row);
  }

  const doc = new Document({
    sections: [
      {
        properties: {},
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: formatRenterName(
                      values.profileInformation.firstName,
                      values.profileInformation.middleName || '',
                      values.profileInformation.lastName
                    ),
                    ...stylesWord.footer,
                    break: 1,
                  }),
                ],
                border: {
                  top: {
                    style: 'single',
                    size: 1,
                    space: 0,
                    color: 'D3D3D3',
                  },
                },
                alignment: AlignmentType.LEFT,
              }),
            ],
          }),
        },
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: todaysDateDMY(),
                ...stylesWord.datestyle,
              }),
            ],
            alignment: AlignmentType.RIGHT,
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: 'RENTAL PROPERTY',
                ...stylesWord.heading1,
              }),
              new TextRun({
                text: values.propertyAddress,
                ...stylesWord.heading2,
                break: 1,
              }),
              new TextRun({
                text: 'OCCUPANT ',
                ...stylesWord.heading1,
                break: 2,
              }),
              new TextRun({
                text: values.renterField.split(' | ')[1].split(' ')[0] || 'null',
                ...stylesWord.heading3,
              }),
              new TextRun({
                text: values.renterField.split(' | ')[0] || 'null',
                ...stylesWord.heading4,
                break: 1,
              }),
              new TextRun({
                text: `Dear ${values.renterField.split(' | ')[0].split(' ')[0] || 'null'},`,
                ...stylesWord.MainContentFont,
                break: 2,
              }),
              new TextRun({
                text: 'Thank you for your recent rental application. After careful consideration, we regret to inform you that we are unable to approve your rental application at this time. Principle reason(s) for denial include:',
                ...stylesWord.MainContentFont,
                break: 2,
              }),
            ],
            alignment: AlignmentType.LEFT,
          }),
          new Table({
            rows: [
              ...rows,
              ...(values.otherField !== ''
                ? [
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new CheckBox({ checked: true }),
                                new TextRun({
                                  text: ` ${values.otherField}`,
                                  ...stylesWord.CheckBoxText,
                                }),
                              ],
                              spacing: {
                                before: 200,
                                after: 200,
                              },
                            }),
                          ],
                          borders: {
                            top: { style: 'single', size: 0, space: 0, color: 'FFFFFF' },
                            bottom: { style: 'single', size: 0, space: 0, color: 'FFFFFF' },
                            left: { style: 'single', size: 0, space: 0, color: 'FFFFFF' },
                            right: { style: 'single', size: 0, space: 0, color: 'FFFFFF' },
                          },
                        }),
                      ],
                    }),
                  ]
                : []),
            ],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
          }),
          new Paragraph({
            children: [
              ...(values.comments !== ''
                ? [
                    new TextRun({
                      text: 'Additional Comments',
                      ...stylesWord.ContentHeadlines,
                      break: 1,
                    }),
                    new TextRun({
                      text: values.comments,
                      ...stylesWord.MainContentFont,
                      break: 1,
                    }),
                  ]
                : []),
            ],
            alignment: AlignmentType.LEFT,
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: 'Details about the credit bureau and credit score we used to make this decision are included at the end of this letter. This information',
                ...stylesWord.MainContentFont,
                break: values.comments !== '' ? 1 : 0,
              }),
              new TextRun({
                text: 'Intellirent Solutions, INC.',
                ...stylesWord.MainContentFont,
                break: 2,
              }),
              new TextRun({
                text: '632 Commercial Street, 5th Floor',
                ...stylesWord.MainContentFont,
                break: 1,
              }),
              new TextRun({
                text: 'San Francisco, CA 94111',
                ...stylesWord.MainContentFont,
                break: 1,
              }),
              new TextRun({
                text: 'Toll free: (844) 755-4059',
                ...stylesWord.MainContentFont,
                break: 1,
              }),
              new TextRun({
                text: 'For information on how to obtain a copy of your report or file a dispute, please login to your account at https://my.ir.app/login. A copy of A Summary Of Your Rights Under The Fair Credit Reporting Act is enclosed with this letter for your review.',
                ...stylesWord.MainContentFont,
                break: 2,
              }),
              new TextRun({
                text: 'Intellirent plays no part in the decision to take any action on your rental application and is unable to provide you with specific reason(s) for not accepting your application. You have the right within 60 days of receipt of this notice to obtain a free copy of your report. You also have the right to dispute the accuracy or completeness of any information in the report furnished by logging into your account or contacting Intellirent, https://myintellirent.com/dispute-consumer-report.',
                ...stylesWord.MainContentFont,
                break: 2,
              }),
              new TextRun({
                text: 'Intellirent obtains reports from Experian, 701 Experian Prkwy, Allen, TX 75013, www.experian.com/reportaccess, (888) 397-3742.',
                ...stylesWord.MainContentFont,
                break: 2,
              }),
              new TextRun({
                text: 'Sincerely,',
                ...stylesWord.MainContentFont,
                break: 2,
              }),
              new TextRun({
                text: values.profileInformation.businessName || '',
                ...stylesWord.MainContentFont,
                break: 1,
              }),
              new TextRun({
                text: values.profileInformation.businessMailingAddress || '',
                ...stylesWord.MainContentFont,
                break: 1,
              }),
              new TextRun({
                text: `${values.profileInformation.businessCity}, ${values.profileInformation.businessState} ${values.profileInformation.businessZipCode}`,
                ...stylesWord.MainContentFont,
                break: 1,
              }),
              new TextRun({
                text: values.profileInformation.businessPhoneNumber || '',
                ...stylesWord.MainContentFont,
                break: 1,
              }),
            ],
            alignment: AlignmentType.LEFT,
          }),
        ],
      },
    ],
  });

  return doc;
};
