import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReportErrorTypes } from 'constants/reportConstants';

import styles from './ReportErrorMessage.module.scss';
export const ErrorSupportMessage = ({ errorType }: { errorType: ReportErrorTypes | string }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.SupportMessage}>
      {errorType === ReportErrorTypes.ERROR && (
        <>
          {t('agent.creditReport.weExperiencedDifficulties')}&nbsp;
          <Link className={styles.SupportLink} to={'#'}>
            {t('agent.creditReport.intellirentSupport')}
          </Link>
          &nbsp;{t('agent.creditReport.andProvideThe')}
        </>
      )}
      {errorType === ReportErrorTypes.EXPIRED && (
        <>
          {t('agent.creditReport.the30DayPeriod')}&nbsp;
          <Link className={styles.SupportLink} to={'#'}>
            {t('agent.creditReport.intellirentSupport')}
          </Link>
          .
        </>
      )}
      {errorType === ReportErrorTypes.IN_PROGRESS && (
        <>
          {t('agent.creditReport.theReportHasBeen')}&nbsp;
          <Link className={styles.SupportLink} to={'#'}>
            {t('agent.creditReport.intellirentSupport')}
          </Link>
          .
        </>
      )}
    </div>
  );
};
