import { useTranslation } from 'react-i18next';
import { Document, Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

import { todaysDateDMY } from 'helpers/agentHelper';
import { formatRenterName } from 'helpers/agentHelper';
import { AgentProfileInformationProps } from 'shared/types/agentTypes';

import { CheckedOptionLogo } from '../../../shared/pdf/v2/svgs/CheckedOptionLogo';
import { MapPinOutlineSVG } from '../../../shared/pdf/v2/svgs/MapPinOutlineSVG';

Font.register({
  family: 'OpenSans',
  fonts: [{ src: 'https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0b.woff' }],
});

const AdverseStyles = StyleSheet.create({
  DocumentSizing: {
    padding: '40px',
  },
  DateDesign: {
    fontSize: '11px',
    fontWeight: 600,
    textAlign: 'right',
    lineHeight: '2px',
  },
  Header1: {
    fontSize: '11px',
    fontWeight: 600,
    textAlign: 'left',
    color: '#5E6566',
    marginBottom: '2px',
  },
  Header2: {
    fontSize: '11px',
    fontWeight: 600,
    textAlign: 'left',
  },
  Header3: {
    fontSize: '10px',
    fontWeight: 600,
    textAlign: 'left',
    color: '#5E6566',
  },
  Header4: {
    fontSize: '10px',
    fontWeight: 600,
    textAlign: 'left',
    color: '#5E6566',
  },
  Header5: {
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'left',
    lineHeight: '2px',
  },
  SpaceBreak: {
    marginBottom: '10px',
  },
  SpaceBreak2: {
    marginBottom: '6px',
  },

  SpaceBreak3: {
    marginBottom: '25px',
  },
  RentalPropertyDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-end',
  },
  MainContentFont: {
    fontSize: '11px',
    fontWeight: 400,
    textAlign: 'left',
    color: '#4a4f4f',
  },
  ContentHeadlines: {
    fontSize: '11px',
    fontWeight: 600,
    textAlign: 'left',
    color: '#5E6566',
  },

  MapPin: {
    width: '13px',
    height: '12px',
  },

  CheckBoxDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    width: '50%',
    marginBottom: '10px',
  },

  CheckBoxDiv2: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '20px',
  },

  CheckBoxText: {
    fontSize: '11px',
    fontWeight: 400,
    color: '#4a4f4f',
  },

  CheckBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  FooterContainer: {
    padding: '25px',
  },
  Footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  FooterName: {
    fontSize: '12px',
    fontWeight: 700,
    textAlign: 'left',
    fontFamily: 'OpenSans',
  },
  HrLine: {
    width: '100%',
    height: '1px',
    backgroundColor: '#D3D3D3',
    marginVertical: '10px',
  },
  DocumentSettings: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100vh',
  },
});

type AdversePDFProps = {
  values: {
    renterField: string;
    checkedActions: string[];
    otherField: string;
    comments: string;
    downloadType: string;
    profileInformation: AgentProfileInformationProps;
    propertyAddress: string;
  };
};

export const AdversePDF = ({ values }: AdversePDFProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Document>
      <Page size="A4">
        <View style={AdverseStyles.DocumentSettings}>
          <View style={AdverseStyles.DocumentSizing}>
            <View>
              <Text style={AdverseStyles.DateDesign}>{todaysDateDMY()}</Text>
            </View>
            <View>
              <Text style={AdverseStyles.Header1}>{t('agent.adverseForm.document.rental')}</Text>
            </View>
            <View style={[AdverseStyles.RentalPropertyDiv, AdverseStyles.SpaceBreak]}>
              <View style={AdverseStyles.MapPin}>
                <MapPinOutlineSVG />
              </View>
              <Text style={AdverseStyles.Header2}>{values.propertyAddress}</Text>
            </View>
            <View style={AdverseStyles.RentalPropertyDiv}>
              <Text style={AdverseStyles.Header1}>{`${t('agent.adverseForm.document.occupant')} `}</Text>
              <Text style={AdverseStyles.Header4}>{values.renterField.split(' | ')[1].split(' ')[0] || 'null'}</Text>
            </View>
            <View>
              <Text style={AdverseStyles.Header5}>{values.renterField.split(' | ')[0] || 'null'}</Text>
            </View>
            <View>
              <Text style={[AdverseStyles.MainContentFont, AdverseStyles.SpaceBreak2]}>{`Dear ${
                values.renterField.split(' | ')[0].split(' ')[0] || 'null'
              },`}</Text>
            </View>
            <View>
              <Text style={[AdverseStyles.MainContentFont, AdverseStyles.SpaceBreak3]}>
                {t('agent.adverseForm.document.thankyou')}
              </Text>
            </View>
            <View style={AdverseStyles.CheckBoxContainer}>
              {values.checkedActions.map((action, index) => (
                <View key={`action${index}`} style={AdverseStyles.CheckBoxDiv}>
                  <View>
                    <CheckedOptionLogo />
                  </View>
                  <Text style={AdverseStyles.CheckBoxText}>{action}</Text>
                </View>
              ))}
            </View>
            {!(values.otherField === '') && (
              <View style={AdverseStyles.CheckBoxDiv2}>
                <View>
                  <CheckedOptionLogo />
                </View>
                <Text style={AdverseStyles.CheckBoxText}>{values.otherField}</Text>
              </View>
            )}
            {!(values.comments === '') && (
              <>
                <View>
                  <Text style={AdverseStyles.ContentHeadlines}>{t('agent.adverseForm.document.additional')}</Text>
                </View>
                <View>
                  <Text style={[AdverseStyles.MainContentFont, AdverseStyles.SpaceBreak]}>{values.comments}</Text>
                </View>
              </>
            )}
            <View>
              <Text style={[AdverseStyles.MainContentFont, AdverseStyles.SpaceBreak]}>
                {t('agent.adverseForm.document.thisInfo')}
              </Text>
            </View>
            <View style={AdverseStyles.SpaceBreak}>
              <Text style={AdverseStyles.MainContentFont}>{t('agent.adverseForm.document.intellirent')}</Text>
              <Text style={AdverseStyles.MainContentFont}>{t('agent.adverseForm.document.commercial')}</Text>
              <Text style={AdverseStyles.MainContentFont}>{t('agent.adverseForm.document.francisco')}</Text>
              <Text style={AdverseStyles.MainContentFont}>{t('agent.adverseForm.document.toll')}</Text>
            </View>
            <View>
              <Text style={[AdverseStyles.MainContentFont, AdverseStyles.SpaceBreak]}>
                {t('agent.adverseForm.document.copy')}
              </Text>
            </View>
            <View>
              <Text style={[AdverseStyles.MainContentFont, AdverseStyles.SpaceBreak]}>
                {t('agent.adverseForm.document.plays')}
              </Text>
            </View>
            <View>
              <Text style={[AdverseStyles.MainContentFont, AdverseStyles.SpaceBreak]}>
                {t('agent.adverseForm.document.obtains')}
              </Text>
            </View>
            <View style={AdverseStyles.SpaceBreak3}>
              <Text style={AdverseStyles.MainContentFont}>{t('agent.adverseForm.document.Sincerely')}</Text>
              <Text style={AdverseStyles.MainContentFont}>{values.profileInformation.businessName}</Text>
              <Text style={AdverseStyles.MainContentFont}>{values.profileInformation.businessMailingAddress}</Text>
              <Text
                style={AdverseStyles.MainContentFont}
              >{`${values.profileInformation.businessCity}, ${values.profileInformation.businessState} ${values.profileInformation.businessZipCode}`}</Text>
              <Text style={AdverseStyles.MainContentFont}>{values.profileInformation.businessPhoneNumber}</Text>
            </View>
          </View>
          <View style={AdverseStyles.FooterContainer}>
            <View style={AdverseStyles.HrLine}></View>
            <View style={AdverseStyles.Footer}>
              <Text style={AdverseStyles.FooterName}>
                {formatRenterName(
                  values.profileInformation.firstName,
                  values.profileInformation.middleName || '',
                  values.profileInformation.lastName
                )}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
