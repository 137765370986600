import { Dispatch, memo, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as ErrorIcon } from 'assets/svgs/ErrorOutlineIcon.svg';
import RCButton from 'components/shared/Button/Button';
import { OtpInputField } from 'components/shared/OTPInputField/OTPInputField';
import Spinner from 'components/shared/Spinner/Spinner';
import { otpFieldMaxDigits } from 'shared/constants';

import styles from '../Modal2FA.module.scss';

type Props = {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setOtpCode: Dispatch<SetStateAction<string>>;
  submitOTP: () => void;
  isError: boolean;
  isSubmitting: boolean;
  otpCode: string;
  phoneNumber: string;
  handleResendOtp: () => void;
};

export const OtpView = memo(
  ({
    setIsModalOpen,
    setOtpCode,
    submitOTP,
    otpCode,
    isError,
    isSubmitting,
    phoneNumber,
    handleResendOtp,
  }: Props): JSX.Element => {
    const { t } = useTranslation();

    return (
      <>
        <div className={styles.ModalBodyText2}>
          <p className={styles.FontStyle3}>{`We’ve sent a verification code to ***-***-*${phoneNumber.slice(-3)}.`}</p>
        </div>
        <div className={styles.OTPHeaderContainer}>
          <p className={styles.OTPStyle1}>Enter your</p>
          <p className={styles.OTPStyle2}>Verification Code.</p>
        </div>
        <div className={styles.OTPInput}>
          <OtpInputField hasAutoFocus length={otpFieldMaxDigits} onChangeOTP={(e) => setOtpCode(e)} />
        </div>

        {isError && (
          <div className={styles.ErrorText}>
            <ErrorIcon className={styles.ErrorIcon} />
            {t('otpModal.invalidOtp2FA')}
          </div>
        )}
        <div className={classNames(styles.ButtonContainer2FA, styles.OTPButtonMargin)}>
          <RCButton
            onClick={() => {
              setIsModalOpen(false);
            }}
            variant="outline"
            disabled={isSubmitting}
            className={styles.CancelButton}
          >
            {'Cancel'}
          </RCButton>
          <RCButton
            onClick={submitOTP}
            variant="primary"
            type="submit"
            disabled={otpCode.length !== otpFieldMaxDigits || isSubmitting}
            className={styles.Continue}
          >
            {isSubmitting ? (
              <span>
                <Spinner />
              </span>
            ) : (
              'Continue'
            )}
          </RCButton>
        </div>
        <div className={styles.FooterText}>
          {"Didn't get the Verification Code?"}&nbsp;
          <RCButton variant="outline" className={styles.FooterHighlightedText} onClick={handleResendOtp}>
            {'Resend a new Code'}
          </RCButton>
        </div>
      </>
    );
  }
);
