/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { ReactComponent as MenuIcon } from 'assets/svgs/menu.svg';
import { MenuOption } from 'shared/types/sharedTypes';

import styles from '../Settings.module.scss';

type Props = {
  heading: string;
  options: MenuOption[];
  switchSection: (section: string) => void;
  activeSection: string;
};

export const SideMenu = ({ heading, options, switchSection, activeSection }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const toggleSidebar = (): void => {
    setIsOpen(!isOpen);
  };
  const handleClickOutside = (event: MouseEvent): void => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <button className={styles.MenuToggle} onClick={toggleSidebar}>
        <MenuIcon />
      </button>

      <div ref={sidebarRef} className={classNames(styles.SideMenu, { [styles.Show]: isOpen })}>
        <div className={styles.MenuContainer}>
          <div className={styles.MenuHeading}>{heading}</div>
          <div className={styles.MenuOptionsContainer}>
            {options.map((option: MenuOption, index: number) => (
              <div key={`menu-${option.label}-${index}`}>
                <div
                  className={classNames(styles.MenuItem, { [styles.Active]: option.label === activeSection })}
                  onMouseDown={() => {
                    switchSection(option.label);
                  }}
                >
                  {option.label}
                </div>
                {option.nested && (
                  <div className={styles.SubMenuItems}>
                    {option.nested.map((nestedOption: MenuOption) => (
                      <div key={nestedOption.label} className={classNames(styles.MenuItem)}>
                        {nestedOption.label}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
