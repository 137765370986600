import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Field, Form, Formik, FormikErrors } from 'formik';
import { t } from 'i18next';
import { isEmpty } from 'lodash-es';
import { isSaving, selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { addRenterProfileInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';
import { renterProfileSchema } from 'schema/renterSchema';

import Button from 'components/shared/Button/Button';
import CustomErrorMessage from 'components/shared/CustomErrorMessage/ErrorMessage';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import TextField from 'components/shared/TextField/TextField';
import { RenterProfileSteps } from 'constants/renterConstants';
import { formatSSN } from 'helpers/renterHelper';
import { SSN_LENGTH } from 'shared/constants';
import { RenterProfileInformationProps } from 'shared/types/renterTypes';

import RenterModal from '../RenterModal/RenterModal';

import styles from './EditSSNModal.module.scss';

type EditSSNModalProps = { onHide: () => void; shouldShowModal: boolean };

export const EditSSNModal = ({ onHide, shouldShowModal }: EditSSNModalProps): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const isSavingInformation = useSelector(isSaving);
  const renterDetails = useSelector(selectRenterProfileInformation);
  const [blurred, setBlurred] = useState(false);
  const [ssn, setSSN] = useState('');
  const { ssnTailDigits } = renterDetails;

  useEffect(() => {
    setSSN(formatSSN(ssnTailDigits?.toString(), true) || '');
  }, [ssnTailDigits]);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setBlurred(false);
    setSSN(formatSSN(event.target.value));
  }, []);
  const handleSubmit = useCallback(
    (
      values: { ssnTailDigits: string | number },
      setErrors: (errors: FormikErrors<RenterProfileInformationProps>) => void
    ) => {
      if (renterDetails.userId) {
        const params = { ...values, id: renterDetails.userId, hasNoSSN: false };

        dispatch(addRenterProfileInformation({ values: params, shouldSnnChange: true }))
          .unwrap()
          .catch((error) => setErrors(error))
          .finally(() => onHide());
      }
    },
    [dispatch, renterDetails, onHide]
  );

  return (
    <Formik
      onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
      initialValues={{ ssnTailDigits: ssnTailDigits ?? '' }}
      validationSchema={renterProfileSchema[RenterProfileSteps.SSNINPUT]}
      enableReinitialize
    >
      {({ dirty: isDirty, isValid, errors, setFieldValue }) => (
        <RenterModal show={shouldShowModal} onHide={onHide} isDirty={isDirty}>
          <div className={styles.container}>
            <div className={styles.heading}>{t('renter.PhotoIdInformation.editDetails')}</div>
            <div className={styles.subHeading}>
              {t('renter.PhotoIdInformation.makeChanges')}&nbsp;
              <span className={`${styles.spanColor} fs-mask`}>{t('application.ssn')}</span>
              <Form>
                <FormCloseWarning isDirty={isDirty} />
                <div className={styles.textField}>
                  <Field
                    as={TextField}
                    className={`${classNames(
                      styles.ssnTextField,
                      blurred && errors.ssnTailDigits && styles.error
                    )} fs-mask`}
                    name="ssnTailDigits"
                    value={ssn}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('ssnTailDigits', event.target.value.replaceAll('-', '').slice(0, SSN_LENGTH));
                      handleChange(event);
                    }}
                    onBlur={() => setBlurred(true)}
                    placeholder={'000-00-0000'}
                  />
                  {blurred && errors.ssnTailDigits && <CustomErrorMessage message={errors.ssnTailDigits} />}
                </div>

                <div className={styles.renterButtonContainer}>
                  <Button
                    className={styles.renterButton}
                    variant="outline"
                    disabled={!isEmpty(errors) || !isDirty || !isValid || isSavingInformation}
                    type="submit"
                  >
                    {t('renter.PhotoIdInformation.savechanges')}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </RenterModal>
      )}
    </Formik>
  );
};

export default EditSSNModal;
