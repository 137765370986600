import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectRenterCreditProfile } from 'redux/selectors/agentSelector';

import { ReactComponent as CheckIconFillPrimary } from 'assets/svgs/CheckIconFillPrimary.svg';
import { ReactComponent as InProgressIcon } from 'assets/svgs/EncircledThreeDotsIcon.svg';
import { ReactComponent as ErrorIcon } from 'assets/svgs/RedWarningIcon.svg';
import { ReactComponent as ExpiredIcon } from 'assets/svgs/TimerIcon.svg';
// import RCButton from 'components/shared/Button/Button';
import { ReportErrorTypes } from 'constants/reportConstants';
import { ReportErrorMessageProps } from 'shared/types/reportTypes';

import { ReportSkeletonRow } from '../ReportLoadingSkeletonRow/ReportSkeletonRow';

import styles from './ReportStatusLoader.module.scss';
export const ReportStatusLoader = ({
  errorType,
  errorCode,
  errorMessage,
  shouldShowLoaderRows = true,
  isSuperAdmin = false,
}: ReportErrorMessageProps): JSX.Element => {
  const { t } = useTranslation();
  const renterCreditProfile = useSelector(selectRenterCreditProfile);
  const getErrorIcon = (): ReactNode => {
    if (isSuperAdmin && errorType === ReportErrorTypes.SUCCESS && renterCreditProfile?.success === true) {
      return <CheckIconFillPrimary height={40} width={40} />;
    } else if (errorType === ReportErrorTypes.ERROR) {
      return <ErrorIcon />;
    } else if (errorType === ReportErrorTypes.EXPIRED) {
      return <ExpiredIcon />;
    }

    return <InProgressIcon />;
  };
  const getErrorMessage = (): string => {
    if (
      isSuperAdmin &&
      renterCreditProfile?.success === true
      // errorType === ReportErrorTypes.IN_PROGRESS &&
      // experianAuth === ExperianAuthenticationStatus.COMPLETED
    ) {
      return t('agent.creditReport.successReport');
    } else if (errorType === ReportErrorTypes.ERROR) {
      return t('agent.creditReport.errorReport');
    } else if (errorType === ReportErrorTypes.EXPIRED) {
      return t('agent.creditReport.expiredReport');
    }

    return t('agent.creditReport.inProgressReport');
  };

  return (
    <div className={styles.Container}>
      {shouldShowLoaderRows && (
        <div className={styles.LoaderRows}>
          {Array.from({ length: 3 }, (_, index) => (
            <ReportSkeletonRow key={`report-loader-row-${index}`} />
          ))}
        </div>
      )}
      {!!errorType && (
        <div className={styles.StatusContainer}>
          {getErrorIcon()}
          {isSuperAdmin && renterCreditProfile?.success === true && (
            <div className={styles.ErrorMessage}>{t('agent.creditReport.successHeading')}</div>
          )}
          <div
            className={
              isSuperAdmin && renterCreditProfile?.success === true ? styles.SuccessReportMessage : styles.ErrorMessage
            }
          >
            {getErrorMessage()}
          </div>
        </div>
      )}
      {errorCode && errorMessage && (
        <div className={styles.ErrorDiscriptionContainer}>
          <div className={styles.Code}>{`Code: ${errorCode} ${errorMessage}`}</div>
          {/* <div className={styles.ExtraDiscription}>
            {
              '{Cause} Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id mauris nisl. Vestibulum faucibus porta tristique. Nam auctor vulputate hendrerit. Etiam id lacinia tortor.'
            }
          </div> */}
          {/* <RCButton className={styles.ErrorButton} variant="outline">
            {'CTA {Ref: Cause}'}
          </RCButton> */}
        </div>
      )}
    </div>
  );
};
