import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { addRenterProfileInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import RCButton from 'components/shared/Button/Button';
import { NovaAuthenticationStatus, RenterProfileSteps } from 'constants/renterConstants';
import { renterRoutes } from 'shared/routes';

import RenterIconInfoContainer from '../components/RenterIconInfoContainer';

import indexStyles from '../RenterIndex.module.scss';
import styles from './SSNCheck.module.scss';

const SSNCheck = (): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const { userId, hasNoSSN, ssnTailDigits, novaAuth, visaDocuments } = useSelector(selectRenterProfileInformation);
  const nextStep = useCallback(() => {
    navigate(renterRoutes.generatePersonalInformationUrl(RenterProfileSteps.SSNINPUT, Number(applicationId)));
  }, [applicationId, navigate]);

  useEffect(() => {
    if (hasNoSSN) {
      const nextNavigation =
        novaAuth === NovaAuthenticationStatus.SUCCESS || visaDocuments
          ? renterRoutes.generateGuarantorInformation(Number(applicationId))
          : renterRoutes.generateRenterCreditHistoryUrl(Number(applicationId));

      navigate(nextNavigation);
    } else if (ssnTailDigits) {
      nextStep();
    }
  }, [applicationId, hasNoSSN, navigate, nextStep, ssnTailDigits, novaAuth, visaDocuments]);

  return (
    <div>
      <RenterIconInfoContainer />
      <div className={indexStyles.renterContentContainer}>
        <h2 className={indexStyles.renterH2}>{t('renter.sSNCheck.doYouHaveASocialSecurityNumber?')}</h2>
        <div className={classNames(indexStyles.renterButtonContainer, styles.ssnCheckButtonContainer)}>
          <RCButton
            className={classNames(indexStyles.renterButton, styles.renterCreditButton)}
            variant="outline"
            onClick={nextStep}
          >
            {t('renter.sSNCheck.yes')}
          </RCButton>
        </div>
        <div className={classNames(indexStyles.renterButtonContainer, styles.ssnCheckButtonContainer)}>
          <RCButton
            onClick={() => {
              const params = { hasNoSSN: true, id: userId };

              if (!hasNoSSN) {
                dispatch(addRenterProfileInformation({ values: params }));
              }

              navigate(renterRoutes.generateRenterCreditHistoryUrl(`${applicationId}`));
            }}
            className={classNames(indexStyles.renterButton, styles.renterCreditButton)}
            variant="outline"
          >
            {t('renter.sSNCheck.no')}
          </RCButton>
        </div>
      </div>
    </div>
  );
};

export default SSNCheck;
