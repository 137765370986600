export enum OrganizationNavbarKeys {
  ORGANIZATIONS = 'Organizations',
  REVENUE = 'Revenue',
  VERIFCIATIONS = 'Verifications',
  PAYMENT = 'Payment',
}

export const OrgNavbarSteps = [
  OrganizationNavbarKeys.ORGANIZATIONS,
  OrganizationNavbarKeys.REVENUE,
  OrganizationNavbarKeys.VERIFCIATIONS,
  OrganizationNavbarKeys.PAYMENT,
];
