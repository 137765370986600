import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { select2FaToken } from 'redux/selectors/authSelector';
import { setTwoFactorAuth } from 'redux/slices/authSlice';
import { AppThunkDispatch } from 'redux/store';

import { StepsOptions2FA } from 'constants/agentConstants';
import { parseResponseErrors } from 'helpers/helper';
import { resend2faCodeViaToken, submit2faCode } from 'services/authService';
import { TwilioOtpErrorCodes } from 'shared/constants';
import { Notification } from 'shared/Notification/Notification';
import { Submit2FAValues } from 'shared/types/authType';
import { ConfirmOtpProps } from 'shared/types/sharedTypes';

import { OtpView } from './OtpView';

import styles from '../Modal2FA.module.scss';

export const ConfirmOtp = memo(({ setModalSteps, setIsModalOpen, phoneNumber }: ConfirmOtpProps): JSX.Element => {
  const [otpCode, setOtpCode] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const authToken2FA = useSelector(select2FaToken);
  const { t } = useTranslation();
  const dispatch = useDispatch<AppThunkDispatch>();
  const handleResendOtp = (): void => {
    resend2faCodeViaToken({ token: authToken2FA as string })
      .then((res) => {
        const message = (res?.data as { payload: string })?.payload;

        Notification({ message, type: 'success' });
      })
      .catch((errorResponse) => {
        Notification({ message: parseResponseErrors(errorResponse) });
      });
  };
  const submitOTP = useCallback(() => {
    setIsSubmitting(true);
    const body: Submit2FAValues = {
      code: otpCode,
      token: authToken2FA ?? '',
    };

    setIsError(false);
    submit2faCode(body)
      .then(() => {
        dispatch(setTwoFactorAuth());
        setModalSteps(StepsOptions2FA.SUCCESS);
      })
      .catch((errorResponse) => {
        const { errorCode } = errorResponse?.response?.data?.errors?.[0];

        if (errorCode === TwilioOtpErrorCodes.INVALID_CODE) {
          setIsError(true);
        } else {
          Notification({ message: parseResponseErrors(errorResponse) });
          setModalSteps(StepsOptions2FA.FAILURE);
        }
      })
      .finally(() => setIsSubmitting(false));
  }, [authToken2FA, dispatch, otpCode, setModalSteps]);

  return (
    <>
      <div className={styles.ModalBodyText}>
        <div className={styles.SuccessContainer}>
          <p className={styles.FontStyle2}>{t('otpModal.2FASetup')}</p>
          <p className={styles.FontStyle4}>{t('otpModal.2FAstep2')}</p>
        </div>
      </div>
      <OtpView
        setIsModalOpen={setIsModalOpen}
        setOtpCode={setOtpCode}
        submitOTP={submitOTP}
        isError={isError}
        isSubmitting={isSubmitting}
        otpCode={otpCode}
        phoneNumber={phoneNumber}
        handleResendOtp={handleResendOtp}
      />
    </>
  );
});
