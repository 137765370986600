import { AgentProfileInformationProps } from 'shared/types/agentTypes';
import {
  AdultInviteDetails,
  AdultInviteInitialValues,
  GuarantorInviteDetails,
  GuarantorInviteInitialValues,
  InviteInformationProps,
  InviteInitialValues,
  RenterInviteParams,
  SecurityQuestionsProps,
  SecurityQuestionsPropsRenter,
} from 'shared/types/authType';
import { AgentOrganizationInvitesProps } from 'shared/types/organizationTypes';

import { RootState } from '../types';

export const isAuthenticated = (state: RootState): boolean => state.authSlice.isAuthenticated;
export const isLoading = (state: RootState): boolean => state.authSlice.isLoading;
export const agentId = (state: RootState): number | undefined => state.authSlice.profileInformation.agentId;
export const userType = (state: RootState): string => state.authSlice.userType;
export const selectAgentProfileInformation = (state: RootState): AgentProfileInformationProps =>
  state.authSlice.profileInformation;
export const renterId = (state: RootState): number | undefined => state.authSlice.renterId;
export const inviteDetails = (state: RootState): InviteInformationProps =>
  state.authSlice.inviteDetails || InviteInitialValues; //TODO: Added undefined case until we are sure that no current user will have an un-consistent authSlice;
export const selectAdultInviteDetails = (state: RootState): AdultInviteDetails =>
  state.authSlice.adultInviteDetails || AdultInviteInitialValues; //TODO: Added undefined case until we are sure that no current user will have an un-consistent authSlice;
export const selectGuarantorInviteDetails = (state: RootState): GuarantorInviteDetails =>
  state.authSlice.guarantorInviteDetails || GuarantorInviteInitialValues; //TODO: Added undefined case until we are sure that no current user will have an un-consistent authSlice;
export const selectInviteCode = (state: RootState): string => state.authSlice.inviteCode;
export const inviteType = (state: RootState): string => state.authSlice.inviteType;
export const inviteSubType = (state: RootState): string => state.authSlice.inviteSubType;
export const serverErrorAuth = (state: RootState): number | undefined => state.authSlice?.serverError;
export const renterInviteCode = (state: RootState): RenterInviteParams => state.authSlice.renterInviteCode;
export const selectApplicationId = (state: RootState): number => state.authSlice.applicationId;
export const agentOrganizationInviteDetails = (state: RootState): AgentOrganizationInvitesProps =>
  state.authSlice.agentOrganizationInviteDetails || ({} as AgentOrganizationInvitesProps); //TODO: Added undefined case until we are sure that no current user will have an un-consistent authSlice;
export const selectSecurityQuestions = (state: RootState): SecurityQuestionsProps[] =>
  state.authSlice.securityQuestions;
export const selectSecurityQuestionsRenter = (state: RootState): SecurityQuestionsPropsRenter[] =>
  state.authSlice.securityQuestionsRenter;
export const select2FaToken = (state: RootState): string | null => state.authSlice.authToken2FA;
export const select2Fa = (state: RootState): boolean => state.authSlice.twoFactor;
