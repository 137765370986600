// VerifierContent.tsx

import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';

import { RenterAddressTypes } from 'constants/renterConstants';
import { getEmploymentList, getTenancyList } from 'helpers/agentHelper';
import { formatRenterName } from 'helpers/renterHelper';
import { AddressVerificationResponse } from 'shared/types/agentTypes';
import {
  AddressInformation,
  AddressInformationResponse,
  RenterPresentEmployments,
  RenterProfileInformationProps,
} from 'shared/types/renterTypes';

import PresentEmploymentCard from '../../../RenterIncome/PresentIncome/PresentEmploymentCard/PresentEmploymentCard';
import AddressContainer from '../AddressContainer/AddressContainer';
import { ResidencyVerification } from '../ResidencyVerification/ResidencyVerification';
import { VerifierNavbar } from '../VerifierNavbar/VerifierNavbar';

import styles from './VerifierDetailsModal.module.scss';

interface VerifierContentProps {
  title: string;
  renterDetails: RenterProfileInformationProps;
  addressVerificationInformation?: AddressVerificationResponse[];
  employmentVerificationInformation?: AddressVerificationResponse[];
  address?: AddressInformationResponse;
  employment?: RenterPresentEmployments;
  isModalOpen?: boolean;
  addressApplicationId?: number;
}

export const VerifierDetailsData = ({
  title,
  renterDetails,
  addressVerificationInformation,
  employmentVerificationInformation,
  address,
  employment,
  isModalOpen,
  addressApplicationId,
}: VerifierContentProps): JSX.Element => {
  const { t } = useTranslation();
  const [selectedAddress, setSelectedAddress] = useState<AddressInformation>(address ?? {});
  const [selectedEmployment, setSelectedEmployment] = useState<RenterPresentEmployments>(employment ?? {});
  const currentAddressInformation = addressVerificationInformation?.find(
    (addressInfo) => addressInfo?.renterAddressInformation?.addressId === selectedAddress.id
  );
  const currentEmploymentInformation = employmentVerificationInformation?.find(
    (employmentInfo) => employmentInfo?.renterEmploymentInformation?.presentEmploymentId === selectedEmployment.id
  );

  return (
    <div>
      {isEmpty(addressVerificationInformation) ? (
        <Spinner />
      ) : (
        <div className={styles.Container}>
          <div className={styles.NonScrollableContent}>
            <div className={styles.Heading}>{title}</div>
            <div className={styles.RenterName}>{formatRenterName(renterDetails)}</div>
            <VerifierNavbar
              tenancyList={
                address?.id
                  ? getTenancyList(renterDetails?.addresses ?? [])
                  : getEmploymentList(renterDetails.presentEmployments ?? [])
              }
              setSelectedAddress={address?.id ? setSelectedAddress : setSelectedEmployment}
              activeTab={address?.id ? address : employment ?? {}}
            />
          </div>
          <div className={styles.ScrollableContent}>
            {address?.id && (
              <AddressContainer
                address={selectedAddress}
                timePeriodLabel={t('application.residency')}
                isAddressInline
                isPresent={selectedAddress?.addressType === RenterAddressTypes.PRESENT}
                shouldShowHeading={false}
                isVerifierAddress
                verificationInformation={currentAddressInformation}
                isModalOpen={isModalOpen}
                addressApplicationId={addressApplicationId}
              />
            )}
            {employment?.id && <PresentEmploymentCard isVerifierModal employment={selectedEmployment ?? {}} />}
            <ResidencyVerification
              title={
                address?.id
                  ? t('renter.verifier.verification')
                  : t('agent.applicants.renterResume.sourceOfIncome.verification')
              }
              verificationInformation={address?.id ? currentAddressInformation : currentEmploymentInformation}
            />
          </div>
        </div>
      )}
    </div>
  );
};
