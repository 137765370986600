import { useSearchParams } from 'react-router-dom';
import { TwoFactorSetup } from 'pages/TwoFactorSetup/TwoFactorSetup';

import { AgentSettings, MENU_OPTIONS } from 'constants/agentConstants';

import { AccountPreferences } from './components/AccountPreferences/AccountPreferences';
import { SideMenu } from './components/SideMenu';

import 'stylesheets/mixins/sharedStyles.scss';
import styles from './Settings.module.scss';

export const Settings = (): JSX.Element => {
  const [params, setSearchParams] = useSearchParams();
  const activeSection = params.get('section') ?? AgentSettings.PROFILE;
  const switchSection = (section: string): void => {
    const searchParams = new URLSearchParams(params.toString());

    if (searchParams.get('activeTab')) {
      searchParams.delete('activeTab');
      setSearchParams(searchParams.toString());
      setSearchParams({});
    }

    searchParams.set('section', section);
    setSearchParams(searchParams.toString());
  };

  return (
    <>
      <SideMenu heading="Settings" options={MENU_OPTIONS} switchSection={switchSection} activeSection={activeSection} />
      <div className={styles.MenuSection}>
        {activeSection === AgentSettings.ACCOUNT && <TwoFactorSetup />}
        {activeSection === AgentSettings.PROFILE && <AccountPreferences />}
      </div>
    </>
  );
};
