import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectAgentProfileInformation } from 'redux/selectors/agentSelector';

import { ReactComponent as CheckIconFillPrimary } from 'assets/svgs/CheckIconFillPrimary.svg';
import { ReactComponent as WarningIcon } from 'assets/svgs/ErrorInfo.svg';
import { ReactComponent as International } from 'assets/svgs/International.svg';
import { ReactComponent as ErrorIcon } from 'assets/svgs/RedWarningIcon.svg';
import AgentPills, { PillTypes } from 'components/shared/AgentPills/AgentPills';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import { CreditCheckProvider, ExperianAuthenticationStatus, NovaAuthenticationStatus } from 'constants/renterConstants';
import { PropertyApplicationStatus } from 'shared/types/agentTypes';
import { UserType } from 'shared/types/authType';

import styles from './CreditBackgroundCapsule.module.scss';
export const CreditBackgroundCapsule = ({
  creditScore,
  experianAuth,
  novaAuth,
  creditCheckProvider,
  novaCreditScore,
  onCustomClick,
  applicationStatus,
}: {
  creditScore: string | null;
  experianAuth?: ExperianAuthenticationStatus;
  novaAuth?: NovaAuthenticationStatus;
  creditCheckProvider?: CreditCheckProvider;
  novaCreditScore?: number | null;
  onCustomClick?: () => void;
  applicationStatus?: PropertyApplicationStatus;
}): JSX.Element => {
  const { t } = useTranslation();
  const applicant = useSelector(selectAgentProfileInformation);
  const isSuperAdmin = applicant.agentProfessionalProfile?.role === UserType.SUPERADMIN;
  const getCreditScoreElement = useMemo((): JSX.Element => {
    if (
      experianAuth === ExperianAuthenticationStatus.COMPLETED &&
      applicant.agentProfessionalProfile?.role === UserType.SUPERADMIN
    ) {
      return <CheckIconFillPrimary height={20} width={20} />;
    }

    if (
      experianAuth === ExperianAuthenticationStatus.IN_PROGRESS ||
      experianAuth === ExperianAuthenticationStatus.PENDING
    ) {
      return <WarningIcon height={20} width={20} />;
    }

    return <ErrorIcon height={20} width={20} />;
  }, [applicant.agentProfessionalProfile?.role, experianAuth]);
  const getNovaCreditScoreElement = useMemo((): JSX.Element => {
    if (novaAuth === NovaAuthenticationStatus.IN_PROGRESS || novaAuth === NovaAuthenticationStatus.PENDING) {
      return <WarningIcon height={20} width={20} />;
    }

    return <ErrorIcon height={20} width={20} />;
  }, [novaAuth]);
  const reportDisplayCapsule = (): JSX.Element => {
    switch (creditCheckProvider) {
      case CreditCheckProvider.EXPERIAN:
        return (
          <button disabled={experianAuth ? false : true} onClick={onCustomClick} className={styles.customNavButton}>
            <Tooltip
              placement="top"
              {...(creditScore || !isSuperAdmin || experianAuth !== ExperianAuthenticationStatus.COMPLETED
                ? { text: creditScore ? t('agent.viewDetails') : t('agent.renterHasNotSubmitted') }
                : { text: 'Successful' })}
              icon={
                creditScore ? (
                  <>
                    <AgentPills
                      pillContainerClassName={styles.PillContainer}
                      pillsData={[{ heading: `${creditScore}`, type: PillTypes.SUCCESS_LIGHT }]}
                    />
                    <div className={styles.LabelContainer}>
                      <div className={styles.CapsuleLabel}>{t('auth.creditCheckProvider.experian')}</div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.EmptyState}>{getCreditScoreElement}</div>
                    <div className={styles.LabelContainer}>
                      <div className={styles.CapsuleLabel}>{t('auth.creditCheckProvider.experian')}</div>
                    </div>
                  </>
                )
              }
              customIconClassName={styles.CountContainer}
            />
          </button>
        );
      case CreditCheckProvider.NOVA:
        return (
          <button disabled={novaAuth ? false : true} onClick={onCustomClick} className={styles.customNavButton}>
            <Tooltip
              placement="top"
              text={novaCreditScore ? t('agent.viewDetails') : t('agent.renterHasNotSubmitted')}
              icon={
                novaCreditScore ? (
                  <>
                    <AgentPills
                      pillContainerClassName={styles.PillContainer}
                      pillsData={[{ heading: `${novaCreditScore}`, type: PillTypes.MIDNIGHT_STEEL }]}
                    />
                    <div className={styles.LabelContainer}>
                      <div className={styles.CapsuleLabel}>{t('auth.creditCheckProvider.nova')}</div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.EmptyState}>{getNovaCreditScoreElement}</div>
                    <div className={styles.LabelContainer}>
                      <div className={styles.CapsuleLabel}>{t('auth.creditCheckProvider.nova')}</div>
                    </div>
                  </>
                )
              }
              customIconClassName={styles.CountContainer}
            />
          </button>
        );
      case CreditCheckProvider.DOCUMENT:
        return (
          <button
            disabled={applicationStatus === PropertyApplicationStatus.COMPLETED ? false : true}
            onClick={onCustomClick}
            className={styles.customNavButton}
          >
            <Tooltip
              placement="top"
              text={
                applicationStatus === PropertyApplicationStatus.COMPLETED
                  ? t('agent.viewDetails')
                  : t('agent.renterHasNotSubmitted')
              }
              icon={
                applicationStatus === PropertyApplicationStatus.COMPLETED ? (
                  <>
                    <International />
                    <div className={styles.LabelContainer}>
                      <div className={styles.CapsuleLabel}>{t('auth.creditCheckProvider.document')}</div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.EmptyState}>___</div>
                    <div className={styles.LabelContainer}>
                      <div className={styles.CapsuleLabel}>{t('auth.creditCheckProvider.document')}</div>
                    </div>
                  </>
                )
              }
              customIconClassName={styles.CountContainer}
            />
          </button>
        );
      default:
        return (
          <>
            <Tooltip
              placement="top"
              text={t('agent.renterHasNotSubmitted')}
              icon={<div className={styles.EmptyState}>{'___'}</div>}
              customIconClassName={styles.CountContainer}
            />
            <div className={styles.LabelContainer}>
              <div className={styles.CapsuleLabel}>{t('auth.creditCheckProvider.noReport')}</div>
            </div>
          </>
        );
    }
  };

  return <div className={styles.CapsuleContainer}>{reportDisplayCapsule()}</div>;
};
