import { Dispatch, memo, SetStateAction, useCallback, useState } from 'react';
import { Modal as BModal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setIsAuthenticated } from 'redux/slices/authSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as Security2FA } from 'assets/svgs/Security2FA.svg';
import AgentModal from 'components/Agent/components/AgentModal/AgentModal';
import { parseResponseErrors } from 'helpers/helper';
import { resend2faCode, verify2faCode } from 'services/authService';
import { TwilioOtpErrorCodes } from 'shared/constants';
import { Notification } from 'shared/Notification/Notification';
import { Verify2FAValues } from 'shared/types/authType';

import { OtpView } from './components/OtpView';

import styles from './Modal2FA.module.scss';

type Props = {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  email: string;
  phoneNumber: string;
};

export const OtpVerifyModal = memo(({ setIsModalOpen, email, phoneNumber }: Props): JSX.Element => {
  const [otpCode, setOtpCode] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const dispatch = useDispatch<AppThunkDispatch>();
  const handleResendOtp = (): void => {
    resend2faCode({ email })
      .then((res) => {
        const message = (res?.data as { payload: string })?.payload;

        Notification({ message, type: 'success' });
      })
      .catch((errorResponse) => {
        Notification({ message: parseResponseErrors(errorResponse) });
      });
  };
  const submitOTP = useCallback(() => {
    setIsSubmitting(true);
    const body: Verify2FAValues = {
      code: otpCode,
      email: email,
    };

    setIsError(false);
    verify2faCode(body)
      .then(() => {
        dispatch(setIsAuthenticated());
        setIsModalOpen(false);
      })
      .catch((errorResponse) => {
        const { errorCode } = errorResponse?.response?.data?.errors?.[0];

        if (errorCode === TwilioOtpErrorCodes.INVALID_CODE) {
          setIsError(true);
        } else {
          Notification({ message: parseResponseErrors(errorResponse) });
          setIsModalOpen(false);
        }
      })
      .finally(() => setIsSubmitting(false));
  }, [dispatch, email, otpCode, setIsModalOpen]);

  return (
    <AgentModal
      show
      onHide={() => setIsModalOpen(false)}
      customTitleClassName={styles.modalTitle}
      isCrossIconVisible={false}
      customModalClassName={styles.Outter2FAContainer}
    >
      <BModal.Body className={styles.Modal2FAContainer}>
        <div>
          <div className={styles.ModalHeaderText}>
            <Security2FA />
            <p className={styles.FontStyle1}>
              2FA by <b>Intellirent</b>
            </p>
          </div>
          <div className={styles.ModalHeader}>
            <p className={styles.FontStyle2}>2FA SMS</p>
          </div>
          <OtpView
            setIsModalOpen={setIsModalOpen}
            setOtpCode={setOtpCode}
            submitOTP={submitOTP}
            isError={isError}
            isSubmitting={isSubmitting}
            otpCode={otpCode}
            phoneNumber={phoneNumber}
            handleResendOtp={handleResendOtp}
          />
        </div>
      </BModal.Body>
    </AgentModal>
  );
});
