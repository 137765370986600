import { useCallback } from 'react';
import { Nav, Navbar, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { t } from 'i18next';
import { selectAgentProfileInformation } from 'redux/selectors/agentSelector';

import { ReactComponent as HomeDesign } from 'assets/svgs/authBackGround.svg';
import { SettingsAccountPreferenceKeys } from 'constants/resumeSideMenuConstants';
import { IsMobile } from 'helpers/IsMobile';

import { BusinessDetailsForm } from './BusinessDetailsForm';
import { ProfessionalDetailsForm } from './ProfessionalDetailsForm';

import styles from '../../Settings.module.scss';

export const AccountPreferences = (): JSX.Element => {
  const [params, setSearchParams] = useSearchParams();
  const isMobileView = IsMobile();
  const profileInfo = useSelector(selectAgentProfileInformation);
  const activeTabName = params.get('activeTab') ?? SettingsAccountPreferenceKeys.DETAILS;
  const tabSwitch = useCallback(
    (activeTab: string) => {
      const searchParams = new URLSearchParams(params.toString());

      searchParams.set('activeTab', activeTab);
      setSearchParams(searchParams.toString());
    },
    [params, setSearchParams]
  );

  return (
    <div>
      <div className={styles.Padding}>
        <div className={styles.OrganizationContainer}>
          <div className={styles.OrganizationName}>{profileInfo.businessName}</div>
          <div className={styles.OptionSelected}>
            <div className={styles.Title}>{t('agent.settings.details.professional.title')}</div>
          </div>
        </div>
        {!isMobileView && <HomeDesign className={classNames(styles.homeDesignTop)} />}
        <Navbar expand="lg" className={styles.NavBarWidth}>
          <div className={styles.Container}>
            <Nav
              className={classNames(styles.NavLinks, {
                [styles.active]: activeTabName === SettingsAccountPreferenceKeys.DETAILS,
              })}
              onClick={() => tabSwitch(SettingsAccountPreferenceKeys.DETAILS)}
            >
              {t('agent.settings.details.professional.nav.details')}
            </Nav>
            <Nav
              className={classNames(styles.NavLinks, {
                [styles.active]: activeTabName === SettingsAccountPreferenceKeys.EMAIL,
              })}
              onClick={() => tabSwitch(SettingsAccountPreferenceKeys.EMAIL)}
            >
              {t('agent.settings.details.professional.nav.email')}
            </Nav>
            <Nav
              className={classNames(styles.NavLinks, {
                [styles.active]: activeTabName === SettingsAccountPreferenceKeys.NOTIFICATION,
              })}
              onClick={() => tabSwitch(SettingsAccountPreferenceKeys.NOTIFICATION)}
            >
              {t('agent.settings.details.professional.nav.notification')}
            </Nav>
          </div>
        </Navbar>

        <div className={styles.CenterContainer}>
          <ProfessionalDetailsForm />
          <BusinessDetailsForm />
        </div>
        <div className={styles.LastContainer}>
          <div className={styles.ProfHeading}>{t('agent.settings.details.personal.heading')}</div>
          <div className={styles.PersonalBox}>
            <div className={styles.PersonalText}>{t('agent.settings.details.personal.text')}</div>
            <div className={styles.EditContainer}>
              <Row className={styles.FullName}>
                <div className={styles.FullNameHeading}>{t('agent.settings.details.personal.fullName')}</div>
                <div className={styles.Name}>
                  {profileInfo.firstName} {profileInfo.lastName}
                </div>
              </Row>
            </div>
            <div className={styles.FullName}>
              <div className={styles.FullNameHeading}>{t('agent.settings.details.personal.address')}</div>
              <div className={styles.Name}>{profileInfo.mailingStreetAddress}</div>
              <div className={styles.Name}>
                {profileInfo.city}, {profileInfo.state} {profileInfo.zipCode}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
