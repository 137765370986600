import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckCircle } from 'assets/svgs/check_circle.svg';
import RCButton from 'components/shared/Button/Button';
import { TwoFactorStatusProps } from 'shared/types/sharedTypes';

import styles from '../Modal2FA.module.scss';

export const TwoFactorSuccess = memo(({ setIsModalOpen }: TwoFactorStatusProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.ModalBodyText}>
        <div className={styles.SuccessContainer}>
          <p className={styles.FontStyle2}>{t(`otpModal.2FASmsSetup`)}</p>
          <p className={styles.FontStyle4}>{t(`otpModal.2FAcomplete`)}</p>
        </div>
      </div>
      <div className={styles.SuccessContainer2}>
        <CheckCircle className={styles.CheckCircle} />
        <p className={styles.SuccessStyle1}>{t(`otpModal.2FAthankYou`)}</p>
        <p className={styles.SuccessStyle2}>{t(`otpModal.2FASmsAuth`)}</p>
      </div>
      <div className={styles.ButtonContainer2FA}>
        <RCButton
          onClick={() => {
            setIsModalOpen(false);
          }}
          variant="outline"
          className={styles.Continue}
        >
          {'Done'}
        </RCButton>
      </div>
    </>
  );
});
