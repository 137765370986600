import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import lodash from 'lodash';
import { housingProviderCategories } from 'pages/AgentPofileForm/types';
import { selectAgentProfileInformation } from 'redux/selectors/agentSelector';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { getAgentProfileInformation } from 'redux/slices/authSlice';
import { AppThunkDispatch } from 'redux/store';
import { AgentProfessionalProfileSchema } from 'schema/agentSchema';

import defaultImage from 'assets/images/defaultProfilePicture.png';
import RCButton from 'components/shared/Button/Button';
import FormikSelectField from 'components/shared/FormikSelectField/FormikSelectField';
import FormikPhoneNumber from 'components/shared/PhoneNumber/FormikPhoneNumber';
import ProfileInfo from 'components/shared/ProfileInfoWithoutDropdown/ProfileIconWithoutDropdown';
import FormikField from 'components/shared/TextField/FormikTextField';
import { PhoneNoTypes } from 'constants/agentConstants';
import { updateAgentProfessionalProfile } from 'services/agentService';

import { SettingsSaveRow } from '../../SettingsSaveRow';

import styles from '../../Settings.module.scss';

export const ProfessionalDetailsForm = (): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const [isEditable, setIsEditable] = useState(false);
  const profileInfo = useSelector(selectAgentProfileInformation);
  const currentOrganization = useSelector(selectedOrganization);
  const initialFormValues = {
    fullName: [
      profileInfo.agentProfessionalProfile?.firstName ?? '',
      profileInfo.agentProfessionalProfile?.lastName ?? '',
    ].join(' '),
    license: profileInfo?.agentProfessionalProfile?.license ?? '',
    housingProviderCategory: profileInfo.agentProfessionalProfile?.housingProviderCategory ?? '',
    phoneNumber: profileInfo.agentProfessionalProfile?.phoneNumber ?? '',
    phoneType: lodash.capitalize(profileInfo.agentProfessionalProfile?.phoneType ?? ''),
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={AgentProfessionalProfileSchema}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        const [firstName, ...lastName] = values.fullName.split(' ');
        const body = {
          ...values,
          firstName,
          lastName: lastName.join(' '),
          license: values.license,
          phoneType: values.phoneType?.toLocaleLowerCase(),
        };

        updateAgentProfessionalProfile(body, currentOrganization?.id)
          .then(() => {
            dispatch(getAgentProfileInformation());
          })
          .finally(() => {
            setIsEditable(false);
            setSubmitting(false);
          });
      }}
    >
      {({ handleSubmit, isSubmitting, isValid, resetForm, dirty: isDirty }) => (
        <Form className={styles.FormikMainContainer}>
          <div className={isEditable ? styles.EditContainer : styles.ViewContainer}>
            <RCButton className={styles.EditButton} variant="outline" onClick={() => setIsEditable(true)}>
              {t('application.edit')}
            </RCButton>
            <ProfileInfo profileImgStyling={styles.ImageSize} imageSrc={defaultImage} />
            <div className={styles.ProfessionalContainer}>
              <div className={styles.ProfHeadingContainer}>
                <div className={styles.ProfHeading}>Professional</div>
                <div className={styles.TextDiv}>{t('agent.settings.details.professional.nav.details.text')}</div>
              </div>
            </div>
            <div className={styles.FirstContainer}>
              <Row>
                <Col lg={3} md={8} sm={12} className={styles.OptionHeading}>
                  {t('agent.settings.details.professional.details.housing.heading')}
                </Col>
                <Col lg={9} md={12} sm={12} className={styles.TextAlignForDropDown}>
                  <FormikSelectField
                    name="housingProviderCategory"
                    options={housingProviderCategories}
                    placeholder="Select"
                    hasFloatingLabel={false}
                    isCustomOptions={true}
                    isDisabled={true}
                    backgroundColor={!isEditable ? '#ffffff' : '#F7FAFA'}
                    borderStyle="1px solid #D2D6D6"
                    className={styles.HousingProviderCategory}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={3} md={8} sm={12} className={styles.OptionHeading}>
                  {t('agent.settings.details.professional.details.license.heading')}
                </Col>
                <Col lg={9} md={12} sm={12} className={styles.TextAlignForDropDown}>
                  <FormikField
                    placeholder=""
                    className={styles.DisableFieldViewStyle}
                    name="license"
                    disabled={!isEditable}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={8} sm={12} className={styles.OptionHeading}>
                  {t('agent.settings.details.professional.details.fullName')}
                </Col>
                <Col lg={9} md={12} sm={12} className={styles.TextAlignForDropDown}>
                  <FormikField className={styles.DisableFieldViewStyle} name="fullName" disabled={!isEditable} />
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={8} sm={12} className={styles.OptionHeading}>
                  {t('agent.settings.details.professional.details.phone')}
                </Col>
                <Col lg={6} md={9} sm={12} className={styles.phoneNumberContainer}>
                  <FormikPhoneNumber
                    className={styles.DisableFieldViewStyle}
                    name="phoneNumber"
                    isDisabled={!isEditable}
                    placeholder="(  )  -"
                    isShowCountryCode={false}
                  />
                </Col>
                <Col lg={3} md={3} sm={12} className={styles.stateSelectField}>
                  <span className={styles.textFieldLabel}>{t('agent.settings.details.professional.details.type')}</span>
                  <FormikSelectField
                    name="phoneType"
                    backgroundColor="#ffffff"
                    placeholder={t('renter.realtionship.select')}
                    options={PhoneNoTypes}
                    borderStyle="1px solid #D2D6D6"
                    isDisabled={!isEditable}
                  />
                </Col>
              </Row>
            </div>
          </div>
          {isEditable && (
            <div className={styles.ButtonContainerSettings}>
              <SettingsSaveRow
                handleSaveClick={handleSubmit}
                handleCancelClick={() => {
                  setIsEditable(false);
                  resetForm();
                }}
                isSubmitting={isSubmitting}
                isValid={isValid}
                isDirty={isDirty}
              />
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};
