import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { PublicRecordInformationProps } from 'shared/types/reportTypes';

import { ReportHeading } from '../ReportHeading/ReportHeading';
import { EmptyReportRecord } from '../SharedReports/EmptyReportRecord/EmptyReportRecord';
import { PublicRecordReport } from '../SharedReports/PublicRecordReport/PublicRecordReport';

import styles from './PublicRecordInformation.module.scss';

export const PublicRecordInformation = ({
  publicRecordInformation,
  isRenterFlow = false,
}: PublicRecordInformationProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <ReportHeading
        heading={t('agent.creditReport.publicRecord')}
        hasCounter={!!publicRecordInformation?.length}
        counter={publicRecordInformation?.length ?? 0}
      />
      {publicRecordInformation && publicRecordInformation.length > 0 && (
        <div className={styles.publicReportHeader}>
          <p className={styles.publicReportStatus}>{publicRecordInformation[0].Status.desc}</p>
          <div className={styles.publicReportDiv}>
            <p className={styles.publicReportEvaluation}>{t('creditReport.evaluation')}</p>
            <p className={styles.publicReportEvaluationVal}>{publicRecordInformation[0].Evaluation.desc}</p>
          </div>
        </div>
      )}
      {!!publicRecordInformation?.length ? (
        <div className={styles.PublicRecords}>
          {publicRecordInformation.map((publicRecord, index) => (
            <div
              key={`public-record-${publicRecord}-${index}`}
              className={classNames(styles.PublicRecordItem, {
                [styles.ItemDivider]: index !== publicRecordInformation?.length - 1,
              })}
            >
              <PublicRecordReport isRenterFlow={isRenterFlow} publicRecord={publicRecord} />
            </div>
          ))}
        </div>
      ) : (
        <EmptyReportRecord />
      )}
    </div>
  );
};
