import { useCallback, useMemo } from 'react';
import { Col, Navbar as BootstrapNavbar, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { t } from 'i18next';
import { capitalize } from 'lodash-es';

import { ReactComponent as HelpIcon } from 'assets/svgs/HelpGrayIcon.svg';
import { ReactComponent as IntellirentIcon } from 'assets/svgs/IntellirentIcon.svg';
import { ReactComponent as MenuIcon } from 'assets/svgs/menu.svg';
import ProfileDropdown from 'components/shared/ProfileDropdown/ProfileDropdown';
import { ApplicantStatus } from 'constants/dropdownOptions';
import { propertyOptionsTransaltion } from 'constants/propertyConstants';
import { checkFormPage, checkProfilePage, urlMatcher } from 'helpers/agentHelper';
import { IntellirentPublicUrls } from 'shared/constants';
import { routes } from 'shared/routes';
import { AgentProfileInformationProps } from 'shared/types/agentTypes';
import { OrganizationProps } from 'shared/types/organizationTypes';
import { PaginationType } from 'shared/types/paginationTypes';
import { ApplicantType, PropertyType } from 'shared/types/propertType';

import LabelIcon from '../../../LabelIcon/LabelIcon';

import styles from './../AgentNavbar.module.scss';
export interface AgentMobileNavBarProps {
  selectedOrg: OrganizationProps;
  agentProfileInformation: AgentProfileInformationProps;
  pageInfo: PaginationType;
}
const AgentMobileNavBar = ({ selectedOrg, agentProfileInformation, pageInfo }: AgentMobileNavBarProps): JSX.Element => {
  const [searchParams] = useSearchParams();
  const activeOptionProperty = searchParams.get('option') || capitalize(PropertyType.PUBLISHED);
  const activeOptionApplicant = searchParams.get('option') || ApplicantType.ACTIVE;
  const location = useLocation();
  const isProfilePage = checkProfilePage(location);
  const isFormPage = checkFormPage(location);
  const isNonProfilePage = useMemo((): boolean => !isFormPage && !isProfilePage, [isFormPage, isProfilePage]);
  const displayOptions = useCallback((): JSX.Element | undefined => {
    if (urlMatcher(routes.properties, location)) {
      return (
        <div className={styles.horizantalTagDiv}>
          <Row className={styles.scrollableDiv}>
            {propertyOptionsTransaltion.map((propertyOption) => (
              <Link
                to={`Properties?option=${propertyOption.label}`}
                key={propertyOption.label}
                className={classNames(styles.tagDiv, {
                  [styles.active]: activeOptionProperty === propertyOption.label,
                })}
              >
                <div className={styles.optionsDiv}>{propertyOption.value}</div>
              </Link>
            ))}
          </Row>
        </div>
      );
    } else if (urlMatcher(routes.applicants, location)) {
      return (
        <div className={classNames(styles.horizantalTagDiv, styles.horizontalScroll)}>
          <Row className={classNames(styles.scrollableDiv, styles.scrollable)}>
            {ApplicantStatus.map((applicantOption) => (
              <Link
                to={`?option=${applicantOption.label}`}
                key={applicantOption.label}
                className={classNames(styles.tagDiv, {
                  [styles.active]: activeOptionApplicant === applicantOption.label,
                })}
              >
                <div className={styles.optionsDiv}>{applicantOption.name}</div>
              </Link>
            ))}
          </Row>
        </div>
      );
    }
  }, [activeOptionApplicant, activeOptionProperty, location]);
  const displayTotal = useCallback((): JSX.Element | undefined => {
    if (urlMatcher(routes.properties, location)) {
      return (
        <Col className={styles.navbarHeading}>
          <div className={styles.navbarHeading}>{t('agent.properties.property')}</div>
          <div className={styles.navbarSubHeading}>
            {pageInfo?.totalElements === undefined ? (
              <Skeleton height={15} width={45} />
            ) : (
              `${pageInfo.totalElements} ${t('agent.properties.total')}`
            )}
          </div>
        </Col>
      );
    } else if (urlMatcher(routes.applicants, location)) {
      return (
        <Col className={styles.navbarHeading}>
          <div className={styles.navbarHeading}>{t('agent.applicants.property')}</div>
        </Col>
      );
    }
  }, [location, pageInfo]);

  return (
    <BootstrapNavbar expand="md" className={classNames('navbar fixed-top pb-0', styles.navBarWrapper)}>
      <div className={styles.navBarContainer}>
        <div className={styles.navTogglerMobile}>
          <div className={styles.navbarAlignment}>
            {isNonProfilePage ? (
              <>
                <LabelIcon className={styles.alignIcon} customIcon={IntellirentIcon} url={routes.applicants} />
                {!location?.pathname?.includes('/properties/') && displayTotal()}
              </>
            ) : (
              <div className={styles.IntellirentLogo}>
                <span className={styles.navIntelli}>intelli</span>
                <span className={styles.navRent}>rent</span>
              </div>
            )}
          </div>
          <div className={classNames(styles.navToggler, styles.navbarMenu)}>
            {isNonProfilePage && (
              <Link to={IntellirentPublicUrls.KNOWLEDGE_BASE} target="_blank" rel="noreferrer">
                <HelpIcon />
              </Link>
            )}
            <ProfileDropdown
              isProfileInfoVisible
              organizationName={selectedOrg?.name}
              name={
                agentProfileInformation.firstName
                  ? `${agentProfileInformation.firstName} ${agentProfileInformation.lastName}`
                  : agentProfileInformation.email
              }
              isProfileSet={
                location.pathname === routes.organizations
                  ? false
                  : !!agentProfileInformation.firstName && location.pathname !== routes.agentSecurityQuestionnaire
              }
            >
              <MenuIcon />
            </ProfileDropdown>
          </div>
        </div>
        {!location?.pathname?.includes('/properties/') && displayOptions()}
        <hr className={classNames(styles.horizantalLineDiv, styles.bottomLine)} />
      </div>
    </BootstrapNavbar>
  );
};

export default AgentMobileNavBar;
