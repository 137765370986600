import { Navigate } from 'react-router-dom';
import { DocumentPage } from 'pages/Document/DocumentPage';
import Renter from 'pages/Renter/Renter';
import { TwoFactorSetup } from 'pages/TwoFactorSetup/TwoFactorSetup';

import RenterAddressInformation from 'components/Renter/components/AddressInformation/AddressInformation';
import { ApplicationCreditBackground } from 'components/Renter/components/ApplicationCreditBackground/ApplicationCreditBackground';
import ApplicationIndex from 'components/Renter/components/ApplicationIndex/ApplicationIndex';
import ConnectBankDetails from 'components/Renter/components/ConnectBankDetails/ConnectBankDetails';
import Guarantor from 'components/Renter/components/Guarantor/Guarantor';
import CheckoutCard from 'components/Renter/components/PaymentInformation/CheckoutCard';
import RelationAdultConfirmation from 'components/Renter/components/RenterHouseHold/RenterAdult/RelationAdultConfirmation';
import RelationshipIndex from 'components/Renter/components/RenterHouseHold/RenterAdult/RelationAdultIndex';
import RenterAdult from 'components/Renter/components/RenterHouseHold/RenterAdult/RenterAdult';
import RelationMinorConfirmation from 'components/Renter/components/RenterHouseHold/RenterMinor/RelationMinorConfirmation';
import RelationshipMinorIndex from 'components/Renter/components/RenterHouseHold/RenterMinor/RelationMinorIndex';
import RenterMinor from 'components/Renter/components/RenterHouseHold/RenterMinor/RenterMinor';
import RenterPersonalInfoSummary from 'components/Renter/components/RenterPersonalInfoSummary';
import { RenterReport } from 'components/Renter/components/RenterReport/RenterReport';
import StripeDocumentUpload from 'components/Renter/components/Stripe/StripeDocumentUpload/StripeDocumentUpload';
import { StripeManualVerification } from 'components/Renter/components/Stripe/StripeManualVerification/StripeManualVerification';
import ViewApplication from 'components/Renter/components/ViewApplication/ViewApplication';
import RenterConnectLinkedin from 'components/Renter/ConnectLinkedin/ConnectLinkedin';
import CreditHistory from 'components/Renter/CreditHistory/CreditHistory';
import IdentityVerification from 'components/Renter/IdentityVerification/IdentityVerification';
import NoCreditHistory from 'components/Renter/NoCreditHistory/NoCreditHistory';
import AnimalInformation from 'components/Renter/PetsInformation/AnimalInformation';
import RenterProfileInformation from 'components/Renter/ProfileInformation/ProfileInformation';
import RenterBackgroundInformation from 'components/Renter/RenterBackgroundInformation/RenterBackgroundInformation';
import RenterCriteria from 'components/Renter/RenterCriteria/RenterCriteria';
import RenterFurtherInformation from 'components/Renter/RenterFurtherInformation/RenterFurtherInformation';
import RenterIncomeInformation from 'components/Renter/RenterIncomeInformation/RenterIncomeInformation';
import RenterPhoneNumberCard from 'components/Renter/RenterPhoneNumber/RenterPhoneNumberCard';
import RenterPhotoIdInformation from 'components/Renter/RenterPhotoIdInformation/RenterPhotoIdInformation';
import RenterPresentSourceOfIncome from 'components/Renter/RenterPresentSourceOfIncome/AddPresentSourceOfIncome/RenterPresentSourceOfIncome';
import RenterPresentIncomeSummary from 'components/Renter/RenterPresentSourceOfIncome/RenterPresentIncomeSummary/RenterPresentIncomeSummary';
import RenterPriorSourceOfIncome from 'components/Renter/RenterPriorSourceOfIncome/AddPriorSourceOfIncome/RenterPriorSourceOfIncome';
import RenterPriorIncomeSummary from 'components/Renter/RenterPriorSourceOfIncome/RenterPriorIncomeSummary/RenterPriorIncomeSummary';
import { RenterPropertySelection } from 'components/Renter/RenterPropertySelection';
import { PropertiesList } from 'components/Renter/RenterPropertySelection/PropertiesList/PropertiesList';
import { CustomAddress } from 'components/Renter/RenterPropertySelection/RenterCustomAddress/CustomAddress';
import { RenterSecurityQuestonWrapper } from 'components/Renter/RenterSecurityQuestonWrapper/RenterSecurityQuestonWrapper';
import RenterVehiclesInformation from 'components/Renter/RenterVehiclesInformation/RenterVehiclesInformation';
import VehiclesConfirmation from 'components/Renter/RenterVehiclesInformation/VehiclesConfirmation/VehiclesConfirmation';
import { VisaUpload } from 'components/Renter/VisaUpload/VisaUpload';
import { renterRoutes as renter, routes } from 'shared/routes';
const renterRoutes = [
  { path: '*', element: <Navigate to={renter.renter} replace />, index: true },
  {
    path: renter.renter,
    element: <Renter />,
  },
  {
    path: renter.viewRenterCriteria,
    element: <RenterCriteria />,
  },
  {
    path: renter.renterCreditHistory,
    element: <CreditHistory />,
  },
  {
    path: renter.renterNoCreditHistory,
    element: <NoCreditHistory />,
  },
  {
    path: renter.creditHistoryOthers,
    element: <VisaUpload />,
  },
  {
    path: renter.renterAdults,
    element: <RenterAdult />,
  },
  {
    path: renter.renterMinors,
    element: <RenterMinor />,
  },
  {
    path: renter.renterRelation,
    element: <RelationshipIndex />,
  },

  {
    path: renter.renterRelationMinorIndex,
    element: <RelationshipMinorIndex />,
  },
  {
    path: renter.renterRelationMinorConfirmation,
    element: <RelationMinorConfirmation />,
  },
  {
    path: renter.renterRelationAdultConfirmation,
    element: <RelationAdultConfirmation />,
  },

  {
    path: renter.renterStripeIdentity,
    element: <StripeDocumentUpload />,
  },
  {
    path: renter.renterPhotoIdInformation,
    element: <RenterPhotoIdInformation />,
  },
  {
    path: renter.personalInformation,
    element: <RenterProfileInformation />,
  },
  {
    path: renter.renterBackgroundInformation,
    element: <RenterBackgroundInformation />,
  },
  {
    path: renter.renterFurtherInformation,
    element: <RenterFurtherInformation />,
  },
  {
    path: renter.renterPriorSourceOfIncome,
    element: <RenterPriorSourceOfIncome />,
  },
  {
    path: renter.renterPriorSourceOfIncomeSummary,
    element: <RenterPriorIncomeSummary />,
  },
  {
    path: renter.renterPresentSourceOfIncome,
    element: <RenterPresentSourceOfIncome />,
  },
  {
    path: renter.renterPresentSourceOfIncomeSummary,
    element: <RenterPresentIncomeSummary />,
  },
  {
    path: renter.renterConnectLinkedin,
    element: <RenterConnectLinkedin />,
  },
  {
    path: renter.addressHistoryInformation,
    element: <RenterAddressInformation />,
  },
  {
    path: renter.renterPhoneNumber,
    element: <RenterPhoneNumberCard />,
  },
  {
    path: renter.connectBankDetails,
    element: <ConnectBankDetails />,
  },
  {
    path: renter.incomeInformation,
    element: <RenterIncomeInformation />,
  },
  {
    path: renter.guarantorInformation,
    element: <Guarantor />,
  },
  {
    path: renter.startApplication,
    element: <RenterPropertySelection />,
  },
  {
    path: renter.customProperty,
    element: <CustomAddress />,
  },
  {
    path: renter.applicationProperties,
    element: <PropertiesList />,
  },
  {
    path: renter.vehiclesInformation,
    element: <RenterVehiclesInformation />,
  },
  {
    path: renter.vehiclesInformationSummary,
    element: <VehiclesConfirmation />,
  },
  {
    path: renter.renterPetsInformation,
    element: <AnimalInformation />,
  },
  {
    path: renter.renterPaymentMethod,
    element: <CheckoutCard />,
  },

  {
    path: renter.renterPaymentVerification,
    element: <IdentityVerification />,
  },
  {
    path: renter.applications,
    element: <ApplicationIndex />,
  },
  {
    path: renter.applicationToRent,
    element: <ViewApplication />,
  },
  {
    path: renter.renterReport,
    element: <RenterReport />,
  },
  {
    path: renter.renterSecurityQuestionnaire,
    element: <RenterSecurityQuestonWrapper />,
  },
  {
    path: renter.applicationCreditBackground,
    element: <ApplicationCreditBackground />,
  },

  {
    path: renter.stripeManualVerification,
    element: <StripeManualVerification />,
  },
  {
    path: renter.personalInformationSummary,
    element: <RenterPersonalInfoSummary />,
  },
  {
    path: routes.twoFactorSetup,
    element: <TwoFactorSetup />,
  },
  {
    path: routes.document,
    element: <DocumentPage />,
  },
];

export default renterRoutes;
