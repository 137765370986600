import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AbsoluteLegendsArray,
  InitialLegendsArray,
  tradePaymentIcons,
  VerticalLegendArray,
} from 'constants/reportConstants';
import { TradeLineReportItem } from 'shared/types/reportTypes';

import { TradeIconGenerator } from '../../TradeIconGenerator/TradeIconGenerator';

import styles from './TradeIconLegend.module.scss';
export const TradeIconLegend = ({
  paymentProfile,
  tradeLineReport,
}: {
  paymentProfile: string;
  tradeLineReport: TradeLineReportItem;
}): JSX.Element => {
  const { t } = useTranslation();
  const getCharCount = useMemo(
    () =>
      (char: string): number => {
        switch (char) {
          case '1':
            return tradeLineReport.DelinquenciesOver30Days ? parseInt(tradeLineReport.DelinquenciesOver30Days) : 0;
          case '2':
            return tradeLineReport.DelinquenciesOver60Days ? parseInt(tradeLineReport.DelinquenciesOver60Days) : 0;
          case '3':
            return tradeLineReport.DelinquenciesOver90Days ? parseInt(tradeLineReport.DelinquenciesOver90Days) : 0;
          default:
            return paymentProfile.split(char).length - 1;
        }
      },
    [
      paymentProfile,
      tradeLineReport.DelinquenciesOver30Days,
      tradeLineReport.DelinquenciesOver60Days,
      tradeLineReport.DelinquenciesOver90Days,
    ]
  );

  return (
    <div className={styles.Container}>
      <div className={styles.HorizontalLegend}>
        <div className={styles.LegendIconContainer}>
          <span className={styles.DashIcon}>—</span>
          <span className={styles.LegendText}>{t('agent.creditReport.tradelineReport.legend.unknown')}</span>
        </div>
        {InitialLegendsArray.map(
          (char, index) =>
            (getCharCount(char) > 0 || AbsoluteLegendsArray.includes(char)) && (
              <div key={`legend-icon-${char}-${index}`} className={styles.LegendIconContainer}>
                <TradeIconGenerator
                  label={tradePaymentIcons[char].label}
                  backgroundColor={tradePaymentIcons[char].color}
                  shouldShowCount
                  count={getCharCount(char)}
                />
                <span className={styles.LegendText}>{tradePaymentIcons[char].iconInformation}</span>
              </div>
            )
        )}
      </div>

      {VerticalLegendArray.map(
        (char, index) =>
          paymentProfile.includes(char) && (
            <div key={`vertical-legend-icon-${char}-${index}`} className={styles.VerticalLegendIconContainer}>
              <TradeIconGenerator
                label={tradePaymentIcons[char].label}
                backgroundColor={tradePaymentIcons[char].color}
              />
              <span className={styles.LegendText}>{tradePaymentIcons[char].iconInformation}</span>
            </div>
          )
      )}
    </div>
  );
};
