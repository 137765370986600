import { PillTypes } from 'components/shared/AgentPills/AgentPills';
import {
  AddressInformationReportItem,
  CandidateCriminalRecordItem,
  ConsumerReportNameItem,
  EmploymentReportItem,
  EnhancedPaymentItem,
  EvictionCandidate,
  EvictionCandidateActivity,
  EvictionReportResponse,
  InformationalMessageReportItem,
  OffenseSubjectInfo,
  OrderResponse,
  ProfileSummaryResponse,
  PublicRecordBankruptcy,
  PublicRecordItem,
  ReportItemObject,
  TradeLineAmountItem,
  TradeLineReportItem,
  Transaction,
  VantageScoreReportItem,
  YearData,
} from 'shared/types/reportTypes';

export const convertResponseToObject = (
  response?:
    | PublicRecordItem
    | TradeLineReportItem
    | ProfileSummaryResponse
    | TradeLineAmountItem
    | EnhancedPaymentItem
    | PublicRecordBankruptcy
    | ConsumerReportNameItem
    | AddressInformationReportItem
    | EmploymentReportItem
    | InformationalMessageReportItem
    | VantageScoreReportItem
    | CandidateCriminalRecordItem
    | OrderResponse
    | OffenseSubjectInfo
    | EvictionReportResponse
    | EvictionCandidate
    | EvictionCandidateActivity
    | TradeLineAmountItem[]
): Record<string, string | number | boolean | null | ReportItemObject> => {
  const result: Record<string, string | number | boolean | null | ReportItemObject> = {};

  if (!!response) {
    Object.entries(response).forEach(([key, value]) => {
      result[key] = value;
    });
  }

  return result;
};

export const getValueFormated = (value: string | number | boolean | null | ReportItemObject): string => {
  if (typeof value === 'boolean' || typeof value === 'string' || typeof value === 'number') {
    return String(value);
  } else if (value === null) {
    return '—';
  }

  return String(value?.desc ?? '—');
};
export const getCodeFormated = (value: string | number | boolean | null | ReportItemObject): string => {
  if (value && typeof value !== 'boolean' && typeof value !== 'string' && typeof value !== 'number') {
    return String(value.code ?? '—');
  }

  return '';
};

export const isEven = (value: number): boolean => value % 2 === 0;
//
export const getRemainingDaysPillType = (value: number | string): PillTypes => {
  if (value === '–') {
    return PillTypes.SECONDARY;
  }

  if (+value > 5) {
    return PillTypes.SECONDARY;
  } else if (+value > 0) {
    return PillTypes.WARNING_DARK;
  }

  return PillTypes.DANGER;
};
//Converting Transaction Array into 2-D array of transactions by month
export const sortTransactionsByMonth = (transactions: Transaction[]): Transaction[][] => {
  const result: Transaction[][] = [];
  const months: { [key: string]: Transaction[] } = {};

  transactions.forEach((transaction) => {
    const month = transaction.transactionDate.slice(0, 7);

    if (!months[month]) {
      months[month] = [];
    }

    months[month].push(transaction);
  });

  Object.values(months).forEach((monthTransactions) => {
    result.push(monthTransactions);
  });

  return result;
};

export const getCharacterCount = (paymentProfile: string): { [key: string]: number } => {
  const characterCount: { [key: string]: number } = {};

  for (let i = 0; i < paymentProfile.length; i++) {
    const character = paymentProfile[i];

    if (characterCount[character]) {
      characterCount[character]++;
    } else {
      characterCount[character] = 1;
    }
  }

  return characterCount;
};

export const getYearsInBetweenArray = (toYear: number, fromYear: number): string[] => {
  const years = [];

  for (let year = toYear; year >= fromYear; year--) {
    years.push(String(year));
  }

  return years;
};

export const getTradeCalendar = (yearArray: string[], stringArray: string, startMonth: number): YearData => {
  const result: YearData = {};
  let stringIndex = 0;

  for (const year of yearArray) {
    result[year] = [];
    for (let month = 0; month < 12; month++) {
      if ((year === yearArray[0] && month < 12 - startMonth) || stringIndex >= stringArray.length) {
        result[year].push('-');
      } else {
        result[year].push(stringArray[stringIndex]);
        stringIndex++;
      }
    }
  }

  return result;
};
