//TODO: Temporarily disabled some non-interactive features and will re-enable later.

import { memo, useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import Row from 'react-bootstrap/esm/Row';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { amenitiesList, selectProperty } from 'redux/selectors/propertySelector';
import {
  addProperty,
  getProperty,
  removeSelectedPropertyDetails,
  updateAmenitiesFeature,
  updatePropertyDetails,
} from 'redux/slices/propertySlice';
import { AppThunkDispatch } from 'redux/store';

import defaultAgentProfile from 'assets/images/defaultProfilePicture.png';
// import { ReactComponent as DropdownArrow } from 'assets/svgs/ArrowDownWithBackgroundColor.svg';
import { ReactComponent as BackArrow } from 'assets/svgs/ArrowLeftDirected.svg';
// import { ReactComponent as DownArrow } from 'assets/svgs/BlackDropdownArrow.svg';
import ActionDropDown from 'components/Agent/components/shared/ActionDropDown/ActionDropDown';
import InviteApplicantModal from 'components/Agent/components/shared/InviteApplicantModal/InviteApplicantModalHandler';
import Avatar from 'components/shared/Avatar/Avatar';
import Button from 'components/shared/Button/Button';
import { ApplicationRequestValues } from 'constants/applicationConstants';
import { PropertyActionStatus } from 'constants/dropdownOptions';
import { PropertyStatuses } from 'constants/propertyConstants';
import { formatDateTime } from 'helpers/agentHelper';
import { propertyGeneralInviteInitialValues } from 'shared/types/agentTypes';
import { RentalRequest } from 'shared/types/authType';
import { PropertyType as PropertyStatusType } from 'shared/types/propertType';
import { AddressType } from 'shared/types/propertyType';

import { parseApplicantsInitials } from '../../../../helpers/propertyHelper';

import { ImageGallery } from './components/ImageGallery';
import PropertyAddress from './components/PropertyAddress';
import { PropertyAmenetiesForm } from './components/PropertyAmenetiesForm';
import { PropertyDescriptionForm } from './components/PropertyDescriptionForm';
import { PropertySpecsForm } from './components/PropertySpecsForm';

import styles from './PropertyDetails.module.scss';

export const PropertyDetails = memo((): JSX.Element => {
  const { t } = useTranslation();
  const amenities = useSelector(amenitiesList);
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch<AppThunkDispatch>();
  const [dropDownOptions, setDropdownOptions] = useState(PropertyActionStatus);
  const propertyDetails = useSelector(selectProperty);
  const currentOrganization = useSelector(selectedOrganization);
  const { applicationId, propertyId } = useParams();
  const { propertyStatus } = propertyDetails;
  const isEditable = Boolean(location.state && location.state?.editable);
  const getAddress = (): AddressType | null => {
    if (!propertyDetails) {
      return null;
    }

    const { city, state, zipCode, streetAddress1, streetAddress2 } = propertyDetails;

    return { city, state, zipCode, streetAddress1, streetAddress2 };
  };

  useEffect(() => {
    if (propertyStatus === PropertyStatuses.DRAFT) {
      setDropdownOptions(PropertyActionStatus.filter((item) => item.value !== PropertyStatuses.ARCHIVED));
    } else {
      setDropdownOptions(PropertyActionStatus);
    }
  }, [propertyStatus]);

  const removeAmenities = async (): Promise<void> => {
    await dispatch(
      updateAmenitiesFeature(
        amenities.map((el) => ({
          ...el,
          isSelected: false,
          isAnimation: false,
          tempSelected: false,
        }))
      )
    );
  };

  useEffect(() => {
    if (propertyId && currentOrganization.id) {
      dispatch(getProperty({ propertyId: Number(propertyId), organizationId: currentOrganization.id }));
    }

    return () => {
      dispatch(removeSelectedPropertyDetails());
      removeAmenities();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization.id, dispatch, propertyId]);

  if (isEmpty(propertyDetails)) {
    return <div></div>;
  }

  const handleOptionChange = (option: any): void => {
    const { value } = option;
    const body = {
      ...propertyDetails,
      applicationId: Number(applicationId),
      propertyId: Number(propertyId),
      propertyStatus: value,
    };

    dispatch(addProperty({ values: body, organizationId: currentOrganization.id }))
      .unwrap()
      .then((updatedProperty) => {
        dispatch(updatePropertyDetails(updatedProperty));
      });
  };

  return propertyDetails ? (
    <>
      <div className={styles.Divider}></div>
      <div className={styles.Container}>
        <div className={styles.PropertyRow}>
          <div className={styles.LeftItem}>
            <BackArrow className={styles.BackIcon} onClick={() => navigate(-1)} />
            <h1>{propertyDetails?.propertyStatus}</h1>

            {/* <DropdownArrow className={styles.DropdownIcon} /> */}
            <span className={styles.CreatedAt}>{formatDateTime(propertyDetails?.createdAt)}</span>
          </div>
          <div className={styles.RightItem}>
            <ActionDropDown
              status={propertyStatus}
              dropDownOptions={dropDownOptions}
              handleOptionChange={handleOptionChange}
            />
            <div className={styles.AgentInfoContainer}>
              <img
                src={defaultAgentProfile}
                width={24}
                height={24}
                alt={t('renter.applicationInfoBox.agentProfile')}
                className={styles.ProfileImage}
              />
              <div className={styles.NameContainer}>
                <span
                  className={styles.Name}
                >{`${propertyDetails?.agentFirstName} ${propertyDetails?.agentLastName}`}</span>
                <span className={styles.Type}>Listing Agent</span>
              </div>
              {/* <DownArrow /> */}
            </div>
          </div>
        </div>
        <div>
          {propertyDetails?.propertyImages && (
            <ImageGallery
              propertyId={propertyDetails.id ?? 0}
              marketingEnabled={propertyDetails?.marketingEnabled ?? false}
              propertyImages={propertyDetails?.propertyImages}
              propertyStatus={propertyDetails?.propertyStatus || ''}
            />
          )}
          <Row className="mx-1 mt-3">
            <PropertyAddress
              addressObject={getAddress()}
              propertyType={propertyDetails?.propertyType}
              dateAvailable={propertyDetails?.dateAvailable?.toString()}
              totalUnits={propertyDetails?.totalUnits}
              name={propertyDetails?.name}
            />
            <Col className={`col-lg-6 col-md-12 ${styles.ApplicantContainer}`}>
              <div className={styles.ApplicantContainerInner}>
                <div className={styles.Col1}></div>
                <div className={styles.Col2}>
                  <span className={styles.ApplicantHeading}>{t('agent.applicants.property')}</span>
                  {!isEmpty(propertyDetails?.applicants?.listOfApplicants) &&
                    !!propertyDetails?.applicants?.active &&
                    !!propertyDetails?.applicants?.sumOfHouseholds && (
                      <Row className={styles.AvatarRow}>
                        {parseApplicantsInitials(propertyDetails?.applicants?.listOfApplicants as [])?.map(
                          (el, index) => (
                            <Avatar firstName={el} className={styles.OverLapIcons} key={`avatar-${index}`} />
                          )
                        )}
                      </Row>
                    )}
                  <div className={styles.ApplicantStatusContainer}>
                    <span className={styles.ApplicantStatusActive}>
                      <span className={styles.ApplicantStatusCount}>{propertyDetails?.applicants?.active ?? 0}</span>
                      {t('agent.properties.property.applicants.active')}
                    </span>
                    <span>
                      <span className={styles.ApplicantStatusCount}>
                        {propertyDetails?.applicants?.sumOfHouseholds ?? 0}
                      </span>
                      <span className={styles.ApplicantStatusHousehold}>
                        {' '}
                        {t('agent.properties.property.applicants.household')}
                      </span>
                    </span>
                  </div>
                </div>
                <div className={styles.Col3}>
                  {propertyDetails?.propertyStatus === PropertyStatusType.PUBLISHED && (
                    <Button
                      className={styles.SendRequestButton}
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                    >
                      {t('agent.properties.property.applicants.newInvite')}
                    </Button>
                  )}
                  {/* <div className={styles.ApplicantSelect}>
                    <ReactSelect
                      className={styles.ReactSelect}
                      isSearchable={false}
                      placeholder={t('agent.properties.property.applicant.active')}
                      options={ApplicantViewOptions}
                    />
                  </div> */}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <div className={styles.PropertyAdditionalInfoContainer}>
            <PropertySpecsForm propertyDetails={propertyDetails} isEditableSpecs={isEditable} />
          </div>
          <div className={styles.PropertyAdditionalInfoContainer}>
            <PropertyDescriptionForm description={propertyDetails?.description ?? ''} isEditable={isEditable} />
          </div>
          <div className={styles.PropertyAdditionalInfoContainer}>
            <PropertyAmenetiesForm propertyDetails={propertyDetails} isEditableAmeneties={isEditable} />
          </div>
        </div>
      </div>
      {isModalOpen && (
        <InviteApplicantModal
          inviteType={ApplicationRequestValues.APPLICATION_AND_REPORTS}
          isModalOpen={isModalOpen}
          onHide={() => setIsModalOpen(false)}
          invitationInitialValues={{ ...propertyGeneralInviteInitialValues, propertyId: propertyId } as RentalRequest}
          propertyAddress={propertyDetails.streetAddress1 ?? 'Street Address'}
        />
      )}
    </>
  ) : (
    <div></div>
  );
});
