import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { ReactComponent as InfoFilled } from 'assets/svgs/InfoFilled.svg';
import { ReactComponent as YellowWarningFilled } from 'assets/svgs/YellowWarningFilled.svg';
import { creditReportCriteria } from 'constants/agentConstants';
import { vantageScoreReportLabelsFirst, vantageScoreReportLabelsSecond } from 'constants/reportConstants';
import { convertResponseToObject, getValueFormated, isEven } from 'helpers/reportsHelper';
import { IntellirentPublicUrls } from 'shared/constants';
import { VantageScoreReportItem } from 'shared/types/reportTypes';

import styles from './VantageScoreReport.module.scss';
export const VantageScoreReport = ({
  vantageScoreReport,
}: {
  vantageScoreReport: VantageScoreReportItem;
}): JSX.Element => {
  const convertedVantageScore = convertResponseToObject(vantageScoreReport);
  const { t } = useTranslation();
  const vantageScore = getValueFormated(convertedVantageScore['Score']);
  const scoreData = useMemo(() => {
    if (vantageScore === '1') {
      return {
        message: t('agent.creditReport.deceasedMessage.score.one'),
        vantageScore: t('agent.creditReport.vantageScore.heading.one'),
        icon: <YellowWarningFilled />,
        cssClass: styles.ErrorHeading,
      };
    } else if (vantageScore === '4') {
      return {
        message: t('agent.creditReport.deceasedMessage.score.four'),
        vantageScore: t('agent.creditReport.vantageScore.heading.four'),
        icon: <InfoFilled />,
        cssClass: styles.ErrorHeading,
      };
    } else if (vantageScore === '9000') {
      return {
        message: t('agent.creditReport.deceasedMessage.score.nine'),
        vantageScore: <YellowWarningFilled className={styles.WarningIcon} />,
      };
    } else {
      return { vantageScore, cssClass: styles.VantageScore };
    }
  }, [t, vantageScore]);

  return (
    <div className={styles.Container}>
      <div className={styles.InformationContainer}>
        <div className={styles.VantageScoreContainer}>
          <div className={styles.VantageHeading}>{t('agent.creditReport.vantageScore')}</div>
          <div className={scoreData.cssClass}>{scoreData.vantageScore}</div>
        </div>
        {scoreData.message ? (
          <div className={styles.DeceasedMessage}>
            <span>{scoreData.icon}</span>
            {scoreData.message}
            <Link
              to={IntellirentPublicUrls.CREDIT_SCORE_ERROR}
              target="_blank"
              rel="noreferrer"
              className={styles.LearnMore}
            >
              {t('agent.properties.learnMore')}
            </Link>
          </div>
        ) : (
          <div className={styles.ScoreFactorsContainer}>
            <div className={styles.FactorHeading}>{t('agent.creditReport.scoreFactors')}</div>
            <div className={styles.ScoreMainContainer}>
              <div className={styles.ScoresContainer}>
                {vantageScoreReportLabelsFirst.map(
                  (label, index) =>
                    getValueFormated(convertedVantageScore[label]) !== '—' && (
                      <div className={styles.ScoresValueContainer} key={`vintage-score-item-${index}`}>
                        <div
                          key={`vantage-report-score-${label}-${convertedVantageScore[label]}-${index}`}
                          className={styles.Score}
                        >
                          {getValueFormated(convertedVantageScore[label])}
                        </div>
                        <div
                          className={classNames(styles.ScoreValue, { [styles.ScoreValueHighlighted]: isEven(index) })}
                        >
                          {t(creditReportCriteria[getValueFormated(convertedVantageScore[label])])}
                        </div>
                      </div>
                    )
                )}
              </div>

              <div className={styles.ScoresContainer}>
                {vantageScoreReportLabelsSecond.map(
                  (label, index) =>
                    getValueFormated(convertedVantageScore[label]) !== '—' && (
                      <div className={styles.ScoresValueContainer} key={`vintage-score-item-${index}`}>
                        <div
                          key={`vantage-report-score-${label}-${convertedVantageScore[label]}-${index}`}
                          className={styles.Score}
                        >
                          {getValueFormated(convertedVantageScore[label])}
                        </div>
                        <div
                          className={classNames(styles.ScoreValue, { [styles.ScoreValueHighlighted]: isEven(index) })}
                        >
                          {t(creditReportCriteria[getValueFormated(convertedVantageScore[label])])}
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
