import { useSelector } from 'react-redux';
import { selectedRenterDetails } from 'redux/selectors/agentSelector';

import AgentModal from 'components/Agent/components/AgentModal/AgentModal';
import { VerifierDetailsModalProps } from 'shared/types/agentTypes';

import { VerifierDetailsData } from './VerifierDetailsData';

import styles from './VerifierDetailsModal.module.scss';

export const VerifierDetailsModal = ({
  isModalOpen,
  onHideHandler,
  address,
  employment,
  title,
  addressVerificationInformation,
  employmentVerificationInformation,
  addressApplicationId,
}: VerifierDetailsModalProps): JSX.Element | null => {
  const renterDetails = useSelector(selectedRenterDetails);

  return (
    <AgentModal
      customModalClassName={styles.CustomCLass}
      show={isModalOpen}
      onHide={onHideHandler}
      customHeaderClassName={styles.CustomHeader}
    >
      <VerifierDetailsData
        title={title}
        renterDetails={renterDetails}
        addressVerificationInformation={addressVerificationInformation}
        employmentVerificationInformation={employmentVerificationInformation}
        address={address}
        employment={employment}
        isModalOpen={isModalOpen}
        addressApplicationId={addressApplicationId}
      />
    </AgentModal>
  );
};
