import { useSearchParams } from 'react-router-dom';

import { DocumentPreview } from 'components/shared/DocumentPreview/DocumentPreview';
import Spinner from 'components/shared/Spinner/Spinner';

export const DocumentPage = (): JSX.Element => {
  const [params] = useSearchParams();
  const fileLink = params.get('fileLink');

  if (!fileLink) {
    return <Spinner />;
  }

  return (
    <div>
      <h1>Denial/Adverse Action Letter</h1>
      <DocumentPreview link={fileLink} />
      <iframe src={fileLink} title="Document" width="80%" />
    </div>
  );
};
