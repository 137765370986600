//TODO: Temporarily disabled some report components feature and will re-enable later.

import { Fragment, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { selectRenterCreditProfile } from 'redux/selectors/agentSelector';
import { selectRenterCreditProfile as selectRenterCreditProfileInformation } from 'redux/selectors/renterSelector';

import { ReactComponent as ArrowIcon } from 'assets/svgs/BlackDownArrow.svg';
import { allNegativeTradePaymentCodes, ReportErrorTypes } from 'constants/reportConstants';

import { AddressReportInformation } from '../AddressReportInformation/AddressReportInformation';
import { ConsumerIdentity } from '../ConsumerIdentity/ConsumerIdentity';
import { EmploymentInformation } from '../EmploymentInformation/EmploymentInformation';
import { InquiryInformation } from '../InquiryInformation/InquiryInformation';
// import { ProfileSummary } from '../ProfileSummary/ProfileSummary';
import { PublicRecordInformation } from '../PublicRecordInformation/PublicRecordInformation';
import { ReportErrorMessage } from '../ReportErrorMessage/ReportErrorMessage';
import { RecordSummaryReport } from '../SharedReports/RecordSummaryReport/RecordSummaryReport';
// import { StatementReportSection } from '../StatementReportSection/StatementReportSection';
import { TradeLineInformation } from '../TradeLineInformation/TradeLineInformation';

import styles from './CreditReport.module.scss';
export const CreditReport = ({ isRenterFlow = false }: { isRenterFlow?: boolean }): JSX.Element => {
  const [isReportExpanded, setIsReportExpanded] = useState(true);
  const creditProfile = useSelector(!isRenterFlow ? selectRenterCreditProfile : selectRenterCreditProfileInformation);
  const { t } = useTranslation();
  const creditReport = creditProfile.report;
  const isReportComplete = useMemo(
    (): boolean => isReportExpanded && !!creditProfile.success,
    [creditProfile.success, isReportExpanded]
  );
  const isReportNotFound = useMemo(
    (): boolean => isReportExpanded && !creditProfile.success && !creditProfile.report,
    [creditProfile.report, creditProfile.success, isReportExpanded]
  );
  const getNegativeTradeCount = useMemo((): number => {
    let count = 0;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    creditReport?.TradeLine?.map(({ PaymentProfile }) => {
      if (allNegativeTradePaymentCodes.some((char) => PaymentProfile?.includes(char))) {
        count = count + 1;

        return;
      }
    });

    return count;
  }, [creditReport?.TradeLine]);

  return (
    <div className={styles.Container}>
      {!isRenterFlow && (
        <div className={styles.CreditIconContainer}>
          <ArrowIcon
            onClick={() => setIsReportExpanded(!isReportExpanded)}
            className={classNames(styles.ArrowIcon, { [styles.ArrowIconExpanded]: isReportExpanded })}
          />
          <div className={styles.Heading}>{t('agent.creditReport.creditReport')}</div>
        </div>
      )}
      {creditProfile.success === null && (
        <div className={styles.SpinnerClass}>
          <Spinner />
        </div>
      )}
      {isReportNotFound && (
        <div className={styles.ErrorReport}>
          <ReportErrorMessage
            errorType={ReportErrorTypes.ERROR}
            errorCode={creditProfile.error.Code}
            errorMessage={creditProfile.error.Message ?? ''}
          />
        </div>
      )}

      {isReportComplete && !!creditReport && (
        <div className={styles.ExpandedInformation}>
          <div className={classNames(styles.CreditReportHeader, { [styles.ReportComponentsRenter]: isRenterFlow })}>
            <div className={styles.InfoMessageContainer}>
              <div className={styles.InfoMsgLabel}>{t('creditReport.infoMessage')}</div>
              {creditReport?.InformationalMessage?.map((info, index) => (
                <Fragment key={`info-msg-${info}-${index}`}>
                  <div className={styles.InfoMsgValue}>{info.MessageText?.split(info.MessageNumber ?? '')[1]}</div>
                  {index + 1 < (creditReport?.InformationalMessage?.length ?? 0) && (
                    <div className={styles.InfoDivider}>|</div>
                  )}
                </Fragment>
              ))}
            </div>
            <div className={styles.NameContainer}>
              {`${creditReport?.ConsumerIdentity?.[0]?.Name?.First} ${creditReport?.ConsumerIdentity?.[0]?.Name?.Surname}`}
            </div>
          </div>
          <div className={classNames(styles.ReportComponents, { [styles.ReportComponentsRenter]: isRenterFlow })}>
            <AddressReportInformation
              addressInformation={creditReport?.AddressInformation ?? []}
              isRenterFlow={isRenterFlow}
            />
            <EmploymentInformation
              employmentInformation={creditReport?.EmploymentInformation ?? []}
              isRenterFlow={isRenterFlow}
            />
            <ConsumerIdentity
              consumerIdentity={creditReport?.ConsumerIdentity ?? []}
              vantageScore={creditReport?.RiskModel ?? []}
            />
            <RecordSummaryReport
              tradelines={creditReport?.TradeLine?.length ?? 0}
              publicRecords={creditReport?.PublicRecord?.length ?? 0}
              inquiries={creditReport.Inquiry?.length ?? 0}
              negativeTradelines={getNegativeTradeCount}
              paidAccounts={creditReport?.ProfileSummary?.PaidAccounts ?? 0}
              satisfactory={creditReport?.ProfileSummary?.SatisfactoryAccount ?? 0}
              daysDelinquent30={creditReport?.ProfileSummary?.DelinquenciesOver30Days ?? 0}
              daysDelinquent60={creditReport?.ProfileSummary?.DelinquenciesOver60Days ?? 0}
              daysDelinquent90={creditReport?.ProfileSummary?.DelinquenciesOver90Days ?? 0}
              overDelinquent180={creditReport?.ProfileSummary?.DerogCounter ?? 0}
            />
            <InquiryInformation inquiryInformation={creditReport.Inquiry ?? []} />

            {/* <StatementReportSection /> */}

            {/* <ProfileSummary profileSummary={creditReport?.ProfileSummary ?? null} isRenterFlow={isRenterFlow} /> */}
            <PublicRecordInformation
              isRenterFlow={isRenterFlow}
              publicRecordInformation={creditReport?.PublicRecord ?? []}
            />
            <TradeLineInformation isRenterFlow={isRenterFlow} tradeLineInformation={creditReport?.TradeLine ?? []} />
          </div>
        </div>
      )}
    </div>
  );
};
