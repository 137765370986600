import { encodedRenterRole } from 'constants/renterConstants';

/* eslint-disable max-params */
export const routes = {
  home: '/',
  pdf: '/pdf',
  signup: '/Create_Account',
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  dashboard: '/dashboard',
  applicants: '/applicants',
  properties: '/properties',
  googleAuthCallback: '/google/callback',
  agentProfileForm: '/agent/profile',
  generateAgentProfileForm: (step: string) => `/agent/profile?step=${step}`,
  organizations: '/organizations',
  settings: '/settings',
  twoFactorSetup: '/2FA/setup',
  collaborators: '/collaborators',
  reports: '/reports',
  inbox: '/inbox',
  renterSignup: `/Create_Account?r=${encodedRenterRole}`,
  renterLogin: '/renter/login',
  organizationDashboard: '/organizations/dashboard',
  renterResume: 'properties/:propertyId/applications/:applicationId/renter-resume',
  generateRenterResume: (applicationId: number, propertyId: number) =>
    `/properties/${propertyId}/applications/${applicationId}/renter-resume`,
  generateRenterResumeNav: (applicationId: number, propertyId: number, activeTab: string) =>
    `/properties/${propertyId}/applications/${applicationId}/renter-resume?activeTab=${activeTab}`,
  renterDummyResume: 'invite/:inviteCode/renter-resume',
  generateRenterDummyResume: (inviteCode: string) => `/invite/${inviteCode}/renter-resume`,
  internalServerError: '/internal-server-error',
  pageNotFound: '/page-not-found',
  agentSecurityQuestionnaire: '/agent/profile/security-questionnaire',
  propertyDetails: 'properties/:propertyId',
  generatePropertyDetails: (propertyId: number) => `/properties/${propertyId}`,
  document: '/document',
};

export const renterRoutes = {
  renter: '/renter',
  // Application
  applications: '/renter/applications',
  applicationToRent: '/renter/applications/:appId',
  generateApplicationToRent: (id?: number | string) => `/renter/applications/${id}`,
  applicationCreditBackground: '/renter/applications/:appId/credit-background',
  generateApplicationCreditBackground: (id?: number | string) => `/renter/applications/${id}/credit-background`,

  // Property Selection
  startApplication: '/renter/start-application',
  applicationProperties: '/renter/application/properties',

  //Custom Property Selection
  customProperty: '/renter/application/custom-Property',

  // Rental Criteria
  viewRenterCriteria: '/renter/application/:applicationId/rental-criteria',
  generateViewRenterCriteriaUrl: (id?: string) => `/renter/application/${id}/rental-criteria`,
  // Personal Profile
  stripeManualVerification: '/renter/application/:applicationId/personal-profile/stripe-identity/manual-verification',
  generateStripeManualVerification: (id: number | string, step: string) =>
    `/renter/application/${id}/personal-profile/stripe-identity/manual-verification?step=${step}`,
  // stripeManualVerificationForm:
  //   '/renter/application/:applicationId/personal-profile/stripe-identity/manual-verification',
  // generateStripeManualVerificationForm: (id: number | string) =>
  //   `/renter/application/${id}/personal-profile/stripe-identity/manual-verification`,
  // stripeRenterInfo: '/renter/application/:applicationId/personal-profile/stripe-identity/manual-verification/input',
  // generateStripeRenterInfo: (id: number | string) =>
  //   `/renter/application/${id}/personal-profile/stripe-identity/manual-verification/input`,
  renterStripeIdentity: '/renter/application/:applicationId/personal-profile/stripe-identity',
  generateRenterStripeIdentityUrl: (id: number | string) =>
    `/renter/application/${id}/personal-profile/stripe-identity`,
  renterPhotoIdInformation: '/renter/properties/:propertyId/personal-profile/information',
  generateViewRenterPhotoIdInformationUrl: (id: number | string) =>
    `/renter/properties/${id}/personal-profile/information`,
  renterInformationEdit: '/renter/properties/:propertyId/personal-profile/information/edit',
  generateViewRenterInformationEditnUrl: (id: number | string) =>
    `/renter/properties/${id}/personal-profile/information/edit`,
  personalInformation: '/renter/application/:applicationId/personal-profile',
  generatePersonalInformationUrl: (step: string, id: number | string) =>
    `/renter/application/${id}/personal-profile?step=${step}`,
  personalInformationSummary: '/renter/application/:applicationId/personal-profile/summary',
  generatePersonalInformationSummary: (id?: string | number) => `/renter/application/${id}/personal-profile/summary`,
  renterPhoneNumber: '/renter/application/:applicationId/personal-profile/phone-number',
  generateRenterPhoneNumber: (id?: number | string, next = '') =>
    `/renter/application/${id}/personal-profile/phone-number?next=${next}`,
  renterCreditHistory: '/renter/application/:applicationId/personal-profile/credit-history',
  generateRenterCreditHistoryUrl: (id: number | string) => `/renter/application/${id}/personal-profile/credit-history`,
  renterNoCreditHistory: '/renter/application/:applicationId/personal-profile/no-credit-history',
  generateRenterNoCreditHistoryUrl: (id: number | string) =>
    `/renter/application/${id}/personal-profile/no-credit-history`,
  // Household Details
  renterMinors: '/renter/application/:applicationId/household-details/minors',
  generateRenterMinors: (id?: string, next = '', section = '') =>
    `/renter/application/${id}/household-details/minors?next=${next}&section=${section}`,
  renterAdults: '/renter/application/:applicationId/household-details/adults',
  creditHistoryOthers: '/renter/application/:applicationId/personal-profile/no-credit-history/:path',
  generateCreditHistoryOthers: (id: number | string, path: string) =>
    `/renter/application/${id}/personal-profile/no-credit-history/${path}`,
  generateRenterAdults: (id?: string | number, next = '', section = '') =>
    `/renter/application/${id}/household-details/adults?next=${next}&section=${section}`,
  renterRelation: '/renter/application/:applicationId/household-details/relation',
  renterRelationMinorIndex: '/renter/application/:applicationId/household-details/relation-minor-index',
  renterRelationMinorConfirmation: '/renter/application/:applicationId/household-details/minors/summary',
  generateRenterRelationMinorConfirmationUrl: (id?: string | number) =>
    `/renter/application/${id}/household-details/minors/summary`,
  renterRelationAdultConfirmation: '/renter/application/:applicationId/household-details/relation-adult-confirmation',
  guarantorInformation: '/renter/application/:applicationId/household-details/guarantor-information',
  generateGuarantorInformation: (id?: string | number) =>
    `/renter/application/${id}/household-details/guarantor-information`,

  // renterAddressConfirmation: '/renter/:propertyId/address-history/renter-address-confirmation',
  // generateRenterAddressConfirmationUrl: (id: number | string) =>
  //   `/renter/${id}/address-history/renter-address-confirmation`,
  // renterPropertyDetails: '/renter/:propertyId/address-history/renter-property-details',
  // renterPresentAddressConfirmation: '/renter/properties/:propertyId/address-history/present-address-confirmation',
  // generateRenterPresentAddressConfirmationUrl: (id: number | string) =>
  //   `/renter/properties/${id}/address-history/present-address-confirmation`,

  // Address History
  addressHistoryInformation: '/renter/application/:applicationId/address-history',
  generateAddressHistoryInformation: (id: string | number, step: string, next = '', section = '') =>
    `/renter/application/${id}/address-history?step=${step}&next=${next}&section=${section}`,

  // generateRenterPropertyRentedUrl: (id: number | string) => `/renter/${id}/address-history/renter-property-rented`,
  // renterPriorAddress: '/renter/:propertyId/address-history/renter-prior-address',
  // generateRenterPriorAddressUrl: (id: number | string) => `/renter/${id}/address-history/renter-prior-address`,
  // renterAddressForm: '/renter/:propertyId/address-history/renter-address-form',
  // generateRenterAddressFormUrl: (id: number | string) => `/renter/${id}/address-history/renter-address-form`,

  // Source of Income

  // Prior Source of Income
  renterPriorSourceOfIncome: '/renter/application/:applicationId/source-of-income/prior',
  generateRenterPriorSourceOfIncome: (id?: number | string, step = 'havePrior', next = '', section = '') =>
    `/renter/application/${id}/source-of-income/prior?step=${step}&next=${next}&section=${section}`,
  renterPriorSourceOfIncomeSummary: '/renter/application/:applicationId/source-of-income/prior/summary',
  generateRenterPriorSourceOfIncomeSummary: (id?: number | string, next = '', section = '') =>
    `/renter/application/${id}/source-of-income/prior/summary?next=${next}&section=${section}`,

  // Present Source of Income
  renterPresentSourceOfIncome: '/renter/application/:applicationId/source-of-income/present',
  generateRenterPresentSourceOfIncome: (id?: number | string, next = '', section = '') =>
    `/renter/application/${id}/source-of-income/present?next=${next}&section=${section}`,
  renterPresentSourceOfIncomeSummary: '/renter/application/:applicationId/source-of-income/present/summary',
  generateRenterPresentSourceOfIncomeSummary: (id?: number | string) =>
    `/renter/application/${id}/source-of-income/present/summary`,

  incomeInformation: '/renter/application/:applicationId/source-of-income/:employmentId/information',
  generateRenterIncomeInformation: (id: number | string, employmentId: string | undefined, next = '') =>
    `/renter/application/${id}/source-of-income/${employmentId}/information?next=${next}`,

  connectBankDetails: '/renter/application/:applicationId/source-of-income/:employmentId/connect-bank-details',
  generateConnectBankDetails: (id?: number | string, employmentType?: string, employmentId?: number, next = '') =>
    `/renter/application/${id}/source-of-income/${employmentId}/connect-bank-details?employmentType=${employmentType}&next=${next}`,

  // Vehicle Information
  vehiclesInformation: '/renter/application/:applicationId/vehicles-information',
  generateVehiclesInformation: (id?: string | number, section?: string, step?: string, next = '') =>
    `/renter/application/${id}/vehicles-information?section=${section || ''}&step=${step || ''}${
      next ? `&next=${next}` : ''
    }`,
  vehiclesInformationSummary: '/renter/application/:applicationId/vehicles-information/summary',
  generateVehiclesInformationSummary: (id?: string | number) =>
    `/renter/application/${id}/vehicles-information/summary`,

  // Animal Information
  renterPetsInformation: '/renter/application/:applicationId/animal-information',
  generateRenterPetsInformation: (id?: number | string, next = '', section = '') =>
    `/renter/application/${id}/animal-information?next=${next}&section=${section}`,

  // Background Questions
  renterBackgroundInformation: '/renter/application/:applicationId/background-questions/background-information',
  generateViewRenterBackgroundInformation: (id?: number | string, next = '', section = '') =>
    `/renter/application/${id}/background-questions/background-information?next=${next}&section=${section}`,
  renterFurtherInformation: '/renter/property/:propertyId/background-questions/further-information',
  generateRenterFurtherInformation: (id: number | string) =>
    `/renter/property/${id}/background-questions/further-information`,
  renterConnectLinkedin: 'renter/property/:propertyId/background-questions/renter-connect-linkedin',
  generateRenterConnectLinkedin: (id: number | string) =>
    `renter/property/${id}/background-questions/renter-connect-linkedin`,
  // Identity Verification

  // Summary Report
  renterPaymentVerification: '/renter/application/:applicationId/summary',
  generateRenterPaymentVerification: (id?: number | string, section = '') =>
    `/renter/application/${id}/summary?section=${section}`,

  renterPaymentMethod: '/renter/application/:applicationId/payment/payment-method',
  generateRenterPaymentMethod: (id?: number | string) => `/renter/application/${id}/payment/payment-method`,

  // Renter Report
  renterReport: '/renter/application/:applicationId/report',
  generateRenterReportUrl: (id?: number | string) => `/renter/application/${id}/report`,
  renterSecurityQuestionnaire: '/renter/application/:applicationId/security-questionnaire',
  generateRenterSecurityQuestionnaire: (id?: number | string) => `/renter/application/${id}/security-questionnaire`,

  // Others
  renterReferralOnProperty: '/renter/referral-on-property',
  renterJustReferral: '/renter/just-referral',
  propertyRented: '/renter/:propertyId/property-rented',
};

export const publicRoutes = {
  renterAdultInvite: '/renter/adult-invite',
  renterGuarantorInvite: '/renter/guarantor-invite',
  agentOrganizationInvite: '/organization/invite',
  addressHistoryRequestVerification: '/request-verification/verify-address-history',
  verifierRedirectForm: '/request-verification/verifier-confirmation',
  employmentHistoryRequestVerification: '/request-verification/verify-employment-history',
  verifierEmploymentHistory: '/request-verification/employment-confirmation-history',
  renterInvite: '/invite',
  identityVerification: '/organizations/:organizationId/identity-verification',
  forgotPassword: '/forgot-password',
  generateIdentityVerification: (organizationId: number) => `/organizations/${organizationId}/identity-verification`,
  voepdf: '/voe-pdf',
};

export const superAdminRoutes = {
  organizations: '/organizations',
};
