import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { PDFTestPage } from 'pages/PDFTestPage/PDFTestPage';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { getRenterApplication, getRenterResume, resetSelectedRenter } from 'redux/slices/agentSlice';
import { getInvites } from 'redux/slices/organizationSlice';
import { AppThunkDispatch } from 'redux/store';

import Spinner from 'components/shared/Spinner/Spinner';
import { RenterResumeNavbarKeys } from 'constants/agentConstants';
import { StepRouteType } from 'helpers/address';
import { parseResponseErrors } from 'helpers/helper';
import { Notification } from 'shared/Notification/Notification';
import { Invite } from 'shared/types/organizationTypes';

import ApplicationDetails from '../../ApplicationDetails';
import RenterCreditAndBackground from '../RenterCreditAndBackground/RenterCreditAndBackground';
import RenterCriteriaAndTerms from '../RenterCriteriaAndTerms/RenterCriteriaAndTerms';
import RenterReceipt from '../RenterReceipt/RenterReceipt';
import RenterResumeWrapper from '../RenterResumeWrapper/RenterResumeWrapper';

const RenterResume = (): JSX.Element => {
  const [params] = useSearchParams();
  const dispatch = useDispatch<AppThunkDispatch>();
  const [invite, setInvite] = useState({} as Invite);
  const { applicationId, inviteCode } = useParams();
  const currentOrganization = useSelector(selectedOrganization);
  const activeTabName = params.get('activeTab') ?? RenterResumeNavbarKeys.APPLICATION;
  const tabRoutes: StepRouteType = {
    application: <ApplicationDetails invite={invite} />,
    creditAndBackground: <RenterCreditAndBackground />,
    criteriaAndTerms: <RenterCriteriaAndTerms />,
    receipt: <RenterReceipt />,
    pdf_preview: <PDFTestPage />,
  };

  useEffect(() => {
    if (inviteCode) {
      return;
    }

    if (currentOrganization?.id) {
      dispatch(
        getRenterApplication({ organizationId: currentOrganization.id, applicationId: Number(applicationId) })
      ).then((res) => {
        dispatch(getRenterResume({ renterId: res?.payload?.renterProfileId, organizationId: currentOrganization.id }));
      });
    }

    return () => {
      dispatch(resetSelectedRenter());
    };
  }, [applicationId, currentOrganization, dispatch, inviteCode]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (inviteCode && currentOrganization.id) {
      dispatch(getInvites(currentOrganization.id))
        .unwrap()
        .then((res) => {
          const newInvite = res?.invites.find((i: Invite) => i.inviteCode === inviteCode);

          newInvite && setInvite(newInvite);
        })
        .catch((error) => {
          Notification({ message: parseResponseErrors(error) });
        });
    }
  }, [applicationId, currentOrganization.id, dispatch, inviteCode]);

  if (inviteCode && !invite.inviteCode) {
    return <Spinner />;
  }

  return (
    <RenterResumeWrapper invite={invite}>
      <div>{tabRoutes[activeTabName]}</div>
    </RenterResumeWrapper>
  );
};

export default RenterResume;
