import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { selectedOrganization } from 'redux/selectors/organizationSelector';

import { ReactComponent as DropDownArrow } from 'assets/svgs/ArrowForward.svg';
import { ReactComponent as InvitedIcon } from 'assets/svgs/InviteIconWhite.svg';
import Button from 'components/shared/Button/Button';
import Spinner from 'components/shared/Spinner/Spinner';
import { VerificationStatus } from 'constants/agentConstants';
import { formatDateTime } from 'helpers/agentHelper';
import { formatPhoneNumber } from 'helpers/renterHelper';
import { sendAddressVerificationEmail } from 'services/agentService';
import { Notification } from 'shared/Notification/Notification';
import { AddressVerificationResponse } from 'shared/types/agentTypes';
import { AddressVerifier, VerifierTypes } from 'shared/types/renterTypes';

import { ViewSubmittedData } from './ViewSubmittedData';

import styles from './VerifierAddressContainer.module.scss';

export const VerifierAddressContainer = ({
  verificationInformation,
  primaryVerifier,
  isModalOpen,
  addressId,
  addressApplicationId,
}: {
  primaryVerifier: AddressVerifier;
  verificationInformation: AddressVerificationResponse;
  isModalOpen?: boolean;
  addressId?: number;
  addressApplicationId?: number;
}): JSX.Element => {
  const [isVerifierCollapsed, setIsVerifierCollapsed] = useState(false);
  const currentOrganization = useSelector(selectedOrganization);
  const [isLoading, setisLoading] = useState(false);
  const { applicationId } = useParams();
  const { t } = useTranslation();
  const [lastEmailSentTime, setLastEmailSentTime] = useState<number | null>(null);
  const sendEmailtoVerifier = (): void => {
    const recentClicked = Date.now();

    if (lastEmailSentTime && recentClicked - lastEmailSentTime < 5000) {
      Notification({ message: 'A request is already in progress', type: 'error' });

      return;
    }

    if (verificationInformation.verifierType === VerifierTypes.ADDRESS) {
      setisLoading(true);
      setLastEmailSentTime(recentClicked);
      sendAddressVerificationEmail({
        applicationId: applicationId || addressApplicationId,
        addressId: addressId,
        organizationId: currentOrganization.id,
      })
        .then(() => {
          Notification({ message: 'Verification email sent Successfully', type: 'info' });
        })
        .finally(() => setisLoading(false));
    }
  };

  return (
    <div className={styles.VerifierModalContainer}>
      <div className={styles.HeadingContainer}>
        <div className={styles.VerifierHeading}>{t('agent.verifier')}</div>
        <DropDownArrow
          onClick={() => setIsVerifierCollapsed(!isVerifierCollapsed)}
          width={12}
          className={classNames(styles.IconContainer, { [styles.IconArrowUp]: isVerifierCollapsed })}
        />
      </div>
      {!isVerifierCollapsed && (
        <>
          <Row className={styles.RenterInformation}>
            <Col sm={12} md={6} className={styles.LeftInformationContainer}>
              <ViewSubmittedData
                fullName={primaryVerifier.fullName || ''}
                emailAddress={primaryVerifier.emailAddress ?? ''}
                phoneNumber={formatPhoneNumber(primaryVerifier.phoneNumber || '')}
                verifierLabel={t('agent.verifier.submittedBy')}
                customContentClassName={styles.TopLeftRounded}
                hasPropertyRelation={false}
              />
            </Col>
            <Col sm={12} md={6} className={styles.RightInformationContainer}>
              <ViewSubmittedData
                submittedTime={formatDateTime(verificationInformation.submittedAt ?? '')}
                fullName={verificationInformation.submitVerifierFullName}
                emailAddress={verificationInformation.submitVerifierEmail}
                phoneNumber={formatPhoneNumber(verificationInformation.submitVerifierPhoneNumber)}
                propertyRelation={verificationInformation.submitVerifierRelationshipProperty}
                verifierLabel={t('agent.verifier.completedBy')}
                customContentClassName={styles.TopRightRounded}
                verifierType={verificationInformation.verifierType as VerifierTypes}
              />
            </Col>
            {isModalOpen && verificationInformation.verifierStatus === VerificationStatus.IN_PROGRESS && (
              <div className={styles.ButtonContainer}>
                <Button className={styles.Button} type="submit" onClick={sendEmailtoVerifier} disabled={isLoading}>
                  <div className={styles.SendInviteButton}>
                    <div>{isLoading ? <Spinner /> : <InvitedIcon />}</div>
                    <div>{t('agent.inviteApplicant.sendRequest')}</div>
                  </div>
                </Button>
              </div>
            )}
          </Row>
          <div className={styles.EventHistoryContainer}>
            <DropDownArrow height={18} width={18} />
            <div className={styles.EventHistory}>{t('agent.verifier.eventHistory')}</div>
          </div>
        </>
      )}
      <div className={styles.HorizontalLine}></div>
    </div>
  );
};
