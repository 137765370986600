import { CSSProperties, Dispatch, ReactNode, SetStateAction } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { FormikErrors, FormikHelpers, FormikState } from 'formik';

import { CustomPreviewFile } from 'components/shared/DropZone/CustomDropzone/CustomDropzone';
import { PreviewFile } from 'components/shared/DropZone/MinimizedDropzone/MinimizedDropzone';
import {
  ExperianAuthenticationStatus,
  NovaAuthenticationStatus,
  RenterAddressTypes,
  VisaTypes,
} from 'constants/renterConstants';
import { VerifierURLType } from 'shared/pdf/verifier/voe/VerifierPDF';

import { PropertyApplicationStatus } from './agentTypes';
import { ApplicationType } from './applicantsType';
import { AgentDetailsResponse } from './renterApplication';
import { BackgroundInfo } from './renterBackgroundInformation';
import { InviteType } from './renterCooccupants';
import { VehiclesInformationType } from './renterVehicleInformation';

export enum MaritalStatus {
  SINGLE = 'Single',
  MARRIED = 'Married',
}

export enum RenterRoles {
  OCCUPANTS = 'co-occupant',
  GUARANTOR = 'guarantor',
  PRIMARY = 'primary',
}

export interface NameParts {
  firstName?: string | undefined;
  lastName?: string | undefined;
}
export interface PhoneMasks {
  [countryCode: string]: string;
}
export type PhoneNumber = {
  id?: number;
  phoneNumber?: string;
  type?: string;
  createdAt?: string | Date;
  updatedAt?: string | Date;
};

export type VisaDocument = {
  id?: number;
  fileName?: string;
  fileLink?: string;
  fileType?: string;
  width?: number;
  height?: number;
  size?: number;
  validaStatus?: string;
  visaType?: VisaTypes;
  createdAt?: string | Date;
  updatedAt?: string | Date;
};
export interface MinorDispatchProps {
  values: RenterMinorProps;
  buttonType?: string;
  setErrors?: (errors: FormikErrors<RenterMinorProps>) => void;
  resetForm?: (nextState?: Partial<FormikState<RenterMinorProps>> | undefined) => void;
  setSubmitting?: (isSubmitting: boolean) => void;
}
export interface AdultDispatchProps {
  values: RenterAdultProps;
  setErrors?: (errors: FormikErrors<RenterAdultProps>) => void;
  resetForm?: (nextState?: Partial<FormikState<RenterAdultProps>> | undefined) => void;
  setSubmitting?: (isSubmitting: boolean) => void;
}
export interface ErrorResponse {
  errors: {
    errorCode: number;
    errorMessage: string;
  }[];
  // Add any other properties if present in the response data
}
export const RenterProfileInitialValue = {
  firstName: '',
  lastName: '',
  renterPhoneNumbers: [] as PhoneNumber[],
  expirationDate: '' || undefined,
  ssnTailDigits: '' || undefined,
  birthDate: '' || undefined,
};
export type RenterAdultProps = {
  id?: number;
  firstName?: string;
  lastName?: string;
  relationship: string;
  phoneNumber: string;
  emailAddress: string;
  createdAt?: string | Date;
  updatedAt?: string | Date;
};
export const RenterAdultInitialValues = {
  relationship: '',
  phoneNumber: '',
  emailAddress: '',
};
export type RenterMinorProps = {
  id?: number;
  fullName: string;
  firstName?: string;
  lastName?: string | null;
  relationship: string;
  birthDate?: string | Date;
  createdAt?: string | Date;
  updatedAt?: string | Date;
};
export interface RenterMinorPayload extends Omit<RenterMinorProps, 'birthDate'> {
  birthDate?: Date;
}
export const RenterMinorInitialValues: RenterMinorProps = {
  birthDate: '',
  firstName: '',
  relationship: '',
  lastName: '',
  fullName: '',
};
export type RenterApplicationProps = {
  id: number;
  validUpto: string | Date;
  signature: string;
  isConfirmed: boolean;
  renterProfileId: number;
  renterRole: RenterRoles;
  reportConsent?: boolean;
  parentApplicationId: number;
  createdAt: string | Date;
  updatedAt: string | Date;
  minors: RenterMinorProps[];
  adults: RenterAdultProps[];
  guarantor?: GuarantorDispatchResponse;
  confirmedAt?: string;
  backgroundInformation: BackgroundInfo[];
  associatedApplications: ApplicationType[];
  agentDetails?: AgentDetailsResponse;
  hasNoAdults: boolean;
  hasNoGuarantor: boolean;
  hasNoMinors: boolean;
};
export const RenterApplicationInitialValue = {
  validUpto: null,
  signature: null,
  isConfirmed: null,
  createdAt: null,
  updatedAt: null,
  minors: [] as RenterMinorProps[],
  adults: [] as RenterAdultProps[],
  agentDetails: {} as AgentDetailsResponse,
  hasNoAdults: false,
  hasNoGuarantor: false,
  hasNoMinors: false,
};

export type RenterBasicProfileProps = {
  id?: number;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  birthDate?: Date | string;
};
export type RenterPhotoIDProps = {
  id?: number;
  idType?: string;
  issuingGovernment?: string;
  idNumber?: string;
  expirationDate?: Date | string;
};

export type RenterProfileProps = {
  id?: number;
  firstName?: string;
  middleName?: string | null;
  lastName?: string;
  renterPhoneNumbers?: PhoneNumber[];
  birthDate?: Date | string;
  stripeVerified?: boolean;
  expirationDate?: Date | string;
  ssnTailDigits?: string | number;
  socialSecurityNumber?: string;
  createdAt?: string | Date;
  updatedAt?: string | Date;
  hasNoSSN?: boolean;
  hasNoPriorAddress?: boolean;
  hasNoPriorEmployments?: boolean;
  hasNoVehicles?: boolean;
  hasNoFurryFriends?: boolean;
  renterCriteriaAccepted?: boolean;
  // renter custom information

  company?: string;
  title?: string;
  monthlyGross?: string;
  supervisor?: string;
  verifierPhoneNumber?: string;
  verifierEmail?: string;
  description?: string;
  experianAuth?: ExperianAuthenticationStatus;
  visaType?: string | null;
  visaTypeDescription?: string | null;
  visaDocuments?: VisaDocument[];
};

export type RenterPresentEmployments = {
  id?: number;
  fullName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  jobStartDate?: string | Date;
  jobEndDate?: string | Date;
  employmentSubType?: string;
  jobDescription?: string;
  status?: string;
  explanation?: string;
  monthlyGrossIncome?: number;
  title?: string;
  company?: string;
  documents?: PreviewFile[];
  presentEmploymentVerifiers?: EmploymentVerifier[];
  businessName?: string;
};

export type RenterPresentEmploymentProps = {
  renterId: number;
  presentEmployments: RenterPresentEmployments[];
};

export interface StripeVerificationDocument {
  documentFileLink: string;
  createdAt: string;
}
export interface StripeDocument {
  documentType: string;
  documentId: string;
  state?: string;
  stripeVerificationDocumentFileLink: StripeVerificationDocument[];
}
export type RenterProfileInformationProps = {
  userId: number;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  birthDate: string;
  ssnTailDigits: string | number;
  stripeVerified: boolean;
  renterPhoneNumbers?: PhoneNumber[];
  createdAt?: string | Date;
  updatedAt?: string | Date;
  addresses?: AddressInformation[];
  applications: RenterApplicationProps[];
  company: string;
  title: string;
  monthlyGross: string;
  supervisor: string;
  verifierPhoneNumber: string;
  verifierEmail: string;
  description: string;
  presentEmployments: RenterPresentEmployments[];
  priorEmployments: RenterPresentEmployments[];
  vehicles: VehiclesInformationType[];
  furryFriends: AnimalInformationProps[];
  signedTermsOfUse: boolean;
  isPaymentSucceeded: boolean;
  driverLicense: string;
  hasNoSSN: boolean;
  hasNoPriorAddress: boolean;
  hasNoPriorEmployments: boolean;
  hasNoVehicles: boolean;
  hasNoFurryFriends: boolean;
  renterCriteriaAccepted: boolean;
  experianAuth: ExperianAuthenticationStatus;
  creditScore?: string | null;
  stripeDocument?: StripeDocument;
  visaType?: VisaTypes | null;
  visaTypeDescription: string | null;
  visaDocuments?: VisaDocument[];
  creditCheckProvider: string | null;
  novaAuth: NovaAuthenticationStatus;
  novaCreditScore?: string | null;
};
export interface DiscardConfirmationProps {
  isErrorModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  setIsErrorModalVisible: Dispatch<SetStateAction<boolean>>;
}
export interface EditDetailsProps {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  onHideHandler: () => void;
  id?: number | undefined;
  heading?: string;
  description?: string;
  animalIndex?: number;
}
export interface EditGuarantorDetailsProps extends EditDetailsProps {
  guarantorInformation: Relation;
}
export interface ModalBasicProps {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  serverError: number;
}
export interface EditVehicleDetailsProps {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  onHideHandler: () => void;
  data: VehiclesInformationType;
}

export const RenterProfileInformationInitialValue = {
  userId: 0,
  firstName: '' || undefined,
  middleName: null || undefined,
  lastName: '' || undefined,
  birthDate: '',
  ssnTailDigits: '',
  stripeVerified: false,
  renterPhoneNumbers: [] as PhoneNumber[],
  createdAt: '',
  updatedAt: '',
  addresses: [],
  applications: [] as RenterApplicationProps[],
  company: '',
  title: '',
  monthlyGross: '',
  supervisor: '',
  verifierPhoneNumber: '',
  verifierEmail: '',
  description: '',
  presentEmployments: [] as RenterPresentEmployments[],
  priorEmployments: [] as RenterPresentEmployments[],
  vehicles: [] as VehiclesInformationType[],
  furryFriends: [] as AnimalInformationProps[],
  signedTermsOfUse: false,
  isPaymentSucceeded: false,
  backgroundInformation: [] as BackgroundInfo[],
  driverLicense: '',
  hasNoSSN: false,
  hasNoPriorAddress: false,
  hasNoPriorEmployments: false,
  hasNoVehicles: false,
  hasNoFurryFriends: false,
  renterCriteriaAccepted: false,
  experianAuth: ExperianAuthenticationStatus.IN_PROGRESS,
  visaType: null,
  visaTypeDescription: null,
  visaDocuments: [] as VisaDocument[],
  creditCheckProvider: null,
  novaAuth: NovaAuthenticationStatus.IN_PROGRESS,
};

export interface HandleNextProps {
  params: RenterProfileProps;
  nextStep: string;
  setErrors: (errors: FormikErrors<RenterProfileInformationProps>) => void;
  successCallback?: () => void;
}

export type RenterInformationEditProps = {
  handleNext?: (data: HandleNextProps) => void;
};

export interface RelationOccupantProps {
  label: string;
  attributeLabel: string;
  addAnotherButtonLabel: string;
  confirmButtonLabel: string;
  informationText: string;
  headerText: string;
  floatingLabel: string;
  continueButtonOnClick: () => void;
  addAnotherButtonOnClick: () => void;
}
export type OwnerInformation = {
  fullName: string;
  rentAmount: number | string;
  phoneNumber: number | string;
  email: string;
  type: string;
};

export type AddressInformation = {
  id?: number;
  streetAddress?: string;
  city?: string;
  state?: string;
  reasonForMoving?: string;
  zipCode?: string;
  contactRent?: number;
  phoneNumber?: string;
  emailAddress?: string;
  addressType?: string;
  isVerified?: boolean;
  apartmentNumber?: string;
  fromDate?: string | null;
  toDate?: string | null;
  moveInDate?: string;
  createdAt?: string;
  fullName?: string;
  updatedAt?: string | null;
  isRented?: boolean;
  addressVerifiers?: AddressVerifier[];
  classification?: string;
  description?: string | null;
};
export interface AddressVerifier {
  id?: number;
  contactRent: number;
  phoneNumber: string;
  fullName: string;
  emailAddress: string;
  isPrimary: boolean;
  submittedAt: string;
  verificationToken: string;
  type: VerifierTypes;
}
export type AddressInformationResponse = {
  id?: number;
  streetAddress?: string;
  city?: string;
  state?: string;
  reasonForMoving?: string;
  zipCode?: string;
  addressType?: string;
  apartmentNumber?: string;
  fromDate?: string | null;
  toDate?: string | null;
  moveInDate?: string;
  createdAt?: string;
  updatedAt?: string | null;
  isRented?: boolean;
  isVerified?: boolean;
  addressVerifiers?: AddressVerifier[];
};

export type RenterAddressInformationProps = {
  streetAddress?: string;
  city?: string;
  state?: string;
  reasonForMoving?: string;
  zipCode?: string;
  contactRent?: number | null;
  fullName?: string | null;
  phoneNumber?: string | null;
  emailAddress?: string | null;
  addressType?: string;
  apartmentNumber?: string | null;
  fromDate?: string | null;
  toDate?: string | null;
  moveInDate?: string | null;
  isRented?: boolean;
  description?: string | null;
  classification?: string;
};
export const initialPresentAddress: AddressInformation = {
  streetAddress: '',
  city: '',
  zipCode: '',
  state: '',
  addressType: RenterAddressTypes.PRESENT,
  fromDate: null,
  toDate: null,
  updatedAt: null,
  isRented: false,
};

export const AddressInitialValues = {
  renterAddress: initialPresentAddress,
  state: '' || undefined,
  city: '' || undefined,
  zipCode: '' || undefined,
  streetAddress1: '' || undefined,
  apartment: '' || undefined,
  toDate: '' || undefined,
  moveInDate: '' || undefined,
  fromDate: '' || undefined,
  isRenting: undefined,
};

export interface AddressInitialValuesProps {
  renterAddress: AddressInformation;
  state?: string;
  city?: string;
  zipCode?: string;
  streetAddress1?: string;
  apartment?: string;
  moveInDate?: string;
  toDate?: string;
  fromDate?: string;
  rent?: number;
  fullName?: string;
  phoneNumber?: string;
  emailAddress?: string;
  classification?: string;
  description?: string;
  reasonForMoving?: string;
  isRenting?: boolean;
}

export type AddressProps = {
  addressArray: AddressInformation[];
  address: AddressInformation;
};

export type StepProps = {
  [key: string]: JSX.Element;
};

export type StringHashType = {
  [key: string]: string;
};

export type RenterPresentEmploymentsProps = {
  values: RenterPresentEmployments;
  setErrors: (errors: FormikErrors<RenterProfileInformationProps>) => void;
};

export type RenterConfirmModalProps = {
  onExit: () => void;
  onGoBack: () => void;
  shouldOpen: boolean;
};

export type RenterDeletePhoneModalProps = {
  handleDelete: () => void;
  onGoBack: () => void;
  shouldOpen: boolean;
  heading: string;
  validationHeading: string;
};

export type RenterDeleteConfirmationModalProps = {
  shouldOpen: boolean;
};

export type EditPriorIncomeProps = {
  data: RenterPresentEmployments;
  onHide: () => void;
  heading?: string;
};

export type CustomEditModalProps = {
  heading?: string;
  isForEditing?: boolean;
};
export type Relation = {
  id?: number;
  relationship?: string;
  phoneNumber?: string;
  emailAddress?: string;
};
export interface GuarantorInitialValuesProps {
  relation: Relation;
  relationship?: string;
  phoneNumber?: string;
  emailAddress?: string;
}
export const initialGuarantorRelation: Relation = {
  relationship: 'GrandMother',
  phoneNumber: '12323232323',
  emailAddress: 'georgiaapple23@example.com',
};
export const GuarantorInitialValues = {
  relation: initialGuarantorRelation,
  relationship: '',
  phoneNumber: '',
  emailAddress: '',
};

export interface GuarantorDetailsFormProps {
  relationshipName: string;
  emailAddressName: string;
  phoneNumberName: string;
}
export interface GuarantorDispatchResponse {
  id: number;
  firstName: string;
  lastName: string;
  relationship: string;
  phoneNumber: string;
  emailAddress: string;
  createdAt?: string | Date;
  updatedAt?: string | Date;
}
export interface GuarantorDispatchProps {
  relationship: string;
  phoneNumber: string;
  emailAddress: string;
}

export interface AnimalProps {
  animalType?: string;
}

export const AnimalInformationInitialValues = {
  furryFriendType: '',
  assistiveAnimalType: undefined,
  furryFriendSubType: '',
  genderType: '',
  name: '',
  breed: '',
  weight: undefined,
  age: undefined,
  ageUnit: 'year',
  id: undefined,
  images: [] as PreviewFile[],
  documents: [] as PreviewFile[],
};
export interface AnimalInformationProps {
  furryFriendType: string;
  assistiveAnimalType?: string | null;
  furryFriendSubType: string;
  genderType: string;
  name: string;
  breed: string;
  weight?: number | undefined;
  age?: number | undefined;
  ageUnit: string;
  id?: number | undefined;
  documents?: PreviewFile[];
  images: PreviewFile[];
}
export interface PhoneNumberInitialValuesProps {
  phoneNumber: string;
  type: string;
}
export const PhoneNumberInitialValues = {
  phoneNumber: '',
  type: '',
};
export type UpdateErrorParams = {
  id: number | undefined;
  newValues: PhoneNumber;
  fieldName: string;
  setPhoneNumberError: FormikHelpers<PhoneNumberInitialValuesProps>['setFieldError'];
};

export type FinicityConnectUrl = {
  link: string;
};
export interface AnimalDispatchProps {
  values: AnimalInformationProps;
  setErrors: (errors: FormikErrors<AnimalInformationProps>) => void;
  setSubmitting: (isSubmitting: boolean) => void;
}
export type StripePaymentResponse = {
  clientSecret: string;
};
export interface InitialValuesPayment {
  name: string;
  email: string;
  discountCoupon?: string;
}
export const InitialValuesRenterPayment = {
  name: '',
  email: '',
};
export type PaymentFormProps = {
  clientSecret: string;
  setIsOtpModalOpen: Dispatch<SetStateAction<boolean>>;
  setInitialOtpCode: Dispatch<SetStateAction<string>>;
};
export interface ReorderImagesProps {
  furryFriendId: number;
  imageIds: (number | undefined)[];
}

export interface GuarantorInviteParams {
  applicationId: string | number;
  propertyId: string | number;
  guarantorId: string | number;
  inviteType: InviteType;
}
export interface FurryFriendDocument {
  fileLink: string;
  fileName: string;
  fileType: string;
  height: number;
  id: number;
  position: number;
  size: number;
  width: number;
}
export interface FurryFriendUploadDocumentApi {
  furryFriendId: number;
  document: FurryFriendDocument[];
}
export interface FurryFriendUploadProps {
  furryFriendId?: number;
  document: PreviewFile;
}
export interface FurryFriendDeleteApi {
  furryFriendId: number;
  documentId: number | undefined;
}
export interface FurryFriendUploadImageProps {
  furryFriendId: number;
  images: FurryFriendDocument[];
}

export type PersonalProfileValues = {
  firstName: string;
  lastName: string;
  middleName: string;
};

export type DobValues = {
  birthDate: string | Date;
};

export interface AnimalInformationTooltipProps {
  handleAddInfo: () => void;
  animalInformation: AnimalInformationProps[];
}
export interface VehicleInformationTooltipProps {
  handleEditInfo?: (vehicle: VehiclesInformationType) => void;
  handleAddInfo: () => void;
  vehicleInformation: VehiclesInformationType[];
  domId: string;
}

export interface AnimalDisplayCardProps {
  animalInformation: AnimalInformationProps;
  shouldShowEditIcon?: boolean;
  shouldUploadData?: boolean;
  index: number;
}
export interface AnimalDetailsInformationCardProps {
  animalInformation: AnimalInformationProps;
  url?: string;
  setExplorerOpen?: Dispatch<SetStateAction<boolean>>;
  shouldShowEditIcon?: boolean;
  shouldShowPill?: boolean;
  index?: number;
  setAnimalId?: Dispatch<SetStateAction<number | undefined>>;
  animalId?: number | undefined;
}

export interface BackgroundQuestionProps {
  index: number;
}

export interface ApplicationProps {
  id?: number;
  submittedAt: string | Date;
  applicationStatus: PropertyApplicationStatus;
  streetAddress: string;
  city: string;
  zipCode: string;
  state: string;
  rent: number;
  agentFirstName: string;
  agentLastName: string;
  agentEmail: string;
  agentProfile: string;
  propertyImage: string;
  propertyType?: string;
  isCustomAddress: boolean;
  dateAvailable: string;
}
export interface VehicleDescriptionCardProps {
  vehicle: VehiclesInformationType;
  customKey?: number;
  subtype?: string;
  hasFontSize?: boolean;
}

export interface SingleSideMenuItem {
  heading: string;
  status?: string;
  subItems?: SingleSideMenuItem[];
}
export type SelectedOption = {
  level: number;
  option: string;
};
export type ApplicationComponents = {
  [key: string]: JSX.Element;
};
export interface NextRouteProp {
  next?: string;
}

export interface DesiredMoveInDate {
  moveInDate: string;
}
export interface RenterCustomAddressRequestType {
  streetAddress1: string;
  city: string;
  state: string;
  zipCode: string;
  inviteCode: string;
  desiredMoveInDate: string;
}
export interface RenterCustomAddressType {
  renterAddress: AddressInformation;
  streetAddress1: string;
  city: string;
  state: string;
  zipCode: string;
  inviteCode: string;
  apartment?: string;
  desiredMoveInDate: string;
  moveInDate?: string;
  toDate?: string;
  fromDate?: string;
  rent?: number;
  fullName?: string;
  phoneNumber?: string;
  emailAddress?: string;
  classification?: string;
  description?: string;
  reasonForMoving?: string;
  isRenting?: boolean;
}
export const RenterCustomAddressInitialValues = {
  renterAddress: {} as AddressInformation,
  streetAddress1: '',
  city: '',
  state: '',
  zipCode: '',
  inviteCode: '',
  apartment: '',
  desiredMoveInDate: '',
};

export interface CreateCustomAddressProps {
  values: RenterCustomAddressRequestType;
  navigate: NavigateFunction;
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
}
export interface AgentCustomAddressApiProps {
  navigate: NavigateFunction;
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
  setErrors: (errors: FormikErrors<RenterCustomAddressType>) => void;
  values: RenterCustomAddressRequestType;
}

export interface CreateCustomAddressResponseProps {
  id: number;
  applicationId: number;
  propertyId: number;
  inviteCode: string;
}
export interface CreateCustomAddressApiPayloadProps {
  errors: [];
  payload: CreateCustomAddressResponseProps;
}
export interface RentalSubmitFormProps {
  isRelationshopDisabled?: boolean;
  heading?: string;
  subHeading?: string;
  formConsent: string;
  formConsentOffer: string;
  isHeadingOptional?: boolean;
  isCompanyNameOptional?: boolean;
}

export interface RequestVerificationDetailsProps {
  isDetailsDisabled: boolean;
  requestedAt?: string;
  isSubmitted: boolean;
  type: VerifierURLType;
}
export interface RequestVerificationInfoPanelProps {
  infoDescription: ReactNode;
  isLinkDisabled?: boolean;
  isIconDisabled?: boolean;
  className?: string;
}
export interface RentalHistoryTitleProps {
  infoDescription?: string;
  title?: string;
}

export interface FormatAddressZipCode {
  zipCode?: string;
  city?: string;
  state?: string;
}
export interface PersonalProfileProps {
  isReportPage?: boolean;
}

export type SelectOption = SingleValue<{
  value: string;
  label: string;
}>;
export type CustomSelectOption = SingleValue<{
  value: string;
  label: string;
}>;

export type Meta = {
  toDate?: string;
  fromDate?: string;
  textDetails?: string;
  numberDetails?: number;
};

export type RentalHistoryQuestionnaireType = {
  id: number;
  question: string;
  questionType: string;
  acceptedReason: string;
  answer?: string;
  meta?: Meta;
};

export type SubmitVerifier = {
  phoneNumber: string;
  fullName: string;
  emailAddress: string;
  companyName: string;
  relationshipProperty: string;
  privacyConsent: boolean;
  titleOfVerifier: string;
};
export type SubmitVerificationType = {
  questionId: number | null;
  answer: string | undefined;
  meta: Meta | undefined;
};

export type SubmitVerifierResponseType = {
  verificationInformation: SubmitVerificationType[];
  submitVerifier: SubmitVerifier | undefined;
};

export interface SubmitVerificationProps {
  token: string;
  values: SubmitVerifierResponseType;
  acceptedFiles: CustomPreviewFile[];
}

export type FormValues = {
  verificationQuestions: RentalHistoryQuestionnaireType[];
  submitVerifier?: SubmitVerifier;
  submittedAt?: string;
  verifierType: string;
};

export type ApplicationAdditionalInfoType = {
  animalsCount: string;
  desiredMoveInDate: string;
  occupantsCount: string;
  groupIncome: string;
};

export interface RentalHistoryQuestionnaireProps {
  questionnaire: RentalHistoryQuestionnaireType;
  index: number;
}

export interface QuestionFormValues {
  verificationQuestions: RentalHistoryQuestionnaireType[];
  submitVerifier?: SubmitVerifier;
}

export interface VerificationQuestions {
  id: number;
  question: string;
  questionType: string;
  acceptedReason: string;
}
export interface VerificationQuestionnaireResponse {
  verificationQuestions: VerificationQuestions[];
}
export interface ApplicationInfoBoxHeaderProps {
  submittedAt: string | Date;
  applicationStatus: PropertyApplicationStatus;
}
export interface ApplicationInfoBoxProps {
  applicationData: ApplicationProps;
  isCollapsedDefault?: boolean;
  isSelectedDefault?: boolean;
  nextRoute?: string;
}
export interface ApplicationNonSelectedProps {
  dateAvailable: string;
  propertyImage: string;
  rent: number;
  propertyType?: string;
  isCustomAddress: boolean;
  moveInDate: string;
}
export interface ApplicationSelectedProps {
  city: string;
  dateAvailable: string;
  propertyImage: string;
  rent: number;
  state: string;
  streetAddress: string;
  zipCode: string;
  isCustomAddress: boolean;
  moveInDate: string;
  agentProfile: string;
  agentFirstName: string;
  agentLastName: string;
}
export interface BackgroundQuestion {
  id: number;
  question: string;
}

export interface BackgroundQuestionnaireResponse {
  backgroundQuestions: BackgroundQuestion[];
}

export interface BackgroundInformation {
  questionId: number;
  answer: string | undefined;
  explanation: string | undefined | null;
}

export interface UpdateBackgroundInfo {
  answer: string | null | undefined;
  explanation: string | null | undefined;
}
export interface SubmitBackgroundInfoProps {
  applicationId: number;
  submitBackgroundInformation: BackgroundInformation[];
}

export interface UpdateBackgroundInfoProps {
  applicationId: number;
  backgroundInfoId: number;
  backgroundInfo: UpdateBackgroundInfo;
}

export interface ApplicationAdditionalInfoProps {
  applicationId: number;
  propertyId: number;
  organizationId: number;
}
export interface ApplicationOptionProps {
  isNew?: boolean;
  isPending?: boolean;
  label: string;
  onClickHandler: () => void;
}

export interface CollapsibleComponentsProps {
  nextRoute: string;
}
export interface CollapsibleBadgeComponentsProps extends NextRouteProp {
  isPending?: boolean;
}

export enum VerifierTypes {
  ADDRESS = 'ADDRESS',
  EMPLOYMENT = 'EMPLOYMENT',
}

export interface EmploymentVerifier extends AddressVerifier {
  titleOfVerifier: string;
}
export interface SubmitRenterOtpProps {
  applicationId: number;
  code: string;
}

export interface SingleOTPInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isFocus?: boolean;
}
export interface OTPModalHeaderProps {
  shouldShowCloseIcon?: boolean;
}
export interface OTPModalBodyProps {
  otpFailed?: boolean;
  shouldShowExpirey?: boolean;
  isLoading?: boolean;
  onOtpChangeHandler: (e: string) => void;
  hasErrors?: boolean;
  setIsSubmitClicked: Dispatch<SetStateAction<boolean>>;
  initialOtpCode?: string;
  isExpired?: boolean;
  onResendOtpClickHandler: () => void;
}
export interface OTPModalProps {
  onSubmitHandler: (code: string) => void;
  initialOtpValue?: string;
  isExpired?: boolean;
  isLoading?: boolean;
  onResendOtpClickHandler: () => void;
}
export interface OTPInputProps {
  length: number;
  onChangeOTP: (otp: string) => void;
  hasAutoFocus?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  className?: string;
  inputStyle?: CSSProperties;
  inputClassName?: string;
  submitOTP?: () => void;
  initalOtpValue?: string;
}
export interface CheckoutProps {
  secret: string;
  setIsOtpModalOpen: Dispatch<SetStateAction<boolean>>;
  setInitialOtpCode: Dispatch<SetStateAction<string>>;
}

export interface SubmitRenterOtpRequestProps {
  setHasOtpExpired: Dispatch<SetStateAction<boolean>>;
  setIsOtpRequestLoading: Dispatch<SetStateAction<boolean>>;
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
  navigate: NavigateFunction;
  applicationId: string;
  code: string;
  setInitialOtpCode: Dispatch<SetStateAction<string>>;
}
export interface ActionTooltipProps {
  isShowingOverlay: boolean;
  setIsShowingOverlay: Dispatch<React.SetStateAction<boolean>>;
  hasShareConsent: boolean;
  setHasShareConsent: Dispatch<React.SetStateAction<boolean>>;
}
export interface UpdateRenterReportProps {
  applicationId: number;
  isConsented: boolean;
}

export type RenterAddressInformation = {
  addressId: string;
  city: string;
  state: string;
  zipCode: string;
  streetAddress: string;
  addressType: string;
  fromDate: string;
  toDate: string;
  moveInDate: string;
  rent: number;
};

export type RenterEmploymentInformation = {
  title: string;
  company: string;
  monthlyGrossIncome: number;
  jobStartDate: string;
};

export type UserDetailsOnVerificationPageProps = {
  renterFirstName: string;
  renterMiddleName: string;
  renterLastName: string;
  renterEmail: string;
  renterPhoneNumber: string;
  verifierFullName: string;
  verifierEmail: string;
  verifierPhoneNumber: string;
  requestedAt: string;
};

export type RenterAddressDetails = UserDetailsOnVerificationPageProps & {
  renterAddressInformation: RenterAddressInformation;
};
export type RenterEmploymentDetails = UserDetailsOnVerificationPageProps & {
  renterEmploymentInformation: RenterEmploymentInformation;
};

export interface UserDetailsOnVerificationPageResponse {
  errors: ErrorResponse;
  payload: UserDetailsOnVerificationPageProps;
}

export type VerifierDetailsOnVerification = {
  phoneNumber?: string | null;
  fullName?: string | null;
  emailAddress?: string | null;
  companyName?: string | null;
  titleOfVerifier?: string | null;
  rejectedReason: string;
};
export const VerifierDetailsOnVerificationInitialValues = {
  phoneNumber: null,
  fullName: null,
  emailAddress: null,
  companyName: null,
  titleOfVerifier: '',
  rejectedReason: '',
};

export type RenterResumeNavbarProps = {
  applicationStatus: string;
  creditScore: string | null;
  experianAuth: ExperianAuthenticationStatus;
  novaCreditScore: string | null;
};

export type CreateCustomProperty = {
  navigate: NavigateFunction;
  inviteType: string;
  inviteCode: string;
  stepName: string;
};

export type FinicityConnectButtonProps = {
  isClicked: Dispatch<SetStateAction<boolean>>;
  applicationId: number;
  setIsFinicityButtonClicked: (isFinicityButtonClicked: boolean) => void;
};

export interface AddressLivingSituationProps {
  address: AddressInformation;
  mainHeading: string;
  subHeading: string;
  isPrior?: boolean;
  customButtonText?: string;
  isForRent?: boolean;
  onClickHandler: () => void;
}

export interface AddressLivingSituationSummaryProps {
  address: AddressInformation;
  onClickHandler: () => void;
  isForRent?: boolean;
  customButtonText?: string;
}

export interface PresentAddressInformationProps {
  address: AddressInformation;
  onClickHandler: () => void;
  isPrior?: boolean;
  mainHeading: string;
  subHeading: string;
  isForRent?: boolean;
  customSubButtonText?: string;
  customMainButtonText?: string;
}
export interface LivingSituationBoxProps {
  address: AddressInformation;
  customHeading?: string;
  onClickHandler: () => void;
}

export interface AnimalDetailsCardProps {
  isEditModal: boolean;
  animalIndex?: number;
}

export interface AnimalHeaderProps {
  animalType: string;
  animalInformation: AnimalInformationProps[];
  isSummaryPage?: boolean;
}
export type AnimalTooltipProps = {
  onDeleteHandler: () => void;
  onEditHandler: (animalId: number) => void;
  isShowingOverlay: boolean;
  setIsShowingOverlay: Dispatch<SetStateAction<boolean>>;
  setAnimalToDelete: Dispatch<SetStateAction<number>>;
  animalInformation: AnimalInformationProps;
  index: number;
};
