import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { parseISO } from 'date-fns';
import { Formik } from 'formik';
import { isEmpty } from 'lodash-es';
import { renterId } from 'redux/selectors/authSelector';
import { selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { addRenterProfileInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';
import { renterProfileSchema } from 'schema/renterSchema';

import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';
import { HandleNextProps, StepProps } from 'shared/types/renterTypes';

import RenterAddressInformation from '../components/AddressInformation/AddressInformation';
import RenterContainer from '../components/RenterContainer';
import RenterPersonalInfoSummary from '../components/RenterPersonalInfoSummary';
import RenterSSN from '../components/RenterSSN';
import RenterPhotoIdInformation from '../RenterPhotoIdInformation/RenterPhotoIdInformation';
import SSNCheck from '../SSNCheck/SSNCheck';

import indexStyles from '../../Renter/RenterIndex.module.scss';

const RenterProfileInformation = (): JSX.Element => {
  const maxHeight = useDeviceHeight(220);
  const dispatch = useDispatch<AppThunkDispatch>();
  const [searchParams] = useSearchParams();
  const { applicationId } = useParams();
  const stepName = searchParams.get('step') || 'basicInfo';
  const navigate = useNavigate();
  const userId = useSelector(renterId);
  const renterProfileInformation = useSelector(selectRenterProfileInformation);
  const initialValues = useMemo(
    () => ({ ...renterProfileInformation, birthDate: new Date(parseISO(renterProfileInformation.birthDate)) }),
    [renterProfileInformation]
  );
  const handleNext = useCallback(
    ({ params, nextStep, setErrors, successCallback }: HandleNextProps): void => {
      if (!isEmpty(params)) {
        params.id = userId || renterProfileInformation.userId;
        dispatch(addRenterProfileInformation({ values: params, shouldSnnChange: true }))
          .unwrap()
          .then(() => {
            if (nextStep) {
              navigate(renterRoutes.generatePersonalInformationUrl(nextStep, Number(applicationId)));
            }

            if (successCallback) {
              successCallback();
            }
          })
          .catch((error) => {
            setErrors(error);
          });
      } else if (nextStep) {
        navigate(renterRoutes.generatePersonalInformationUrl(nextStep, Number(applicationId)));
      }
    },
    [applicationId, dispatch, navigate, renterProfileInformation.userId, userId]
  );
  const currentStepComponent: StepProps = {
    basicInfo: <RenterPhotoIdInformation handleNext={handleNext} />,
    profileSummary: <RenterPersonalInfoSummary handleNext={handleNext} />,
    presentAddressConfirmation: <RenterAddressInformation />,
    SSNCheck: <SSNCheck />,
    SSNInput: <RenterSSN handleNext={handleNext} />,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={renterProfileSchema[stepName]}
      enableReinitialize
      onSubmit={(values) => {
        console.log('form', values);
      }}
    >
      <RenterContainer>
        <section className={indexStyles.renterBody}>
          <div style={{ paddingRight: '12px', height: maxHeight, paddingBottom: '20px', overflowY: 'auto' }}>
            {currentStepComponent[stepName]}
          </div>
        </section>
      </RenterContainer>
    </Formik>
  );
};

export default RenterProfileInformation;
