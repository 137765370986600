import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as Error } from 'assets/svgs/error.svg';
import RCButton from 'components/shared/Button/Button';
import { TwoFactorStatusProps } from 'shared/types/sharedTypes';

import styles from '../Modal2FA.module.scss';

export const TwoFactorFailure = memo(({ setIsModalOpen }: TwoFactorStatusProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.SuccessContainer2}>
        <Error className={styles.CheckCircle} />
        <p className={classNames(styles.SuccessStyle1, 'text-center')}>{t('otpModal.2FAsorry')}</p>
        <p className={classNames(styles.SuccessStyle2, 'text-center')}>
          Subsection description Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua.
        </p>
      </div>
      <div className={styles.ButtonContainer2FA}>
        <RCButton
          onClick={() => {
            setIsModalOpen(false);
          }}
          variant="outline"
          className={styles.CancelButton}
        >
          {'Exit'}
        </RCButton>
      </div>
    </>
  );
});
