import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import {
  selectCurrentProperty,
  selectedRenterDetails,
  selectRenterCreditProfile,
  selectRenterCriminalReport,
} from 'redux/selectors/agentSelector';
import {
  applicationProperties,
  selectRenterCreditProfile as selectRenterCreditProfileInformation,
  selectRenterCriminalReport as selectRenterCriminalReportInformation,
  selectRenterProfileInformation,
} from 'redux/selectors/renterSelector';

import { ReactComponent as ArrowIcon } from 'assets/svgs/BlackDownArrow.svg';
import { BackgroundDataTypes } from 'constants/agentConstants';
import { ReportErrorTypes } from 'constants/reportConstants';
import { formatDateToApplicantDOBFormat } from 'helpers/applicantsHelper';

import { EmptyBackgroundReport } from '../EmptyBackgroundReport/EmptyBackgroundReport';
import { ReportHeading } from '../ReportHeading/ReportHeading';
import { ReportStatusLoader } from '../ReportStatusLoader/ReportStatusLoader';
import { CandidateOffenseReport } from '../SharedReports/CandidateOffenseReport/CandidateOffenseReport';
import { CriminalSubjectReport } from '../SharedReports/CriminalSubjectReport/CriminalSubjectReport';
import { EmptyReportRecord } from '../SharedReports/EmptyReportRecord/EmptyReportRecord';

import styles from './CriminalReport.module.scss';
export const CriminalReport = ({
  isRenterFlow = false,
  isSuperAdmin,
}: {
  isRenterFlow?: boolean;
  isSuperAdmin?: boolean;
}): JSX.Element => {
  const [isReportExpanded, setIsReportExpanded] = useState(true);
  const { t } = useTranslation();
  const renterDetails = useSelector(selectedRenterDetails);
  const renterFlowRenterDetails = useSelector(selectRenterProfileInformation);
  const renterFlowPropertyInformation = useSelector(applicationProperties);
  const agentPropertyInformation = useSelector(selectCurrentProperty);
  const creditProfile = useSelector(isRenterFlow ? selectRenterCreditProfileInformation : selectRenterCreditProfile);
  const criminalReport = useSelector(isRenterFlow ? selectRenterCriminalReportInformation : selectRenterCriminalReport);
  const criminalResponse = criminalReport?.response?.XMLCRIM;
  let candidatelOffenses = criminalResponse?.CICcriminal?.Candidates?.Candidate?.Offenses?.Offense;
  const candidateOrderResponse = criminalResponse?.OrderResponse;
  const canidateSubject = criminalResponse?.CICcriminal?.Candidates?.Candidate?.Subject;
  const errorCode = criminalReport.error?.Code || '';
  const errorMessage = criminalReport.error?.Message || '';
  const shouldShowError = (isReportExpanded && !criminalReport.success) || (isSuperAdmin && !criminalReport.success);

  if (candidatelOffenses !== null && candidatelOffenses !== undefined && !Array.isArray(candidatelOffenses)) {
    candidatelOffenses = [candidatelOffenses];
  }

  if (criminalReport.success === null) {
    return <Spinner />;
  }

  return (
    <div className={styles.Container}>
      {!isRenterFlow && (
        <div className={styles.IconContainer}>
          <ArrowIcon
            onClick={() => setIsReportExpanded(!isReportExpanded)}
            className={classNames(styles.ArrowIcon, { [styles.ArrowIconExpanded]: isReportExpanded })}
          />
          <div className={styles.Heading}>{t('agent.creditReport.criminalRecords')}</div>
        </div>
      )}
      {shouldShowError && (
        <div className={styles.ErrorContainer}>
          <ReportStatusLoader errorCode={errorCode} errorMessage={errorMessage} errorType={ReportErrorTypes.ERROR} />
        </div>
      )}
      {isReportExpanded &&
        criminalReport.success &&
        Number(criminalReport?.response?.XMLCRIM?.CICcriminal?.Candidates?.count) > 0 && (
          <div className={styles.ExpandedInformation}>
            <ReportHeading
              heading={t('agent.creditReport.offenses')}
              hasCounter={!!candidatelOffenses?.length}
              counter={candidatelOffenses?.length ?? 0}
            />
            {!isEmpty(candidatelOffenses) ? (
              candidatelOffenses?.map((offense, index) => (
                <CandidateOffenseReport
                  key={`candidate-offenses-${offense}-${index}`}
                  criminalOffense={offense}
                  orderResponse={candidateOrderResponse}
                  isRenterFlow={isRenterFlow}
                />
              ))
            ) : (
              <EmptyReportRecord />
            )}
            <div className={styles.Criminal}>
              <ReportHeading
                heading={t('agent.creditReport.subject')}
                hasCounter={!isEmpty(canidateSubject)}
                counter={isEmpty(canidateSubject) ? 0 : 1}
              />
              {canidateSubject ? <CriminalSubjectReport criminalSubject={canidateSubject} /> : <EmptyReportRecord />}
            </div>
          </div>
        )}

      {isReportExpanded &&
        !Number(criminalReport?.response?.XMLCRIM?.CICcriminal?.Candidates?.count) &&
        criminalReport.success && (
          <EmptyBackgroundReport
            reportDate={creditProfile?.report?.Header?.ReportDate ?? '____'}
            reportId={criminalReport?.response?.XMLCRIM?.OrderResponse?.ResponseID ?? '____'}
            fullName={
              `${creditProfile?.report?.ConsumerIdentity?.[0]?.Name?.First} ${creditProfile?.report?.ConsumerIdentity?.[0]?.Name?.Surname}` ??
              '____'
            }
            applyingZip={
              isRenterFlow ? renterFlowPropertyInformation?.zipCode || '' : agentPropertyInformation?.zipCode || ''
            }
            dateOfBirth={
              isRenterFlow
                ? formatDateToApplicantDOBFormat(renterFlowRenterDetails.birthDate)
                : formatDateToApplicantDOBFormat(renterDetails?.birthDate) ?? '____'
            }
            page={BackgroundDataTypes.CRIMINAL}
          />
        )}
    </div>
  );
};
