import { Dispatch, SetStateAction, useState } from 'react';
import { Modal as BModal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as Security2FA } from 'assets/svgs/Security2FA.svg';
import AgentModal from 'components/Agent/components/AgentModal/AgentModal';
import { StepsOptions2FA } from 'constants/agentConstants';

import { AddPhoneNumber } from './components/AddPhoneNumber';
import { ConfirmOtp } from './components/ConfirmOtp';
import { ConfirmPassword } from './components/ConfirmPassword';
import { TwoFactorFailure } from './components/TwoFactorFailure';
import { TwoFactorSuccess } from './components/TwoFactorSuccess';

import styles from './Modal2FA.module.scss';

type Props = {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
};

export const Modal2FA = ({ setIsModalOpen }: Props): JSX.Element => {
  const [modalSteps, setModalSteps] = useState(StepsOptions2FA.PASSWORD);
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const renderStep = (): JSX.Element => {
    switch (modalSteps) {
      case StepsOptions2FA.PASSWORD:
        return <ConfirmPassword setIsModalOpen={setIsModalOpen} setModalSteps={setModalSteps} />;
      case StepsOptions2FA.NUMBER:
        return (
          <AddPhoneNumber
            setIsModalOpen={setIsModalOpen}
            setModalSteps={setModalSteps}
            setPhoneNumber={setPhoneNumber}
          />
        );
      case StepsOptions2FA.OTP:
        return <ConfirmOtp setModalSteps={setModalSteps} setIsModalOpen={setIsModalOpen} phoneNumber={phoneNumber} />;
      case StepsOptions2FA.SUCCESS:
        return <TwoFactorSuccess setIsModalOpen={setIsModalOpen} />;
      case StepsOptions2FA.FAILURE:
        return <TwoFactorFailure setIsModalOpen={setIsModalOpen} />;
      default:
        return <div></div>;
    }
  };

  return (
    <AgentModal
      show
      onHide={() => setIsModalOpen(false)}
      customTitleClassName={styles.modalTitle}
      isCrossIconVisible={false}
      customModalClassName={styles.Outter2FAContainer}
    >
      <BModal.Body className={styles.Modal2FAContainer}>
        <div>
          <div
            className={classNames(styles.ModalHeaderText, {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'd-none': modalSteps === StepsOptions2FA.FAILURE,
            })}
          >
            <Security2FA />
            <p className={styles.FontStyle1}>
              {t('otpModal.2FAby')} <b>{t('otpModal.2FAIntellirent')}</b>
            </p>
          </div>
          {renderStep()}
        </div>
      </BModal.Body>
    </AgentModal>
  );
};
