import { add, differenceInCalendarYears, format, isAfter, isBefore, isToday, isValid, parseISO } from 'date-fns';
import { t } from 'i18next';
import * as Yup from 'yup';

import { dateFormatBackend, dateFormatFrontend } from 'constants/calendarConstants';
import {
  animalRegex,
  cardNameRegex,
  cityRegex,
  emailRegex,
  fullNameRegex,
  lisencePlateRegex,
  modelRegex,
  nameRegex,
  onlyUpperLowerCase,
  phoneRegex,
  reasonForMovingRegex,
  stateRegex,
  zipRegex,
} from 'constants/regexConstant';
import {
  addressClassificationOptions,
  BUSINESS_NAME_MAX_CHAR,
  BUSINESS_NAME_MIN_CHAR,
  DESCRIPTION_MAX_LENGTH,
  DESCRIPTION_MIN_LENGTH,
  EMPLOYMENT_TITLE_MAX_LENGTH,
  FurryFriendTypes,
  LICENCE_MAX_LENGTH,
  MAX_RENT_AMOUNT,
  MIN_RENT_AMOUNT,
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
  OTHER_INDEX_IN_ADDRESS_CLASSIFICATION_ARRAY,
  TEXT_MAX_LENGTH,
} from 'constants/renterConstants';
import { isValidSSN } from 'helpers/helper';
import { SSN_LENGTH } from 'shared/constants';
import { EmploymentVerifier } from 'shared/types/renterTypes';
import { MAX_OLD_CAR_YEAR } from 'shared/types/renterVehicleInformation';
// this any warning is to be removed after dicussion
// eslint-disable-next-line @typescript-eslint/no-explicit-any

const basicInfoSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First name is required')
    .matches(nameRegex, `${t('renter.nameError.invalidFormat')}`)
    .min(NAME_MIN_LENGTH, `${t('renter.nameError.minLength')}`)
    .max(NAME_MAX_LENGTH, `${t('renter.nameError.maxLength')}`)
    .nullable(),
  lastName: Yup.string()
    .required('Last name is required')
    .matches(nameRegex, `${t('renter.nameError.invalidFormat')}`)
    .min(NAME_MIN_LENGTH, `${t('renter.nameError.minLength')}`)
    .max(NAME_MAX_LENGTH, `${t('renter.nameError.maxLength')}`)
    .nullable(),
  middleName: Yup.string()
    .matches(nameRegex, `${t('renter.nameError.invalidFormat')}`)
    .min(NAME_MIN_LENGTH, `${t('renter.nameError.minLength')}`)
    .max(NAME_MAX_LENGTH, `${t('renter.nameError.maxLength')}`)
    .nullable(),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renterProfileSchema: { [key: string]: Yup.ObjectSchema<any> } = {
  basicInfo: basicInfoSchema,
  profileSummary: basicInfoSchema,
  SSNInput: Yup.object().shape({
    ssnTailDigits: Yup.string()
      .required('SSN is required')
      .length(SSN_LENGTH, `SSN must be exactly ${SSN_LENGTH} digits`)
      .test('is-valid-ssn', 'Please provide a valid SSN.', (value) => isValidSSN(value || ''))
      .nullable(),
  }),
  manuallyAddInformation: Yup.object().shape({
    monthlyGross: Yup.number().typeError('Gross income must be a number').nullable(),
  }),
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renterPresentEmploymentsSchema: { [key: string]: Yup.ObjectSchema<any> } = {
  other: Yup.object().shape({
    monthlyGrossIncome: Yup.number()
      .required(`${t('renter.employmentSchema.monthlyGross')}`)
      .typeError('Please enter valid amount')
      .min(1, `${t('renter.descriptionError.monthlyGrossIncome')}`)
      .nullable(),
    jobDescription: Yup.string()
      .required(`${t('renter.employmentSchema.jobDescription')}`)
      .min(DESCRIPTION_MIN_LENGTH, `${t('renter.descriptionError.lengthError')}`)
      .max(DESCRIPTION_MAX_LENGTH, `${t('renter.descriptionError.lengthError')}`)
      .nullable(),
    status: Yup.string()
      .required(`${t('renter.employmentSchema.jobType')}`)
      .oneOf(['student', 'retired', 'homemaker'], `${t('renter.employmentSchema.jobType')}`),
  }),
  selfEmployed: Yup.object().shape({
    title: Yup.string()
      .required(`${t('renter.employmentSchema.jobTitle')}`)
      .matches(nameRegex, `${t('renter.nameError.invalidFormat')}`)
      .min(NAME_MIN_LENGTH, `${t('renter.nameError.minLength')}`)
      .max(EMPLOYMENT_TITLE_MAX_LENGTH, `${t('renter.titleError.lessThan50.maxLength')}`),
    businessName: Yup.string()
      .required(`${t('employment.presentSourceOfIncome.businessNameIsRequired')}`)
      .min(BUSINESS_NAME_MIN_CHAR, `${t('renter.descriptionError.lengthError')}`)
      .max(BUSINESS_NAME_MAX_CHAR, `${t('renter.descriptionError.lengthError')}`),
    jobDescription: Yup.string()
      .required(`${t('renter.employmentSchema.jobDescription')}`)
      .min(DESCRIPTION_MIN_LENGTH, `${t('renter.descriptionError.lengthError')}`)
      .max(DESCRIPTION_MAX_LENGTH, `${t('renter.descriptionError.lengthError')}`)
      .nullable(),
    monthlyGrossIncome: Yup.number()
      .required(`${t('renter.employmentSchema.monthlyGross')}`)
      .typeError(`${t('renter.employmentSchema.monthlyGross2')}`)
      .min(1, `${t('renter.descriptionError.monthlyGrossIncome')}`)
      .nullable(),
    jobStartDate: Yup.string()
      .required(`${t('renter.employmentSchema.startDate1')}`)
      .test('jobStartDate', `${t('renter.employmentSchema.startDate2')}`, (date) => {
        if (!date) {
          return true;
        }

        return isValid(new Date(date));
      })
      .test('jobStartDate', `${t('renter.employmentSchema.startDate4')}`, (date) => {
        if (!date) {
          return true;
        }

        return isBefore(new Date(date), new Date());
      }),
  }),
  employed: Yup.object().shape({
    company: Yup.string()
      .required(`${t('renter.employmentSchema.company')}`)
      .min(NAME_MIN_LENGTH, `${t('renter.nameError.minLength')}`)
      .max(TEXT_MAX_LENGTH, `${t('renter.nameError.maxLength')}`),
    title: Yup.string()
      .required(`${t('renter.employmentSchema.jobTitle')}`)
      .matches(nameRegex, `${t('renter.nameError.invalidFormat')}`)
      .min(NAME_MIN_LENGTH, `${t('renter.nameError.minLength')}`)
      .max(EMPLOYMENT_TITLE_MAX_LENGTH, `${t('renter.titleError.lessThan50.maxLength')}`),
    jobStartDate: Yup.string()
      .required(`${t('renter.employmentSchema.startDate1')}`)
      .test('jobStartDate', `${t('renter.employmentSchema.startDate2')}`, (date) => {
        if (!date) {
          return true;
        }

        return isValid(new Date(date));
      })
      .test('jobStartDate', `${t('renter.employmentSchema.startDate4')}`, (date) => {
        if (!date) {
          return true;
        }

        return isBefore(new Date(date), new Date());
      }),
    monthlyGrossIncome: Yup.number()
      .required(`${t('renter.employmentSchema.monthlyGross')}`)
      .typeError(`${t('renter.employmentSchema.monthlyGross2')}`)
      .min(1, `${t('renter.descriptionError.monthlyGrossIncome')}`)
      .nullable(),
    fullName: Yup.string()
      .when('presentEmploymentVerifiers', {
        is: (presentEmploymentVerifiers: EmploymentVerifier[]) =>
          !presentEmploymentVerifiers || presentEmploymentVerifiers.length === 0,
        then: Yup.string().required(`${t('renter.employmentSchema.supervisor')}`),
        otherwise: Yup.string(),
      })
      // .required(`${t('renter.employmentSchema.supervisor')}`)
      .matches(nameRegex, `${t('renter.nameError.invalidFormat')}`)
      .min(NAME_MIN_LENGTH, `${t('renter.nameError.minLength')}`)
      .max(NAME_MAX_LENGTH, `${t('renter.nameError.maxLength')}`),
    emailAddress: Yup.string()
      .email(`${t('renter.employmentSchema.email1')}`)
      .when('presentEmploymentVerifiers', {
        is: (presentEmploymentVerifiers: EmploymentVerifier[]) =>
          !presentEmploymentVerifiers || presentEmploymentVerifiers.length === 0,
        then: Yup.string().required(`${t('renter.employmentSchema.email2')}`),
        otherwise: Yup.string(),
      }),
    // .required(`${t('renter.employmentSchema.email2')}`),
  }),
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renterPriorEmploymentsSchema: { [key: string]: Yup.ObjectSchema<any> } = {
  selfEmployed: Yup.object().shape({
    title: Yup.string()
      .required(t('renter.employmentSchema.jobTitle'))
      .matches(nameRegex, `${t('renter.nameError.invalidFormat')}`)
      .min(NAME_MIN_LENGTH, `${t('renter.nameError.minLength')}`)
      .max(EMPLOYMENT_TITLE_MAX_LENGTH, `${t('renter.titleError.lessThan50.maxLength')}`),
    jobDescription: Yup.string()
      .required(t('renter.employmentSchema.jobDescription'))
      .min(DESCRIPTION_MIN_LENGTH, `${t('renter.descriptionError.lengthError')}`)
      .max(DESCRIPTION_MAX_LENGTH, `${t('renter.descriptionError.lengthError')}`)
      .nullable(),
    monthlyGrossIncome: Yup.number()
      .required(t('renter.employmentSchema.monthlyGross'))
      .typeError('Please enter valid amount')
      .min(1, `${t('renter.descriptionError.monthlyGrossIncome')}`)
      .nullable(),
    jobStartDate: Yup.string()
      .required(`${t('renter.employmentSchema.startDate1')}`)
      .test('jobStartDate', `${t('renter.employmentSchema.startDate2')}`, (date) => {
        if (!date) {
          return true;
        }

        return isValid(new Date(date));
      })
      .test('jobStartDate', `${t('renter.employmentSchema.startDate3')}`, (date) => {
        if (!date) {
          return true;
        }

        return (
          isValid(new Date(parseISO(date))) &&
          (date === format(new Date(parseISO(date)) ? new Date(parseISO(date)) : new Date(), dateFormatFrontend) ||
            date === format(new Date(parseISO(date)) ? new Date(parseISO(date)) : new Date(), dateFormatBackend))
        );
      })
      .test('jobStartDate', `${t('renter.employmentSchema.startDate4')}`, (date) => {
        if (!date) {
          return true;
        }

        return new Date(parseISO(date)).getTime() <= new Date().getTime();
      })
      .test('jobStartDate', t('renter.addressSchema.shouldBeLessThanToday'), (date) => {
        if (!date) {
          return true;
        }

        return (
          new Date() > new Date(parseISO(date)) &&
          format(new Date(), dateFormatFrontend) !== format(new Date(parseISO(date)), dateFormatFrontend)
        );
      }),
    jobEndDate: Yup.string()
      .required(`${t('renter.employmentSchema.endDate1')}`)
      .test('jobEndDate', `${t('renter.employmentSchema.startDate2')}`, (date) => {
        if (!date) {
          return true;
        }

        return isValid(new Date(date));
      })
      .test('jobEndDate', `${t('renter.employmentSchema.endDate2')}`, (date) => {
        if (!date) {
          return true;
        }

        return (
          isValid(new Date(parseISO(date))) &&
          (date === format(new Date(parseISO(date)) ? new Date(parseISO(date)) : new Date(), dateFormatFrontend) ||
            date === format(new Date(parseISO(date)) ? new Date(parseISO(date)) : new Date(), dateFormatBackend))
        );
      })
      .test('jobEndDate', `${t('renter.employmentSchema.startDate4')}`, (date) => {
        if (!date) {
          return true;
        }

        return new Date(parseISO(date)).getTime() <= new Date().getTime();
      })
      .test('jobStartDate', t('renter.addressSchema.shouldBeLessThanToday'), (date) => {
        if (!date) {
          return true;
        }

        return (
          new Date() > new Date(parseISO(date)) &&
          format(new Date(), dateFormatFrontend) !== format(new Date(parseISO(date)), dateFormatFrontend)
        );
      }),
  }),
  employed: Yup.object().shape({
    company: Yup.string()
      .required(t('renter.employmentSchema.company'))
      .min(NAME_MIN_LENGTH, `${t('renter.nameError.minLength')}`)
      .max(TEXT_MAX_LENGTH, `${t('renter.nameError.maxLength')}`),
    title: Yup.string()
      .required(t('renter.employmentSchema.jobTitle'))
      .matches(nameRegex, `${t('renter.nameError.invalidFormat')}`)
      .min(NAME_MIN_LENGTH, `${t('renter.nameError.minLength')}`)
      .max(EMPLOYMENT_TITLE_MAX_LENGTH, `${t('renter.titleError.lessThan50.maxLength')}`),
    jobStartDate: Yup.string()
      .required(`${t('renter.employmentSchema.startDate1')}`)
      .test('jobStartDate', `${t('renter.employmentSchema.startDate2')}`, (date) => {
        if (!date) {
          return true;
        }

        return isValid(new Date(date));
      })
      .test('jobStartDate', `${t('renter.employmentSchema.startDate3')}`, (date) => {
        if (!date) {
          return true;
        }

        return (
          isValid(new Date(parseISO(date))) &&
          (date === format(new Date(parseISO(date)) ? new Date(parseISO(date)) : new Date(), dateFormatFrontend) ||
            date === format(new Date(parseISO(date)) ? new Date(parseISO(date)) : new Date(), dateFormatBackend))
        );
      })
      .test('jobStartDate', `${t('renter.employmentSchema.startDate4')}`, (date) => {
        if (!date) {
          return true;
        }

        return new Date(parseISO(date)).getTime() <= new Date().getTime();
      })
      .test('jobStartDate', t('renter.addressSchema.shouldBeLessThanToday'), (date) => {
        if (!date) {
          return true;
        }

        return (
          new Date() > new Date(parseISO(date)) &&
          format(new Date(), dateFormatFrontend) !== format(new Date(parseISO(date)), dateFormatFrontend)
        );
      })
      .test('jobStartDate', t('renter.addressSchema.shouldBeLessThanToday'), (date) => {
        if (!date) {
          return true;
        }

        return (
          new Date() > new Date(parseISO(date)) &&
          format(new Date(), dateFormatFrontend) !== format(new Date(parseISO(date)), dateFormatFrontend)
        );
      }),
    jobEndDate: Yup.string()
      .required(`${t('renter.employmentSchema.startDate1')}`)
      .test('jobStartDate', `${t('renter.employmentSchema.startDate2')}`, (date) => {
        if (!date) {
          return true;
        }

        return isValid(new Date(date));
      })
      .test('jobStartDate', `${t('renter.employmentSchema.startDate3')}`, (date) => {
        if (!date) {
          return true;
        }

        return (
          isValid(new Date(parseISO(date))) &&
          (date === format(new Date(parseISO(date)) ? new Date(parseISO(date)) : new Date(), dateFormatFrontend) ||
            date === format(new Date(parseISO(date)) ? new Date(parseISO(date)) : new Date(), dateFormatBackend))
        );
      })
      .test('jobStartDate', `${t('renter.employmentSchema.startDate4')}`, (date) => {
        if (!date) {
          return true;
        }

        return new Date(parseISO(date)).getTime() <= new Date().getTime();
      })
      .test('jobStartDate', t('renter.addressSchema.shouldBeLessThanToday'), (date) => {
        if (!date) {
          return true;
        }

        return (
          new Date() > new Date(parseISO(date)) &&
          format(new Date(), dateFormatFrontend) !== format(new Date(parseISO(date)), dateFormatFrontend)
        );
      })
      .test('jobStartDate', t('renter.addressSchema.shouldBeLessThanToday'), (date) => {
        if (!date) {
          return true;
        }

        return (
          new Date() > new Date(parseISO(date)) &&
          format(new Date(), dateFormatFrontend) !== format(new Date(parseISO(date)), dateFormatFrontend)
        );
      }),
    monthlyGrossIncome: Yup.number()
      .required(t('renter.employmentSchema.monthlyGross'))
      .typeError('Please enter valid amount')
      .min(1, `${t('renter.descriptionError.monthlyGrossIncome')}`)
      .nullable(),
  }),
};
export const renterAdultSchema = Yup.object().shape({
  relationship: Yup.string().required(t('renter.relationship.relationshipValidation')),
  phoneNumber: Yup.string().required(t('renter.relationship.phoneNumberValidation')),
  emailAddress: Yup.string()
    .required(t('renter.relationship.emailValidation'))
    .matches(emailRegex, t('renter.relationship.emailGuidlineValidation')),
});
export const renterMinorSchema = Yup.object().shape({
  fullName: Yup.string()
    .required(t('renter.relationship.fullNameValidation'))
    .matches(fullNameRegex, "Full Name must match ^[A-Za-z' -]+$"),
  relationship: Yup.string().required(t('renter.relationship.relationshipValidation')),
  birthDate: Yup.string()
    .required(t('renter.relationship.dateValidation'))
    .test('dateAvailable', t('renter.addressSchema.shouldBeValidDate'), (date) => {
      if (!date) {
        return true;
      }

      return isValid(new Date(date));
    })
    .test('birthDate', t('renter.addressSchema.shouldBeLessThanToday'), (date) => {
      if (!date) {
        return true;
      }

      return isBefore(new Date(date), new Date());
    }),
});

export const AddressFormSchema = [
  // yup step 0
  Yup.object().shape({
    zipCode: Yup.string()
      .required(t('renter.addressSchema.zipCodeIsRequired'))
      .matches(zipRegex, t('renter.addressSchema.zipCodeError'))
      .nullable(),
    streetAddress1: Yup.string()
      .required(t('renter.addressSchema.streetAddressIsRequired'))
      .min(2, t('renter.addressSchema.mustHaveAtleast2'))
      .max(100, t('renter.addressSchema.cantHaveMoreThan100'))
      .nullable(),
    state: Yup.string()
      .required(t('renter.addressSchema.stateIsRequired'))
      .matches(stateRegex, t('renter.addressSchema.invalidFormatForState'))
      .nullable(),
    city: Yup.string()
      .required(t('renter.addressSchema.cityIsRequired'))
      .min(3, t('renter.addressSchema.mustBeAtleast3Characters'))
      .matches(cityRegex, t('renter.addressSchema.invalidFormatForCity'))
      .nullable(),
    moveInDate: Yup.string()
      .required(t('renter.addressSchema.fromDateIsRequired'))
      .test('dateAvailable', t('renter.addressSchema.shouldBeValidDate'), (date) => {
        if (!date) {
          return true;
        }

        return isValid(new Date(date));
      })
      .test('dateAvailable', t('renter.addressSchema.shouldBeLessThanToday'), (date) => {
        if (!date) {
          return true;
        }

        return isBefore(new Date(date), new Date());
      })
      .nullable(),
    rent: Yup.number()

      .typeError(t('renter.addressSchema.rentMustBeNumber'))
      .min(MIN_RENT_AMOUNT, t('renter.addressSchema.rentMustBeGreaterThan0'))
      .max(MAX_RENT_AMOUNT, t('renter.addressSchema.rentShouldBeLess'))
      .nullable(),
    reasonForMoving: Yup.string()
      .required('Reason for moving is required.')
      .min(5, 'should have atleast 5 characters')
      .max(50, 'should have maximum of 50 characters')
      .matches(reasonForMovingRegex, t('address.verifierEditModal.shouldBeValidReason'))
      .nullable(),
    classification: Yup.string().required('Classification is required.').nullable(),
    description: Yup.string().when('classification', {
      is: (classification: string) =>
        classification === addressClassificationOptions[OTHER_INDEX_IN_ADDRESS_CLASSIFICATION_ARRAY].label,
      then: Yup.string().required('Description is required').nullable(),
      otherwise: Yup.string().nullable(),
    }),
    fullName: Yup.string().nullable(),
    contactRent: Yup.number()

      .typeError(t('renter.addressSchema.rentMustBeNumber'))
      .min(MIN_RENT_AMOUNT, t('renter.addressSchema.rentMustBeGreaterThan0'))
      .max(MAX_RENT_AMOUNT, t('renter.addressSchema.rentShouldBeLess'))
      .nullable(),
    phoneNumber: Yup.string()

      .matches(phoneRegex, t('renter.addressSchema.mustContain10Digits'))
      .nullable(),
    emailAddress: Yup.string()

      .matches(emailRegex, t('renter.addressSchema.mustBeAValidEmail'))
      .nullable(),
    renterAddress: Yup.object().shape({
      reasonForMoving: Yup.string()
        .required('Reason for moving is required.')
        .min(5, 'should have atleast 5 characters')
        .max(50, 'should have maximum of 50 characters')
        .matches(reasonForMovingRegex, t('address.verifierEditModal.shouldBeValidReason'))

        .nullable(),
      classification: Yup.string().required('Classification is required.').nullable(),
      description: Yup.string().when('classification', {
        is: (classification: string) =>
          classification === addressClassificationOptions[OTHER_INDEX_IN_ADDRESS_CLASSIFICATION_ARRAY].label,
        then: Yup.string().required('Description is required').nullable(),
        otherwise: Yup.string().nullable(),
      }),
      moveInDate: Yup.string()
        .required(t('renter.addressSchema.fromDateIsRequired'))
        .test('dateAvailable', t('renter.addressSchema.shouldBeValidDate'), (date) => {
          if (!date) {
            return true;
          }

          return isValid(new Date(date));
        })
        .test('dateAvailable', t('renter.addressSchema.shouldBeLessThanToday'), (date) => {
          if (!date) {
            return true;
          }

          return isBefore(new Date(date), new Date());
        })
        .nullable(),
      fullName: Yup.string().nullable(),
      contactRent: Yup.number()

        .typeError(t('renter.addressSchema.rentMustBeNumber'))
        .min(MIN_RENT_AMOUNT, t('renter.addressSchema.rentMustBeGreaterThan0'))
        .max(MAX_RENT_AMOUNT, t('renter.addressSchema.rentShouldBeLess'))
        .nullable(),
      phoneNumber: Yup.string()

        .matches(phoneRegex, t('renter.addressSchema.mustContain10Digits'))
        .nullable(),
      emailAddress: Yup.string()

        .matches(emailRegex, t('renter.addressSchema.mustBeAValidEmail'))
        .nullable(),
    }),
  }),

  //yup step 1
  Yup.object().shape({
    zipCode: Yup.string()
      .required(t('renter.addressSchema.zipCodeIsRequired'))
      .matches(zipRegex, t('renter.addressSchema.zipCodeError'))
      .nullable(),
    streetAddress1: Yup.string()
      .required(t('renter.addressSchema.streetAddressIsRequired'))
      .min(2, t('renter.addressSchema.mustHaveAtleast2'))
      .max(100, t('renter.addressSchema.cantHaveMoreThan100'))
      .nullable(),
    state: Yup.string()
      .required(t('renter.addressSchema.stateIsRequired'))
      .matches(stateRegex, t('renter.addressSchema.invalidFormatForState'))
      .nullable(),
    city: Yup.string()
      .required(t('renter.addressSchema.cityIsRequired'))
      .min(3, t('renter.addressSchema.mustBeAtleast3Characters'))
      .matches(cityRegex, t('renter.addressSchema.invalidFormatForCity'))
      .nullable(),
    fromDate: Yup.string()
      .required(t('renter.addressSchema.fromDateIsRequired'))
      .test('dateAvailable', t('renter.addressSchema.shouldBeValidDate'), (date) => {
        if (!date) {
          return true;
        }

        return isValid(new Date(date));
      })
      .test('dateAvailable', t('renter.addressSchema.shouldBeLessThanToday'), (date) => {
        if (!date) {
          return true;
        }

        return isBefore(new Date(date), new Date());
      })
      .nullable(),
    toDate: Yup.string()
      .required(t('renter.addressSchema.toDateIsRequired'))

      .test('dateAvailable', t('renter.addressSchema.shouldBeValidDate'), (date) => {
        if (!date) {
          return true;
        }

        return isValid(new Date(date));
      })
      .test('dateAvailable', t('renter.addressSchema.shouldBeLessThanToday'), (date) => {
        if (!date) {
          return true;
        }

        return isBefore(new Date(date), new Date());
      })
      .test('dateComparison', t('renter.addressSchema.toDateShouldBeGreater'), function (date) {
        const fromDate = this.parent.fromDate;

        if (!date || !fromDate) {
          return true;
        }

        return isAfter(new Date(date), new Date(fromDate));
      })
      .nullable(),
    rent: Yup.number()

      .typeError(t('renter.addressSchema.rentMustBeNumber'))
      .min(MIN_RENT_AMOUNT, t('renter.addressSchema.rentMustBeGreaterThan0'))
      .max(MAX_RENT_AMOUNT, t('renter.addressSchema.rentShouldBeLess'))
      .nullable(),
    classification: Yup.string().required('Classification is required.').nullable(),
    description: Yup.string().when('classification', {
      is: (classification: string) =>
        classification === addressClassificationOptions[OTHER_INDEX_IN_ADDRESS_CLASSIFICATION_ARRAY].label,
      then: Yup.string().required('Description is required').nullable(),
      otherwise: Yup.string().nullable(),
    }),
    fullName: Yup.string().nullable(),
    contactRent: Yup.number()

      .typeError(t('renter.addressSchema.rentMustBeNumber'))
      .min(MIN_RENT_AMOUNT, t('renter.addressSchema.rentMustBeGreaterThan0'))
      .max(MAX_RENT_AMOUNT, t('renter.addressSchema.rentShouldBeLess'))
      .nullable(),
    phoneNumber: Yup.string()

      .matches(phoneRegex, t('renter.addressSchema.mustContain10Digits'))
      .nullable(),
    emailAddress: Yup.string()

      .matches(emailRegex, t('renter.addressSchema.mustBeAValidEmail'))
      .nullable(),
    renterAddress: Yup.object().shape({
      classification: Yup.string().required('Classification is required.').nullable(),
      description: Yup.string().when('classification', {
        is: (classification: string) =>
          classification === addressClassificationOptions[OTHER_INDEX_IN_ADDRESS_CLASSIFICATION_ARRAY].label,
        then: Yup.string().required('Description is required').nullable(),
        otherwise: Yup.string().nullable(),
      }),
      fromDate: Yup.string()
        .required(t('renter.addressSchema.fromDateIsRequired'))
        .test('dateAvailable', t('renter.addressSchema.shouldBeValidDate'), (date) => {
          if (!date) {
            return true;
          }

          return isValid(new Date(date));
        })
        .test('dateAvailable', t('renter.addressSchema.shouldBeLessThanToday'), (date) => {
          if (!date) {
            return true;
          }

          return isBefore(new Date(date), new Date());
        })
        .nullable(),
      toDate: Yup.string()
        .required(t('renter.addressSchema.toDateIsRequired'))

        .test('dateAvailable', t('renter.addressSchema.shouldBeValidDate'), (date) => {
          if (!date) {
            return true;
          }

          return isValid(new Date(date));
        })
        .test('dateAvailable', t('renter.addressSchema.shouldBeLessThanToday'), (date) => {
          if (!date) {
            return true;
          }

          return isBefore(new Date(date), new Date());
        })
        .test('dateComparison', t('renter.addressSchema.toDateShouldBeGreater'), function (date) {
          const fromDate = this.parent.fromDate;

          if (!date || !fromDate) {
            return true;
          }

          return isAfter(new Date(date), new Date(fromDate));
        })
        .nullable(),
      fullName: Yup.string().nullable(),
      contactRent: Yup.number()

        .typeError(t('renter.addressSchema.rentMustBeNumber'))
        .min(MIN_RENT_AMOUNT, t('renter.addressSchema.rentMustBeGreaterThan0'))
        .max(MAX_RENT_AMOUNT, t('renter.addressSchema.rentShouldBeLess'))
        .nullable(),
      phoneNumber: Yup.string()

        .matches(phoneRegex, t('renter.addressSchema.mustContain10Digits'))
        .nullable(),
      emailAddress: Yup.string()

        .matches(emailRegex, t('renter.addressSchema.mustBeAValidEmail'))
        .nullable(),
    }),
  }),

  //yup step 2
  Yup.object().shape({
    rent: Yup.number()

      .typeError(t('renter.addressSchema.rentMustBeNumber'))
      .min(MIN_RENT_AMOUNT, t('renter.addressSchema.rentMustBeGreaterThan0'))
      .max(MAX_RENT_AMOUNT, t('renter.addressSchema.rentShouldBeLess'))
      .nullable(),
    zipCode: Yup.string()
      .required(t('renter.addressSchema.zipCodeIsRequired'))
      .matches(zipRegex, t('renter.addressSchema.zipCodeError'))
      .nullable(),
    streetAddress1: Yup.string()
      .required(t('renter.addressSchema.streetAddressIsRequired'))
      .min(2, t('renter.addressSchema.mustHaveAtleast2'))
      .max(100, t('renter.addressSchema.cantHaveMoreThan100'))
      .nullable(),
    state: Yup.string()
      .required(t('renter.addressSchema.stateIsRequired'))
      .matches(stateRegex, t('renter.addressSchema.invalidFormatForState'))
      .nullable(),
    city: Yup.string()
      .required(t('renter.addressSchema.cityIsRequired'))
      .min(3, t('renter.addressSchema.mustBeAtleast3Characters'))
      .matches(cityRegex, t('renter.addressSchema.invalidFormatForCity'))
      .nullable(),
    fromDate: Yup.string()
      .required(t('renter.addressSchema.fromDateIsRequired'))
      .test('dateAvailable', t('renter.addressSchema.shouldBeValidDate'), (date) => {
        if (!date) {
          return true;
        }

        return isValid(new Date(date));
      })
      .test('dateAvailable', t('renter.addressSchema.shouldBeLessThanToday'), (date) => {
        if (!date) {
          return true;
        }

        return isBefore(new Date(date), new Date());
      })
      .nullable(),
    toDate: Yup.string()
      .required(t('renter.addressSchema.toDateIsRequired'))

      .test('dateAvailable', t('renter.addressSchema.shouldBeValidDate'), (date) => {
        if (!date) {
          return true;
        }

        return isValid(new Date(date));
      })
      .test('dateAvailable', t('renter.addressSchema.shouldBeLessThanToday'), (date) => {
        if (!date) {
          return true;
        }

        return isBefore(new Date(date), new Date());
      })
      .test('dateComparison', t('renter.addressSchema.toDateShouldBeGreater'), function (date) {
        const fromDate = this.parent.fromDate;

        if (!date || !fromDate) {
          return true;
        }

        return isAfter(new Date(date), new Date(fromDate));
      })
      .nullable(),
    reasonForMoving: Yup.string()
      .required('Reason for moving is required.')
      .min(5, 'should have atleast 5 characters')
      .max(50, 'should have maximum of 50 characters')
      .matches(reasonForMovingRegex, t('address.verifierEditModal.shouldBeValidReason'))

      .nullable(),
    classification: Yup.string().required('Classification is required.').nullable(),
    description: Yup.string().when('classification', {
      is: (classification: string) =>
        classification === addressClassificationOptions[OTHER_INDEX_IN_ADDRESS_CLASSIFICATION_ARRAY].label,
      then: Yup.string().required('Description is required').nullable(),
      otherwise: Yup.string().nullable(),
    }),
    fullName: Yup.string().nullable(),
    contactRent: Yup.number()

      .typeError(t('renter.addressSchema.rentMustBeNumber'))
      .min(MIN_RENT_AMOUNT, t('renter.addressSchema.rentMustBeGreaterThan0'))
      .max(MAX_RENT_AMOUNT, t('renter.addressSchema.rentShouldBeLess'))
      .nullable(),
    phoneNumber: Yup.string()

      .matches(phoneRegex, t('renter.addressSchema.mustContain10Digits'))
      .nullable(),
    emailAddress: Yup.string()

      .matches(emailRegex, t('renter.addressSchema.mustBeAValidEmail'))
      .nullable(),
    moveInDate: Yup.string()
      .required(t('renter.addressSchema.fromDateIsRequired'))
      .test('dateAvailable', t('renter.addressSchema.shouldBeValidDate'), (date) => {
        if (!date) {
          return true;
        }

        return isValid(new Date(date));
      })
      .test('dateAvailable', t('renter.addressSchema.shouldBeLessThanToday'), (date) => {
        if (!date) {
          return true;
        }

        return isBefore(new Date(date), new Date());
      })
      .nullable(),
    renterAddress: Yup.object().shape({
      reasonForMoving: Yup.string()
        .required('Reason for moving is required.')
        .min(5, 'should have atleast 5 characters')
        .max(50, 'should have maximum of 50 characters')
        .matches(reasonForMovingRegex, t('address.verifierEditModal.shouldBeValidReason'))

        .nullable(),
      classification: Yup.string().required('Classification is required.').nullable(),
      description: Yup.string().when('classification', {
        is: (classification: string) =>
          classification === addressClassificationOptions[OTHER_INDEX_IN_ADDRESS_CLASSIFICATION_ARRAY].label,
        then: Yup.string().required('Description is required').nullable(),
        otherwise: Yup.string().nullable(),
      }),
      moveInDate: Yup.string()
        .required(t('renter.addressSchema.fromDateIsRequired'))
        .test('dateAvailable', t('renter.addressSchema.shouldBeValidDate'), (date) => {
          if (!date) {
            return true;
          }

          return isValid(new Date(date));
        })
        .test('dateAvailable', t('renter.addressSchema.shouldBeLessThanToday'), (date) => {
          if (!date) {
            return true;
          }

          return isBefore(new Date(date), new Date());
        })
        .nullable(),
      fullName: Yup.string().nullable(),
      contactRent: Yup.number()

        .typeError(t('renter.addressSchema.rentMustBeNumber'))
        .min(MIN_RENT_AMOUNT, t('renter.addressSchema.rentMustBeGreaterThan0'))
        .max(MAX_RENT_AMOUNT, t('renter.addressSchema.rentShouldBeLess'))
        .nullable(),
      phoneNumber: Yup.string()

        .matches(phoneRegex, t('renter.addressSchema.mustContain10Digits'))
        .nullable(),
      emailAddress: Yup.string()

        .matches(emailRegex, t('renter.addressSchema.mustBeAValidEmail'))
        .nullable(),
    }),
  }),
  //yup step 3 (For Custom Address)
  Yup.object().shape({
    zipCode: Yup.string()
      .required(t('renter.addressSchema.zipCodeIsRequired'))
      .matches(zipRegex, t('renter.addressSchema.zipCodeError'))
      .nullable(),
    streetAddress1: Yup.string()
      .required(t('renter.addressSchema.streetAddressIsRequired'))
      .min(2, t('renter.addressSchema.mustHaveAtleast2'))
      .max(100, t('renter.addressSchema.cantHaveMoreThan100'))
      .nullable(),
    state: Yup.string()
      .required(t('renter.addressSchema.stateIsRequired'))
      .matches(stateRegex, t('renter.addressSchema.invalidFormatForState'))
      .nullable(),
    city: Yup.string()
      .required(t('renter.addressSchema.cityIsRequired'))
      .min(3, t('renter.addressSchema.mustBeAtleast3Characters'))
      .matches(cityRegex, t('renter.addressSchema.invalidFormatForCity'))
      .nullable(),
    desiredMoveInDate: Yup.string()
      .required(t('renter.propertySelect.desiredMoveInRequired'))
      .test('moveInDate', t('renter.addressSchema.shouldBeValidDate'), (date) => {
        if (!date) {
          return true;
        }

        return isValid(new Date(date));
      })
      .test('moveInDate', 'Date should be in future', (date) => {
        if (!date) {
          return true;
        }

        return isToday(new Date(date)) || isAfter(new Date(date), new Date());
      })
      .test('moveInDate', "Shouldn't be greater than max range", (date) => {
        if (!date) {
          return true;
        }

        return isAfter(add(new Date(), { years: 15 }), new Date(date));
      }),
  }),
];
export const GuarantorFormSchema = Yup.object().shape({
  relationship: Yup.string().required(t('renter.relationship.relationshipValidation')),
  phoneNumber: Yup.string().required(t('renter.relationship.phoneNumberValidation')),
  emailAddress: Yup.string()
    .required(t('renter.relationship.emailValidation'))
    .matches(emailRegex, t('renter.relationship.emailGuidlineValidation')),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const vehiclesInformationSchema = Yup.object().shape({
  vehicleType: Yup.string().required(t('renter.vehiclesInformation.validation.vehicleType')),
  licensePlate: Yup.string()
    .matches(lisencePlateRegex, t('renter.vehiclesInformation.validation.lisencePlate.format'))
    .required(t('renter.vehiclesInformation.validation.lisencePlate'))
    .nullable()
    .max(LICENCE_MAX_LENGTH),
  stateIssued: Yup.string()
    .matches(onlyUpperLowerCase, `${t('renter.vehiclesInformation.validation.format')}`)
    .required(t('renter.vehiclesInformation.validation.state'))
    .nullable(),

  make: Yup.string()
    .matches(onlyUpperLowerCase, `${t('renter.vehiclesInformation.validation.format')}`)
    .required(t('renter.vehiclesInformation.validation.make'))
    .nullable(),
  model: Yup.string()
    .matches(modelRegex, `${t('renter.vehiclesInformation.validation.format')}`)
    .required(t('renter.vehiclesInformation.validation.model'))
    .nullable(),
  color: Yup.string()
    .matches(onlyUpperLowerCase, `${t('renter.vehiclesInformation.validation.format')}`)
    .required(t('renter.vehiclesInformation.validation.color'))
    .nullable(),
  yearOfVehicle: Yup.string()
    .required(t('renter.vehiclesInformation.validation.year'))
    .test('valid-year', t('renter.vehiclesInformation.validation.invalidYear'), function (value) {
      const currentYear = new Date().getFullYear() + 1;

      if (!value) {
        return true;
      }

      const year = parseInt(value);

      if (isNaN(year) || year < MAX_OLD_CAR_YEAR) {
        return false;
      }

      return year <= currentYear;
    }),
});

export const renterAnimalSchema = Yup.object().shape({
  furryFriendType: Yup.string().required(),
  assistiveAnimalType: Yup.string()
    .when('furryFriendType', {
      is: (furryFriendType: string) => furryFriendType === FurryFriendTypes.ASSISTIVE_ANIMAL,
      then: Yup.string().required(t('renter.petsInformation.assistiveAnimalValidation')).nullable(),
      otherwise: Yup.string().nullable(),
    })
    .nullable(),
  furryFriendSubType: Yup.string()
    .required(t('renter.petsInformation.petTypeValidation'))
    .min(2, t('renter.petsInformation.petTypeHasToBeGreaterThan2'))
    .max(50, t('renter.petsInformation.petTypeHasToBeLessThan50')),
  genderType: Yup.string().required(t('renter.petsInformation.genderTypeValidation')),
  name: Yup.string()
    .required(t('renter.petsInformation.nameValidation'))
    .matches(animalRegex, t('renter.petsInformation.name2Validation'))
    .min(2)
    .max(50),
  breed: Yup.string()
    .required(t('renter.petsInformation.breedValidation'))
    .matches(animalRegex, t('renter.petsInformation.breed2Validation'))
    .min(2)
    .max(50),

  ageUnit: Yup.string().required(t('renter.petsInformation.ageUnitValidation')),
  weight: Yup.number()
    .required(t('renter.petsInformation.weightValidation'))
    .typeError(t('renter.petsInformation.weightNumberValidation'))
    .min(1)
    .integer(),
  age: Yup.number()
    .required(t('renter.petsInformation.ageValidation'))
    .typeError(t('renter.petsInformation.ageNumberValidation'))
    .min(1)
    .integer(),
});
export const renterPaymentSchema = Yup.object().shape({
  name: Yup.string()
    .required(t('renter.paymentInformation.schema.nameIsRequired'))
    .matches(cardNameRegex, t('renter.paymentInformation.schema.shouldBeAValidName')),
});

export const desiredMoveInDateSchema = Yup.object().shape({
  moveInDate: Yup.string()
    .required(t('renter.propertySelect.moveInRequired'))
    .test('moveInDate', t('renter.addressSchema.shouldBeValidDate'), (date) => {
      if (!date) {
        return true;
      }

      return isValid(new Date(date));
    })
    .test('moveInDate', 'Date should be in future', (date) => {
      if (!date) {
        return true;
      }

      return isToday(new Date(date)) || isAfter(new Date(date), new Date());
    })
    .test('moveInDate', "Shouldn't be greater than max range", (date) => {
      if (!date) {
        return true;
      }

      return isAfter(add(new Date(), { years: 15 }), new Date(date));
    }),
});

export const BackgroundQuestionnaireSchema = Yup.object().shape({
  questions: Yup.array().of(
    Yup.object().shape({
      id: Yup.number(),
      question: Yup.string(),
      answer: Yup.string().oneOf(['YES', 'NO']).required(),
      explanation: Yup.string().when('answer', {
        is: 'YES',
        then: Yup.string().required(t('renter.backgroundQuestion.explanation')).nullable(),
        otherwise: Yup.string().nullable(),
      }),
    })
  ),
});

export const BackgroundInformationSchema = Yup.object().shape({
  backgroundInformation: Yup.array().of(
    Yup.object().shape({
      id: Yup.number(),
      backgroundQuestion: Yup.object().shape({
        id: Yup.number(),
        question: Yup.string(),
      }),
      answer: Yup.string(),
      explanation: Yup.string()
        .nullable()
        .when('answer', {
          is: 'YES',
          then: Yup.string().required(t('renter.backgroundQuestion.explanation')),
          otherwise: Yup.string().nullable(),
        }),
    })
  ),
});

export const renterManualVerificationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First name is required')
    .matches(nameRegex, `${t('renter.nameError.invalidFormat')}`)
    .min(NAME_MIN_LENGTH, `${t('renter.nameError.minLength')}`)
    .max(NAME_MAX_LENGTH, `${t('renter.nameError.maxLength')}`)
    .nullable(),
  lastName: Yup.string()
    .required('Last name is required')
    .matches(nameRegex, `${t('renter.nameError.invalidFormat')}`)
    .min(NAME_MIN_LENGTH, `${t('renter.nameError.minLength')}`)
    .max(NAME_MAX_LENGTH, `${t('renter.nameError.maxLength')}`)
    .nullable(),
  middleName: Yup.string()
    .matches(nameRegex, `${t('renter.nameError.invalidFormat')}`)
    .min(NAME_MIN_LENGTH, `${t('renter.nameError.minLength')}`)
    .max(NAME_MAX_LENGTH, `${t('renter.nameError.maxLength')}`)
    .nullable(),
  birthDate: Yup.string()
    .required(t('renter.relationship.dateValidation'))
    .test('birthDate', t('renter.addressSchema.shouldBeValidDate'), (date) => {
      if (!date) {
        return true;
      }

      return isValid(new Date(date));
    })
    .test('birthDate', t('renter.addressSchema.shouldBeLessThanToday'), (date) => {
      if (!date) {
        return true;
      }

      return isBefore(new Date(date), new Date());
    })
    .test('birthDate', t('renter.addressSchema.shouldBeLessThan18YearsAgo'), (date) => {
      if (!date) {
        return true;
      }

      const dif = differenceInCalendarYears(new Date(), new Date(date));

      return dif >= 18;
    }),
});
