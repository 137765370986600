import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { isEven } from 'helpers/reportsHelper';

import { ReportHeading } from '../../ReportHeading/ReportHeading';
import { RecordReportItem } from '../RecordReportItem/RecordReportItem';

import styles from './RecordSummaryReport.module.scss';

export interface RecordSummaryReportProps {
  publicRecords: number;
  inquiries: number;
  tradelines: number;
  negativeTradelines: number;
  paidAccounts: number;
  satisfactory: number;
  daysDelinquent30: number;
  daysDelinquent60: number;
  daysDelinquent90: number;
  overDelinquent180: number;
}
export const RecordSummaryReport = ({
  publicRecords,
  tradelines,
  negativeTradelines,
  inquiries,
  paidAccounts,
  satisfactory,
  daysDelinquent30,
  daysDelinquent60,
  daysDelinquent90,
  overDelinquent180,
}: RecordSummaryReportProps): JSX.Element => {
  const { t } = useTranslation();
  const recordSummaryItems = [
    { name: 'Public Records', count: publicRecords },
    { name: 'Inquiries', count: inquiries },
    { name: 'Tradelines', count: tradelines },
    { name: 'Paid Accounts', count: paidAccounts },
    { name: 'SATISFACTORY', count: satisfactory },
  ];
  const derogatoryItems = [
    { name: 'Negative Trades', count: negativeTradelines },
    { name: '30 Days Delinquent', count: daysDelinquent30 },
    { name: '60 Days Delinquent', count: daysDelinquent60 },
    { name: '90 Days Delinquent', count: daysDelinquent90 },
    { name: 'Over 180 Days Delinquent', count: overDelinquent180 },
  ];
  const tableHeadings = ['Trades', 'High Credit', 'Credit Limit', 'Past Due', 'Balance', 'Payment', 'Available'];
  const tableData = [
    {
      name: 'Revolving',
      info: {
        tradesNo: 600,
        highCredit: '$0000',
        creditLimit: '$0000',
        pastDue: '$0000',
        balance: '$0000',
        payment: '25%',
        available: '25%',
      },
    },
    {
      name: 'Installment',
      info: {
        tradesNo: 600,
        highCredit: '$0000',
        creditLimit: '$0000',
        pastDue: '$0000',
        balance: '$0000',
        payment: '25%',
        available: '25%',
      },
    },
    {
      name: 'Real Estate',
      info: {
        tradesNo: 600,
        highCredit: '$0000',
        creditLimit: '$0000',
        pastDue: '$0000',
        balance: '$0000',
        payment: '25%',
        available: '25%',
      },
    },
  ];
  const tableTotal = [600, '$0000', '$0000', '$0000', '$0000', '25%', '25%'];

  return (
    <div className={styles.Container}>
      <ReportHeading heading={t('creditReport.recordsSummary')} hasCounter={false} />
      <div className={styles.RecordItemsContainer}>
        {recordSummaryItems.map((item) => (
          <div key={`record-summary-${item.name}`} className={styles.RecordItem}>
            <RecordReportItem label={item.name} value={item.count} />
          </div>
        ))}
      </div>
      <ReportHeading heading={t('creditReport.derogatoryItems')} hasCounter={false} />
      <div className={styles.RecordItemsContainer2}>
        <div className={styles.RecordItemsContainerSecond}>
          {derogatoryItems.map((item) => (
            <div key={`record-summary-${item.name}`} className={styles.RecordItem}>
              <RecordReportItem label={item.name} value={item.count} />
            </div>
          ))}
        </div>
      </div>

      <div className={styles.SummaryTable}>
        <div className={styles.SummaryTableHeader}>
          <div className={styles.SummaryTableLeft}></div>
          <div className={styles.SummaryTableRight}>
            {tableHeadings.map((heading, hindex) => (
              <span
                className={classNames(styles.SummaryHeaders, styles.TableHeadingStyle)}
                key={`heading-summary-${hindex}`}
              >
                {heading}
              </span>
            ))}
          </div>
        </div>
        {tableData.map((data, index) => (
          <div
            key={`table-data-header-${index}`}
            className={!isEven(index) ? styles.SummaryTableHeader : styles.SummaryTableHeaderGrey}
          >
            <div className={styles.SummaryTableLeft}>
              <span>{data.name}</span>
            </div>
            <div className={styles.SummaryTableRight}>
              {Object.entries(data.info).map(([_key, value], index2) => (
                <span className={styles.SummaryHeaders} key={`heading-summary-${index}-${index2}`}>
                  {value}
                </span>
              ))}
            </div>
          </div>
        ))}
        <div className={styles.SummaryTableTotal}>
          <div className={styles.SummaryTableLeft}>
            <span>{t('creditReport.total')}</span>
          </div>
          <div className={styles.SummaryTableRight}>
            {tableTotal.map((total, index) => (
              <span className={styles.SummaryHeaders} key={`heading-total-${index}`}>
                {total}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
