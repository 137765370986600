import { useMemo, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { t } from 'i18next';
import { selectAgentProfileInformation } from 'redux/selectors/agentSelector';
import { logoutSuccess } from 'redux/slices/authSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as LogOutIcon } from 'assets/svgs/ExitCyanIcon.svg';
import placeholderImage from 'assets/svgs/PlaceholderRound.svg';
import { ReactComponent as SettingIcon } from 'assets/svgs/SettingCyanIcon.svg';
import { ReactComponent as UsersIcon } from 'assets/svgs/UsersCyanIcon.svg';
import Button from 'components/shared/Button/Button';
import { closePopOverOnClick } from 'helpers/agentHelper';
import { logout } from 'services/authService';
import { routes } from 'shared/routes';
import { UserType } from 'shared/types/authType';

import ProfileInfo from '../ProfileInfo/ProfileInfo';

import ManageOrganization from './ManageOrganization';

import styles from './ProfileDropdown.module.scss';
export interface ProfileDropdownProps {
  children: React.ReactElement;
  isProfileInfoVisible?: boolean;
  organizationName?: string;
  name?: string;
  isProfileSet?: boolean;
}

const ProfileDropdown = ({
  children,
  organizationName,
  name,
  isProfileInfoVisible,
  isProfileSet = true,
}: ProfileDropdownProps): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const [isLogout, setIsLogout] = useState<boolean>(false);
  const agentProfileInformation = useSelector(selectAgentProfileInformation);
  const isSuperAdmin = useMemo(
    (): boolean => agentProfileInformation.agentProfessionalProfile?.role === UserType.SUPERADMIN,
    [agentProfileInformation.agentProfessionalProfile]
  );
  const location = useLocation();
  const handleLogout = (): void => {
    setIsLogout(true);
    logout().then(() => {
      dispatch(logoutSuccess());
    });
  };
  const renderTooltip = (
    <Popover id="popover-positioned-bottom">
      <Popover.Body className={styles.popoverBody}>
        {isProfileInfoVisible && name && (
          <Link to={routes.reports} className={styles.popoverBtn} onClick={closePopOverOnClick}>
            <div className={styles.selectedOrgDiv}>
              <ProfileInfo
                organizationName={location.pathname === routes.organizations && isSuperAdmin ? '' : organizationName}
                name={name}
                imageSrc={placeholderImage}
              />
            </div>
          </Link>
        )}
        {isProfileSet && (
          <>
            <hr className={classNames(styles.hrLine, styles.hrLineWithNoTopMargin)} />
            {!isSuperAdmin && <ManageOrganization />}
            <Link to={routes.organizationDashboard} className={styles.popoverBtn} onClick={closePopOverOnClick}>
              <div className={styles.selectedOrgDiv}>
                <span className={styles.iconDiv}>
                  <UsersIcon />
                </span>
                <div className={styles.profileDropDownOptions}>{t('agent.Users&team')}</div>
              </div>
            </Link>
            <Link to={routes.settings} className={styles.popoverBtn} onClick={closePopOverOnClick}>
              <div className={styles.selectedOrgDiv}>
                <span className={styles.iconDiv}>
                  <SettingIcon />
                </span>
                <div className={styles.profileDropDownOptions}>{t('application.setting')}</div>
              </div>
            </Link>

            <hr className={styles.hrLine} />
          </>
        )}
        <span className={styles.popoverBtn}>
          <Button variant="link" disabled={isLogout} onClick={handleLogout} className={styles.selectedOrgDiv}>
            <span className={styles.iconDiv}>
              <LogOutIcon />
            </span>
            <div className={styles.profileDropDownOptions}>{t('application.logout')}</div>
          </Button>
        </span>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger rootClose trigger="click" key="bottom" placement="bottom" overlay={renderTooltip}>
      {children}
    </OverlayTrigger>
  );
};

export default ProfileDropdown;
