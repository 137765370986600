import { AxiosResponse } from 'axios';
import { differenceInCalendarMonths, differenceInCalendarYears } from 'date-fns';
import { format as formatDate } from 'date-fns';
import { logoutSuccess, setSecurityQuestionsRenter } from 'redux/slices/authSlice';
import { getRenterProfileInformation } from 'redux/slices/renterSlice';

import DocumentIcon from 'assets/images/documentIcon.png';
import agentProfile from 'assets/images/ExampleCardProfile.png';
import defaultPropertyImage from 'assets/images/Rectangle 1515.png';
import { PreviewFile } from 'components/shared/DropZone/MinimizedDropzone/MinimizedDropzone';
import { dateFormatFrontend } from 'constants/calendarConstants';
import { ssnPattern } from 'constants/regexConstant';
import { FurryFriendTypes, PaymentVerificationKeys, RenterAddressTypes } from 'constants/renterConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { resendRenterOtp, submitRenterOtp } from 'services/renterService';
import { EXPERIAN_EXPIREY_ERROR_CODE, EXPERIAN_USER_BLOCKED_ERROR_CODE } from 'shared/constants';
import { Notification } from 'shared/Notification/Notification';
import { renterRoutes } from 'shared/routes';
import { PropertyApplicationStatus, ResentOtpRequestProps } from 'shared/types/agentTypes';
import { ApplicationType } from 'shared/types/applicantsType';
import { PropertyProps } from 'shared/types/propertyType';
import { BackgroundInfo, FormValues } from 'shared/types/renterBackgroundInformation';
import { FileTypes } from 'shared/types/renterIncomeInformation';
import {
  AddressInformation,
  AddressInformationResponse,
  AddressVerifier,
  AnimalInformationProps,
  ApplicationProps,
  EmploymentVerifier,
  FormatAddressZipCode,
  RenterApplicationProps,
  RenterPresentEmployments,
  RenterProfileInformationProps,
  RenterProfileProps,
  RenterRoles,
  SubmitRenterOtpRequestProps,
} from 'shared/types/renterTypes';

import { pluralize } from './user';

export const formatRenterName = (params: RenterProfileInformationProps | undefined): string => {
  if (!params) {
    return '';
  }

  const { firstName, middleName, lastName } = params;

  return `${firstName ?? ''} ${middleName ?? ''} ${lastName ?? ''}`;
};

export const renterPresentAddress = (
  params: AddressInformationResponse[] | undefined
): AddressInformationResponse | undefined => params?.find((obj) => obj.addressType === RenterAddressTypes.PRESENT);

export const renterPriorAddress = (
  params: AddressInformationResponse[] | undefined
): AddressInformationResponse[] | undefined =>
  params?.filter(({ addressType }) => addressType === RenterAddressTypes.PRIOR);

export const formatRenterPresentAddress = (params: AddressInformationResponse[] | undefined): string => {
  const currentAddress = renterPresentAddress(params);

  if (currentAddress) {
    const { streetAddress, apartmentNumber, city, state, zipCode } = currentAddress;

    return `${streetAddress?.trim() ?? ''}${apartmentNumber ? ', ' + apartmentNumber : ''}, ${city ?? ''}, ${
      state ?? ''
    } ${zipCode ?? ''}`;
  }

  return '';
};

export const formatAddressZipCode = ({ city, state, zipCode }: FormatAddressZipCode): string =>
  `${city ?? ''}, ${state ?? ''} ${zipCode ?? ''}`;

export const formatPhoneNumber = (phoneNumber: string | null | undefined): string => {
  if (phoneNumber) {
    return (phoneNumber.includes('+') ? phoneNumber : `+1${phoneNumber}`).replace(
      /(\d{1})(\d{3})(\d{3})(\d{4})/,
      '$1 ($2) $3-$4'
    );
  }

  return '';
};

export const differenceInYearsAndMonths = (inputDate: Date | string | undefined): string => {
  if (!inputDate) {
    return '';
  }

  const currentDate = new Date();
  const targetDate = typeof inputDate === 'string' ? new Date(inputDate) : inputDate;
  const differenceInYears = differenceInCalendarYears(currentDate, targetDate);
  const differenceInMonths = differenceInCalendarMonths(currentDate, targetDate);

  if (!differenceInYears) {
    return '';
  }

  if (differenceInYears === 1 && differenceInMonths > 0 && differenceInMonths < 12) {
    return ` ${pluralize(differenceInMonths, 'mo')}`;
  }

  return ` ${pluralize(differenceInYears, 'yr')}`;
};
export const imageUrl = (animal: AnimalInformationProps): string | undefined => {
  if (!animal?.images?.length) {
    if (!animal?.documents?.length) {
      return;
    } else if (animal.documents[0].fileType === FileTypes.PDF) {
      return DocumentIcon;
    }

    return;
  } else if (animal.images[0].fileType === FileTypes.PDF) {
    return DocumentIcon;
  }

  return animal.images[0].fileLink;
};
const createStarPattern = (size: number): string => '*'.repeat(size);

export const formatSSN = (value: string, isStarPattern = false): string => {
  if (value) {
    if (value.length <= 4 && isStarPattern) {
      return [createStarPattern(3), createStarPattern(2), value].filter(Boolean).join('-');
    }

    if (value.includes('*')) {
      return value;
    }

    const cleanedValue = value.replace(/\D/g, '').slice(0, 9);
    const match = cleanedValue.match(ssnPattern);

    if (match) {
      let formattedValue = '';

      formattedValue = [match[1], match[2], match[3]].filter(Boolean).join('-');

      return formattedValue;
    }
  }

  return value;
};

export const formatRentPrefixString = (value: number | undefined): string => {
  if (value) {
    return '$';
  }

  return '';
};

export const isAddressVerifierPresent = (address: AddressInformation | AddressInformationResponse): boolean =>
  !!address?.isRented;

export const getPrimaryVerifier = (
  verifiers: AddressVerifier[] | EmploymentVerifier[]
): AddressVerifier | EmploymentVerifier | undefined => verifiers.find((verifier) => verifier.isPrimary === true);
export const updateSnnName = (values: RenterProfileProps): RenterProfileProps => {
  if (!values?.ssnTailDigits) {
    return values;
  }

  const snn = values?.ssnTailDigits;
  const newValues = { ...values, socialSecurityNumber: String(snn) };

  delete newValues.ssnTailDigits;

  return newValues;
};

export const getFormattedApplications = ({
  renterApplications,
  propertiesInformation,
  applicationId,
}: {
  renterApplications: RenterApplicationProps[];
  propertiesInformation: Record<number, PropertyProps>;
  applicationId?: number;
}): ApplicationProps[] => {
  const formattedApplications: ApplicationProps[] = [];

  renterApplications.forEach((application) => {
    if ((applicationId && applicationId === application.id) || !applicationId) {
      const formattedApplication: ApplicationProps = {
        id: application.id,
        submittedAt: application.createdAt ?? '',
        applicationStatus: !application?.isConfirmed
          ? PropertyApplicationStatus.IN_PROGRESS
          : PropertyApplicationStatus.COMPLETED,
        streetAddress: propertiesInformation[application.id].streetAddress1.split(',')[0],
        city: propertiesInformation[application.id].city,
        zipCode: propertiesInformation[application.id].zipCode,
        state: propertiesInformation[application.id].state,
        rent: propertiesInformation[application.id].monthlyRentAmount,
        agentFirstName: application?.agentDetails?.firstName ?? '',
        agentLastName: application?.agentDetails?.lastName ?? '',
        agentEmail: application?.agentDetails?.email ?? '',
        agentProfile: agentProfile,
        propertyImage: propertiesInformation[application.id]?.propertyImages[0]?.fileLink || defaultPropertyImage,
        propertyType: propertiesInformation[application.id].propertyType ?? '',
        isCustomAddress: propertiesInformation[application.id].isCustomAddress,
        dateAvailable: propertiesInformation[application.id].dateAvailable
          ? formatDate(new Date(propertiesInformation[application.id]?.dateAvailable), dateFormatFrontend)
          : '',
      };

      formattedApplications.push(formattedApplication);
    }
  });

  return formattedApplications;
};
export const getApplicationById = (
  applications: ApplicationProps[],
  applicationId: number
): ApplicationProps | null => {
  // Find the application with the specified ID
  const foundApplication = applications.find((application) => application.id === applicationId);

  return foundApplication || null;
};
export const formatDateForVerifierPage = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  const formattedDate = date.toLocaleString('en-US', {
    ...options,
    timeZone: 'America/Los_Angeles',
    timeZoneName: 'short',
  });

  return formattedDate.replace('at', '');
};

export const formatDateForApplicationIndex = (dateObject: Date): string => {
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(dateObject);

  return formattedDate.replace(',', ' at');
};

export const isAddressVerificationPending = (addresses: AddressInformation[]): boolean =>
  addresses.some((address) => address.isRented && !address.isVerified);

export const isHouseHoldVerificationPending = (applications: ApplicationType[]): boolean =>
  applications.some((application) => !application.isConfirmed);

export const formatAddressForFrontEnd = (address: AddressInformationResponse): AddressInformation => {
  const primaryVerifier = getPrimaryVerifier(address.addressVerifiers ?? []);

  return primaryVerifier
    ? {
        ...address,
        emailAddress: primaryVerifier?.emailAddress,
        phoneNumber: primaryVerifier?.phoneNumber,
        contactRent: primaryVerifier?.contactRent,
        fullName: primaryVerifier?.fullName,
      }
    : address;
};
export const formatEmploymentForFrontEnd = (employment: RenterPresentEmployments): AddressInformation => {
  const primaryVerifier = getPrimaryVerifier(employment.presentEmploymentVerifiers ?? []);

  return primaryVerifier
    ? {
        ...employment,
        emailAddress: primaryVerifier?.emailAddress,
        phoneNumber: primaryVerifier?.phoneNumber,
        fullName: primaryVerifier?.fullName,
      }
    : employment;
};

export const submitRenterOtpRequest = ({
  setHasOtpExpired,
  setIsOtpRequestLoading,
  dispatch,
  navigate,
  applicationId,
  code,
  setInitialOtpCode,
}: SubmitRenterOtpRequestProps): void => {
  setHasOtpExpired(false);
  setIsOtpRequestLoading(true);
  submitRenterOtp(code, Number(applicationId))
    .then((res: AxiosResponse) => {
      if (res?.data?.payload?.kiqEnabled) {
        dispatch(setSecurityQuestionsRenter(res.data.payload.kba.questionSet));
        navigate(renterRoutes.generateRenterSecurityQuestionnaire(applicationId));
      } else {
        dispatch(getRenterProfileInformation());
        navigate(renterRoutes.applications);
      }
    })
    .catch((error) => {
      const errorObject = error?.response?.data?.payload?.payload?.payload?.error;

      if (errorObject) {
        if (errorObject.Code === EXPERIAN_EXPIREY_ERROR_CODE) {
          setHasOtpExpired(true);
        } else {
          Notification({ message: errorObject.Message });
        }

        return;
      }

      Notification({ message: error?.response?.data?.errors?.[0]?.errorMessage });

      if (error?.response?.data?.errors?.[0]?.errorCode === EXPERIAN_USER_BLOCKED_ERROR_CODE) {
        Notification({ message: error?.response?.data?.errors?.[0]?.errorMessage });
        dispatch(logoutSuccess);
      }
    })
    .finally(() => {
      setIsOtpRequestLoading(false);
      setInitialOtpCode('');
    });
};
export const renterResendOtpRequest = ({ setInitialOtpCode, setHasOtpExpired }: ResentOtpRequestProps): void => {
  resendRenterOtp().then((res: AxiosResponse) => {
    setInitialOtpCode(
      res?.data?.payload?.crossCoreFullResponse?.clientResponsePayload?.decisionElements?.[0]?.decisions?.[0]?.value ||
        ''
    );
    setHasOtpExpired(false);
  });
};

export const isComponentRestricted = (role: RenterRoles, componentName: PaymentVerificationKeys): boolean =>
  (componentName === PaymentVerificationKeys.ANIMAL_INFORMATION && renterRestrictions[role].animals) ||
  (componentName === PaymentVerificationKeys.VEHICLE_INFORMATION && renterRestrictions[role].vehicles) ||
  (componentName === PaymentVerificationKeys.HOUSEHOLD_DETAILS &&
    renterRestrictions[role].adults &&
    renterRestrictions[role].minors);
export const formatBackgroundInformationToFormValues = (backgroundInformation: BackgroundInfo[]): FormValues[] =>
  backgroundInformation.map((backgroundInfo) => ({
    id: backgroundInfo?.id,
    question: backgroundInfo.backgroundQuestion?.question, // assuming backgroundQuestion has a question property
    answer: backgroundInfo?.answer,
    explanation: backgroundInfo?.explanation ?? null, // use null if explanation is undefined
  }));
export const formatAnimalEditModalValues = (animalInformation: AnimalInformationProps): AnimalInformationProps => {
  const { furryFriendType } = animalInformation;

  return {
    ...animalInformation,
    documents: furryFriendType === FurryFriendTypes.ASSISTIVE_ANIMAL ? animalInformation.documents : [],
    assistiveAnimalType:
      furryFriendType === FurryFriendTypes.ASSISTIVE_ANIMAL ? animalInformation.assistiveAnimalType : null,
  };
};

export const getAnimalFilesToDelete = (oldFiles: PreviewFile[], newFiles: PreviewFile[]): PreviewFile[] => {
  const documentIds = new Set(newFiles?.map((file) => file.id).filter((fileId) => fileId !== undefined));

  return oldFiles?.filter((file) => !documentIds?.has(file.id ?? 0));
};
