import { Navigate } from 'react-router-dom';
import { AgentProfileFormWrapper } from 'pages/AgentPofileForm/AgentProfileFormWrapper';
import ApplicantsPage from 'pages/Applicants/ApplicantsPage';
import InboxPage from 'pages/Inbox';
import { OrganizationsPage } from 'pages/OrganizationsPage/OrganizationsPage';
import { Settings } from 'pages/Settings/Settings';
import { TwoFactorSetup } from 'pages/TwoFactorSetup/TwoFactorSetup';

import { AgentSecurityQuestonWrapper } from 'components/Agent/AgentSecurityQuestonWrapper/AgentSecurityQuestonWrapper';
import RenterResume from 'components/Agent/Applications/ApplicationDetails/components/RenterResume/RenterResume';
import { PropertyDetails } from 'components/Agent/Applications/PropertyDetails/PropertyDetails';
import UserOrganization from 'components/Agent/components/UserOrganization/UserOrganization';
import { InternalServerError, PageNotFoundError } from 'components/shared/ServerErrors/index';
import { routes } from 'shared/routes';
const agentRoutes = [
  { path: '*', element: <Navigate to={routes.applicants} replace />, index: true },
  {
    path: routes.agentProfileForm,
    element: <AgentProfileFormWrapper />,
  },
  {
    path: routes.applicants,
    element: <ApplicantsPage />,
  },
  {
    path: routes.agentSecurityQuestionnaire,
    element: <AgentSecurityQuestonWrapper />,
  },
  {
    path: routes.inbox,
    element: <InboxPage />,
  },
  {
    path: routes.organizationDashboard,
    element: <UserOrganization />,
  },
  {
    path: routes.renterResume,
    element: <RenterResume />,
  },
  {
    path: routes.renterDummyResume,
    element: <RenterResume />,
  },
  {
    path: routes.pageNotFound,
    element: <PageNotFoundError />,
  },
  {
    path: routes.internalServerError,
    element: <InternalServerError />,
  },
  {
    path: routes.propertyDetails,
    element: <PropertyDetails />,
  },

  {
    path: routes.organizations,
    element: <OrganizationsPage />,
  },
  {
    path: routes.settings,
    element: <Settings />,
  },
  {
    path: routes.twoFactorSetup,
    element: <TwoFactorSetup />,
  },
];

export default agentRoutes;
