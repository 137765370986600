import { DownloadProvider } from 'contexts/DownloadContext';

import Router from 'routes';

import 'react-loading-skeleton/dist/skeleton.css';
import './App.scss';
const App = (): JSX.Element => (
  <div className="App">
    <DownloadProvider>
      <Router />
    </DownloadProvider>
  </div>
);

export default App;
