import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { t } from 'i18next';
import { selectRenterApplication } from 'redux/selectors/agentSelector';

import { ReactComponent as HouseHoldIcon } from 'assets/svgs/HouseHold.svg';
import styles from 'components/Agent/Applications/ApplicationDetails/ApplicationDetails.module.scss';
import { formatName } from 'helpers/helper';
import { formatPhoneNumber } from 'helpers/renterHelper';

import { SectionTitle } from '../shared';

import OccupantsDetails from './OccupantsDetails/OccupantsDetails';

import componentStyles from './RenterHousehold.module.scss';

const RenterHousehold = (): JSX.Element => {
  const { adults, minors, hasNoAdults, hasNoMinors } = useSelector(selectRenterApplication);
  const totalOccupants = useMemo(() => (adults?.length ?? 0) + (minors?.length ?? 0) + 1, [adults, minors]);

  return (
    <div className={classNames(styles.container, componentStyles.Container, styles.ApplicationDetails)}>
      <div className={styles.infoDiv}>
        <SectionTitle
          mainTitle={t('application.household')}
          iconElement={<HouseHoldIcon width={20} height={20} />}
          description={t('renter.paymentConfirmation.houseHoldDetails.totalOccupants')}
          count={totalOccupants}
          shouldShowOptions
          shouldDrawLine
        />

        <div
          className={classNames(componentStyles.SubHeadingContainer, {
            [componentStyles.SubHeadingContainerForEmpty]: hasNoAdults,
          })}
        >
          <span>{t('renter.relationship.adultCoOccupants')}</span>
          {!hasNoAdults ? (
            <span className={componentStyles.Pill}>{adults?.length}</span>
          ) : (
            <span className={componentStyles.Pill}>0</span>
          )}
        </div>
        {hasNoAdults && <span className={componentStyles.NotExist}>{t('agnet.renterResumePdf.iAmTheOnyAdult')}</span>}
        {adults?.map(({ firstName, lastName, relationship, emailAddress, phoneNumber }, index) => (
          <OccupantsDetails
            key={`adult-occupant-${emailAddress}-${index}`}
            isAdult
            fullName={formatName(firstName, lastName)}
            relationShip={relationship}
            email={emailAddress}
            phone={formatPhoneNumber(phoneNumber)}
          />
        ))}

        <div
          className={classNames(componentStyles.SubHeadingContainer, {
            [componentStyles.MinorEmpty]: hasNoMinors,
          })}
        >
          <span>{t('renter.relationship.minorCoOccupants')}</span>
          {!hasNoMinors ? (
            <span className={componentStyles.Pill}>{minors?.length}</span>
          ) : (
            <span className={componentStyles.Pill}>0</span>
          )}
        </div>
        {hasNoMinors && <span className={componentStyles.NotExist}>{t('renter.relationship.minorNoButton')}</span>}
        {minors?.map(({ firstName, lastName, relationship, birthDate }, index) => (
          <OccupantsDetails
            key={`minor-occupant-${birthDate}-${index}`}
            fullName={formatName(firstName, lastName ?? '')}
            relationShip={relationship}
            dateOfBirth={birthDate as string}
          />
        ))}
      </div>
    </div>
  );
};

export default RenterHousehold;
