import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { FormikErrors } from 'formik';
import { isEmpty } from 'lodash-es';

import { updateAgentProfileHelper } from 'helpers/agentHelper';
import { handleServerResponseError, parseResponseErrors } from 'helpers/helper';
import * as agentService from 'services/agentService';
import { StatusCodes } from 'shared/constants';
import { Notification } from 'shared/Notification/Notification';
import {
  AddressVerificationInformationRequest,
  AddressVerificationResponse,
  AgentInitialProfileInformation,
  AgentProfileInformationProps,
  EmploymentVerificationInformationRequest,
  GetLegacyApplicantsParams,
  LegacyApplicantsProps,
  LegacyApplicantsTotalCount,
  OrganizationProps,
  TransactionRequestProps,
  UpdateApplicationFileStatusValues,
  VerificationInformationRequestProps,
} from 'shared/types/agentTypes';
import { ApplicationType } from 'shared/types/applicantsType';
import { PaginationType } from 'shared/types/paginationTypes';
import { AddPropertyParams, PropertyApplicantType } from 'shared/types/propertyType';
import { RenterProfileInformationInitialValue, RenterProfileInformationProps } from 'shared/types/renterTypes';
import { ExperianReportsResponse, InitialExperianReportsValue, NovaReport } from 'shared/types/reportTypes';
import { createFormikErrorObject } from 'shared/utils/errorObject';

export interface PropertyApplications {
  [key: number]: ApplicationType[];
}

export interface GetRenterApplicationProps {
  organizationId: number;
  applicationId: number;
}
interface AgentInterface {
  isLoading: boolean;
  profileInformation: AgentProfileInformationProps;
  selectedOrganization?: OrganizationProps;
  selectedRenter: RenterProfileInformationProps;
  propertyApplications: PropertyApplications;
  renterExperianReports: ExperianReportsResponse;
  selectedRenterApplication: ApplicationType;
  selectedProperty: AddPropertyParams;
  verifierAddressInformation: AddressVerificationResponse[];
  verifierEmploymentInformation: AddressVerificationResponse[];
  legacyApllicants: LegacyApplicantsProps[];
  pageInfo: PaginationType;
  leagacyCount: LegacyApplicantsTotalCount;
  renterNovaReport: NovaReport;
}

type AgentParams = {
  values: AgentProfileInformationProps;
  setErrors: (errors: FormikErrors<AgentProfileInformationProps>) => void;
};

export const initialState: AgentInterface = {
  isLoading: false,
  profileInformation: AgentInitialProfileInformation,
  selectedOrganization: undefined,
  selectedRenter: RenterProfileInformationInitialValue,
  propertyApplications: {},
  renterExperianReports: InitialExperianReportsValue,
  selectedRenterApplication: {} as ApplicationType,
  verifierAddressInformation: [],
  verifierEmploymentInformation: [],
  selectedProperty: {},
  legacyApllicants: [],
  pageInfo: {} as PaginationType,
  leagacyCount: {} as LegacyApplicantsTotalCount,
  renterNovaReport: {} as NovaReport,
};

const slice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setProfileInformation: (state, action) => {
      state.profileInformation = action.payload;
    },
    setSelectedProperty: (state, action) => {
      state.selectedProperty = action.payload;
    },
    resetSelectedRenter: (state) => {
      state.selectedRenter = {} as RenterProfileInformationProps;
    },
    resetPropertyApplications: (state) => {
      state.propertyApplications = {};
    },
    removePropertyApplication: (state, { payload }) => {
      const propertyApplications = current(state).propertyApplications;
      const updatedApplications = {
        ...propertyApplications,
        [payload.propertyId]: propertyApplications[payload.propertyId]?.filter(
          (item) => item.id !== payload.applicationId
        ),
      };

      if (isEmpty(updatedApplications[payload.propertyId])) {
        delete updatedApplications[payload.propertyId];
      }

      state.propertyApplications = updatedApplications;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateAgentDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAgentDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.profileInformation = updateAgentProfileHelper(action.payload);
      })
      .addCase(updateAgentDetails.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getRenterResume.pending, (state) => {
        state.isLoading = true;
        state.selectedRenter = {} as RenterProfileInformationProps;
      })
      .addCase(getRenterResume.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedRenter = action.payload;
      })
      .addCase(getRenterResume.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getPropertyApplicant.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPropertyApplicant.fulfilled, (state, action) => {
        state.isLoading = false;
        state.propertyApplications = {
          ...state.propertyApplications,
          [action.payload.propertyId]: action.payload.applications,
        };
      })
      .addCase(getPropertyApplicant.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getRenterExperianReport.pending, (state) => {
        state.isLoading = true;
        state.renterExperianReports = InitialExperianReportsValue;
      })
      .addCase(getRenterExperianReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.renterExperianReports = action.payload;
      })
      .addCase(getRenterExperianReport.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getRenterExperianReportForSuperadmin.pending, (state) => {
        state.isLoading = true;
        state.renterExperianReports = InitialExperianReportsValue;
      })
      .addCase(getRenterExperianReportForSuperadmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.renterExperianReports = action.payload;
      })
      .addCase(getRenterExperianReportForSuperadmin.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getRenterNovaReport.pending, (state) => {
        state.isLoading = true;
        state.renterNovaReport = {} as NovaReport;
      })
      .addCase(getRenterNovaReport.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.renterNovaReport = payload.payload;
      })
      .addCase(getRenterNovaReport.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getRenterApplication.pending, (state) => {
        state.isLoading = true;
        state.selectedRenterApplication = {} as ApplicationType;
      })
      .addCase(getRenterApplication.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedRenterApplication = action.payload;
      })
      .addCase(getRenterApplication.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAddressVerificationInformation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAddressVerificationInformation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.verifierAddressInformation = [...state.verifierAddressInformation, action.payload];
      })
      .addCase(getAddressVerificationInformation.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getVerifierAddresses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVerifierAddresses.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getVerifierAddresses.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getEmploymentVerificationInformation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEmploymentVerificationInformation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.verifierEmploymentInformation = [...state.verifierEmploymentInformation, action.payload];
      })
      .addCase(getEmploymentVerificationInformation.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getVerifierEmployments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVerifierEmployments.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getVerifierEmployments.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateApplicationFileStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateApplicationFileStatus.fulfilled, (state, action) => {
        state.selectedRenterApplication = {
          ...state.selectedRenterApplication,
          fileApplication: { ...state.selectedRenterApplication.fileApplication, status: action.payload.status },
        };
        state.isLoading = false;
      })
      .addCase(updateApplicationFileStatus.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getPaginatedLegacyApplicants.pending, (state) => {
        state.isLoading = true;
        state.legacyApllicants = [];
        state.pageInfo = {};
      })
      .addCase(getPaginatedLegacyApplicants.fulfilled, (state, { payload }) => {
        state.isLoading = false;

        state.legacyApllicants = payload.results;
        state.pageInfo = payload.pageInfo;
        state.leagacyCount = {
          archivedCount: payload.archivedCount,
          approvedCount: payload.approvedCount,
          deniedCount: payload.deniedCount,
          invitedCount: payload.invitedCount,
        };
      })
      .addCase(getPaginatedLegacyApplicants.rejected, (state) => {
        state.isLoading = false;
      })
      .addDefaultCase((state) => state);
  },
});

// API call for update agent profile that takes agent params and updates the info
// it also shows the error messages
export const updateAgentDetails = createAsyncThunk(
  'agent/profileInformation',
  async ({ values }: AgentParams, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await agentService.updateAgentDetails(values);

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      const errorObject = createFormikErrorObject(error as AxiosError);

      return rejectWithValue({ ...errorObject });
    }
  }
);
export const getRenterResume = createAsyncThunk(
  'renter/getRenterResume',
  async ({ renterId, organizationId }: { renterId?: number; organizationId: number }, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await agentService.getRenterDetails(renterId, organizationId);

      return payload;
    } catch (e) {
      // Handle errors using the handleProfileError action
      handleServerResponseError({ error: e, dispatch });

      try {
        if (e instanceof AxiosError && e.response && e.response?.status === StatusCodes.INTERNAL_SERVER_ERROR) {
          throw e;
        }
      } catch (error) {
        // Handle any errors that occur during the creation or subsequent getProfileInformation attempt
        handleServerResponseError({ error, dispatch });

        if (error instanceof AxiosError && error.response) {
          Notification({ message: parseResponseErrors(error) });

          return Promise.reject();
        }
      }
    }
  }
);

export const getPropertyApplicant = createAsyncThunk(
  'property/getPropertyApplicant',
  async (values: PropertyApplicantType, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await agentService.getPropertyApplicant(values);

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });

        return Promise.reject();
      }
    }
  }
);
export const getRenterExperianReport = createAsyncThunk(
  'renter/getExperianReport',
  async (
    {
      organizationId,
      applicationId,
      isForPDF = false,
    }: { organizationId: number; applicationId: number; isForPDF?: boolean },
    { dispatch }
  ) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await agentService.getRenterExperianReport(organizationId, applicationId);

      return payload;
    } catch (error) {
      !isForPDF && handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        !isForPDF && Notification({ message: parseResponseErrors(error) });

        return Promise.reject(error.response.data.errors[0].errorCode); //error code to check the report expirey status
      }
    }
  }
);

export const getRenterExperianReportForSuperadmin = createAsyncThunk(
  'renter/report-errors',
  async (
    { organizationId, renterId, isForPDF = false }: { organizationId: number; renterId: number; isForPDF?: boolean },
    { dispatch }
  ) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await agentService.getRenterExperianReportForSuperadmin(organizationId, renterId);

      return payload;
    } catch (error) {
      !isForPDF && handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        !isForPDF && Notification({ message: parseResponseErrors(error) });

        return Promise.reject(error.response.data.errors[0].errorCode); //error code to check the report expirey status
      }
    }
  }
);

export const getRenterNovaReport = createAsyncThunk(
  'renter/getNovaReport',
  async (
    {
      organizationId,
      applicationId,
      isForPDF = false,
    }: { organizationId: number; applicationId: number; isForPDF?: boolean },
    { dispatch }
  ) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await agentService.getRenterNovaReport(organizationId, applicationId);

      return payload;
    } catch (error) {
      !isForPDF && handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        !isForPDF && Notification({ message: parseResponseErrors(error) });

        return Promise.reject(error.response.data.errors[0].errorCode); //error code to check the report expirey status
      }
    }
  }
);

export const getRenterApplication = createAsyncThunk(
  'renter/getApplication',
  async ({ organizationId, applicationId }: GetRenterApplicationProps, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await agentService.getRenterApplication(organizationId, applicationId);

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });

        return Promise.reject();
      }
    }
  }
);

export const getAddressVerificationInformation = createAsyncThunk(
  'renter/addressVerificationInformation',
  async ({ organizationId, renterId, addressId }: AddressVerificationInformationRequest, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await agentService.getAddressVerificationInformation({
        renterId: renterId,
        organizationId: organizationId,
        addressId: addressId,
      });

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });

        return Promise.reject();
      }
    }
  }
);
export const getVerifierAddresses = createAsyncThunk(
  'renter/getVerifierAddresses',
  async ({ organizationId, renterId }: VerificationInformationRequestProps, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await agentService.getVerifierAddresses({
        renterId: renterId,
        organizationId: organizationId,
      });

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });

        return Promise.reject();
      }
    }
  }
);

export const getEmploymentVerificationInformation = createAsyncThunk(
  'renter/employmentVerificationInformation',
  async ({ organizationId, renterId, employmentId }: EmploymentVerificationInformationRequest, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await agentService.getEmploymentVerificationInformation({
        renterId: renterId,
        organizationId: organizationId,
        employmentId: employmentId,
      });

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });

        return Promise.reject();
      }
    }
  }
);
export const getVerifierEmployments = createAsyncThunk(
  'renter/getVerifierEmployments',
  async ({ organizationId, renterId }: VerificationInformationRequestProps, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await agentService.getVerifierEmployments({
        renterId: renterId,
        organizationId: organizationId,
      });

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });

        return Promise.reject();
      }
    }
  }
);

export const getTransactionReport = createAsyncThunk(
  'renter/getVerifierEmployments',
  async ({ organizationId, renterId, applicationId }: TransactionRequestProps) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await agentService.retrieveRenterTransactionReport({
        renterId,
        organizationId,
        applicationId,
      });

      return payload;
    } catch (error) {
      console.log(error);

      return Promise.reject(error);
    }
  }
);

export const updateApplicationFileStatus = createAsyncThunk(
  'renter/updateApplicationFileStatus',
  async ({ organizationId, values }: UpdateApplicationFileStatusValues, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await agentService.updateApplicationFileStatus({
        values,
        organizationId,
      });

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });

        return Promise.reject();
      }
    }
  }
);

export const getPaginatedLegacyApplicants = createAsyncThunk(
  'renter/getPaginatedLegacyApplicants',
  async ({ values, organizationId }: GetLegacyApplicantsParams, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await agentService.getLegacyApplicants({ values, organizationId });

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });

        return Promise.reject();
      }
    }
  }
);

export const {
  setIsLoading,
  setProfileInformation,
  setSelectedProperty,
  resetPropertyApplications,
  removePropertyApplication,
  resetSelectedRenter,
} = slice.actions;
export const { reducer } = slice;
