import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import lodash from 'lodash';
import { selectAgentProfileInformation } from 'redux/selectors/agentSelector';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { getAgentProfileInformation } from 'redux/slices/authSlice';
import { AppThunkDispatch } from 'redux/store';
import { AgentBusinessProfileSchema } from 'schema/agentSchema';

import { ReactComponent as InfoIcon } from 'assets/svgs/InfoIcon.svg';
import RCButton from 'components/shared/Button/Button';
import FormikSelectField from 'components/shared/FormikSelectField/FormikSelectField';
import FormikPhoneNumber from 'components/shared/PhoneNumber/FormikPhoneNumber';
import { PlacesAutocompleteComponent } from 'components/shared/PlacesAutoCompleteComponent/PlacesAutoCompleteComponent';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import StateSelectField from 'components/shared/StateSelectField/StateSelectField';
import FormikField from 'components/shared/TextField/FormikTextField';
import { PhoneNoTypes } from 'constants/agentConstants';
import { updateAgentBusinessProfile } from 'services/agentService';
import { US_STATES } from 'shared/data/usStates';

import { SettingsSaveRow } from '../../SettingsSaveRow';

import styles from '../../Settings.module.scss';

export const BusinessDetailsForm = (): JSX.Element => {
  const [isAddresError, setIsAddressError] = useState<boolean>(false);
  const currentOrganization = useSelector(selectedOrganization);
  const location = useLocation();
  const dispatch = useDispatch<AppThunkDispatch>();
  const isEditableForm = Boolean(location.state && location.state?.editable);
  const [isEditable, setIsEditable] = useState(isEditableForm);
  const profileInfo = useSelector(selectAgentProfileInformation);
  const initialFormValues = {
    firstName: profileInfo.agentProfessionalProfile?.firstName ?? '',
    businessName: profileInfo?.businessName,
    businessMailingAddress: profileInfo?.businessMailingAddress ?? null,
    businessSuite: profileInfo?.businessSuite ?? null,
    businessCity: profileInfo?.businessCity ?? null,
    businessState: profileInfo?.businessState ?? null,
    businessZipCode: profileInfo?.businessZipCode,
    businessPhoneNumber: profileInfo?.businessPhoneNumber,
    businessPhoneType: lodash.capitalize(profileInfo.businessPhoneType ?? ''),
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={AgentBusinessProfileSchema}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        const body = {
          mailingAddress: values?.businessMailingAddress,
          suite: values?.businessSuite,
          city: values?.businessCity,
          state: values?.businessState,
          zipCode: values?.businessZipCode,
          phoneType: values?.businessPhoneType?.toLocaleLowerCase(),
          phoneNumber: values?.businessPhoneNumber,
        };

        updateAgentBusinessProfile(body, currentOrganization?.id)
          .then(() => {
            dispatch(getAgentProfileInformation());
          })
          .finally(() => {
            setIsEditable(false);
            setSubmitting(false);
          });
      }}
    >
      {({ handleSubmit, isSubmitting, isValid, resetForm, dirty: isDirty }) => (
        <Form className={styles.FormikMainContainer}>
          <div className={classNames(styles.SecondContainer, { [styles.SecondViewContainer]: isEditable })}>
            <RCButton className={styles.EditButton} variant="outline" onClick={() => setIsEditable(true)}>
              {t('application.edit')}
            </RCButton>
            <div className={styles.AdministratorContainer}>
              <div className={styles.HeadingText}>
                {t('agent.settings.details.professional.details.organization.heading')}
              </div>
            </div>
            <span className={styles.BoxText}>{t('agent.settings.details.professional.details.organization.text')}</span>

            <Row>
              <Col lg={3} md={8} sm={12} className={styles.OptionHeading}>
                {t('agent.settings.details.professional.details.organization.business')}
              </Col>
              <Col lg={9} md={12} sm={12} className={styles.TextAlignForDropDown}>
                <FormikField
                  className={!isEditable ? styles.DisableFieldViewStyle : styles.DisableBusiness}
                  name="businessName"
                  disabled={true}
                />
              </Col>
            </Row>
            <div className={styles.VisibityContainer}>
              <div className={styles.Visibitytext}>
                {t('agent.settings.details.professional.details.organization.visibility')}{' '}
                <InfoIcon width={18} height={18} />
              </div>
              <Col lg={1} md={2} sm={2} className={classNames(styles.TextAlignForDropDown, styles.AnyoneContainer)}>
                <ReactSelect placeholder="Anyone" isSearchable={false} backgroundColor="none" borderStyle="none" />
              </Col>
            </div>
            <Row>
              <Col lg={3} md={8} sm={12} className={styles.OptionHeading}>
                {t('agent.settings.details.professional.details.organization.address')}
              </Col>
              <Col lg={6} md={8} sm={12} className={styles.TextAlignForDropDown}>
                <PlacesAutocompleteComponent
                  addressField="businessMailingAddress"
                  zipField="businessZipCode"
                  stateField="businessState"
                  cityField="businessCity"
                  setIsError={setIsAddressError}
                  shouldAcceptCustomAddress
                  placeholder="Type to search"
                  labelField="Mailing Street Address"
                  className={styles.BusinessAddressStyle}
                  shouldTrimAddress
                  disabled={!isEditable}
                />
              </Col>
              <Col lg={3} md={4} sm={12}>
                <FormikField
                  className={styles.DisableFieldViewStyle}
                  name="businessSuite"
                  label="Suite (Optional)"
                  disabled={!isEditable}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={3} md={8} sm={12}></Col>
              <Col lg={6} md={8} sm={12} className={styles.TextAlignForDropDown}>
                <FormikField
                  className={styles.DisableFieldViewStyle}
                  name="businessCity"
                  type="text"
                  placeholder="City"
                  label="City"
                  disabled={!isEditable}
                />
              </Col>
              <Col lg={1} md={2} sm={12} className={styles.stateSelectField}>
                <span className={styles.textFieldLabel}>{t('state')}</span>
                <StateSelectField
                  name="businessState"
                  backgroundColor="#ffffff"
                  placeholder="Select"
                  statesArray={US_STATES}
                  className={styles.BusinessStateStyle}
                  isDisabled={!isEditable}
                />
              </Col>
              <Col lg={2} md={2} sm={12}>
                <FormikField
                  className={styles.DisableFieldViewStyle}
                  name="businessZipCode"
                  type="number"
                  placeholder="Zip"
                  label="Zip"
                  onWheel={(event) => event.currentTarget.blur()}
                  disabled={!isEditable}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={3} md={8} sm={12} className={styles.OptionHeading}>
                {t('agent.settings.details.professional.details.organization.phone.optional')}
              </Col>
              <Col lg={6} md={8} sm={12}>
                <FormikPhoneNumber
                  placeholder="(  )  -"
                  className={styles.DisableFieldViewStyle}
                  name="businessPhoneNumber"
                  isDisabled={!isEditable}
                  isShowCountryCode={false}
                />
              </Col>
              <Col lg={3} md={4} sm={12} className={styles.stateSelectField}>
                <span className={styles.textFieldLabel}>{t('agent.profile.phoneType.text')}</span>
                <FormikSelectField
                  name="businessPhoneType"
                  backgroundColor="#ffffff"
                  placeholder={t('renter.realtionship.select')}
                  options={PhoneNoTypes}
                  data-testId="buisnessPhoneTypeSelector"
                  isDisabled={!isEditable}
                  borderStyle="1px solid #D2D6D6"
                />
              </Col>
            </Row>
          </div>
          {isEditable && (
            <div className={styles.ButtonContainerSettings}>
              <SettingsSaveRow
                handleSaveClick={handleSubmit}
                handleCancelClick={() => {
                  setIsEditable(false);
                  resetForm();
                }}
                isSubmitting={isSubmitting}
                isValid={isValid}
                isDirty={isDirty}
                isAddresError={isAddresError}
              />
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};
