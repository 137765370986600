import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Modal as BModal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SingleValue } from 'react-select';
import { selectedRenterDetails, selectRenterApplication } from 'redux/selectors/agentSelector';

import { ReactComponent as ArrowDown } from 'assets/svgs/BlackDropdownArrow.svg';
import { ReactComponent as CrossIcon } from 'assets/svgs/GreyCrossIcon.svg';
import AgentModal from 'components/Agent/components/AgentModal/AgentModal';
import RCButton from 'components/shared/Button/Button';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import { ExportResumeOptions } from 'constants/dropdownOptions';
import { getApplicationStatus } from 'helpers/agentHelper';
import { RenterResumeActions } from 'shared/types/agentTypes';
import { SelectOption } from 'shared/types/selectOption';

import { RenterInformationHeader } from '../../RenterIncomeReports/RenterInformationHeader/RenterInformationHeader';

import styles from './ExportModal.module.scss';

type Props = {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  exportToCSV: () => void;
  isPreparingPdf: boolean;
  setIsModalOpenForPdfSection: Dispatch<SetStateAction<boolean>>;
};

export const ExportModal = ({
  setIsModalOpen,
  exportToCSV,
  isPreparingPdf,
  setIsModalOpenForPdfSection,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const { firstName, lastName } = useSelector(selectedRenterDetails);
  const { adults, minors, isConfirmed } = useSelector(selectRenterApplication);
  const [selectedValue, setSelectedValue] = useState<SelectOption<string | number>>(ExportResumeOptions[0]);
  const totalOccupants = useMemo(() => (adults?.length ?? 0) + (minors?.length ?? 0) + 1, [adults, minors]);
  const onChangeHandler = (option: SingleValue<SelectOption<string | number>>): void => {
    option && setSelectedValue(option);
  };
  const handleExportClick = (): void => {
    if (selectedValue.label === RenterResumeActions.PDF) {
      setIsModalOpenForPdfSection(true);
      setIsModalOpen(false);
    }

    if (selectedValue.label === RenterResumeActions.CSV) {
      exportToCSV();
    }
  };

  return (
    <AgentModal
      show
      onHide={() => setIsModalOpen(false)}
      customTitleClassName={styles.modalTitle}
      customContainerClassName={styles.CustomHeader}
      customModalClassName={styles.ModalContainer}
      customCrossIcon={CrossIcon}
      crossButtonClassName={styles.CancelButton}
      buttonText={t('application.cancel')}
    >
      <>
        <BModal.Header className={styles.HeaderContainer} />
        <BModal.Body className={styles.BodyContainer}>
          <div className={styles.ContentContainer}>
            <div className={styles.UserInformationContainer}>
              <div className={styles.IncomeHeading}>{t('application.renterResume.export')}</div>
              <RenterInformationHeader
                renterName={`${firstName} ${lastName}`}
                totalOccupants={totalOccupants}
                status={getApplicationStatus(!!isConfirmed)}
              />
            </div>
            <div className={styles.ExportContainer}>
              <span className={styles.Title}>{t('application.renterResume.prepareExport')}</span>
              <ReactSelect
                options={ExportResumeOptions}
                value={selectedValue}
                onChange={onChangeHandler}
                dropDownIcon={<ArrowDown />}
                dropDownIconContainer={styles.DropDownIconContainer}
                floatingLabel={t('application.renterResume.fileFormat')}
                hasFloatingLabel={true}
                isSearchable={false}
              />
              <div className={styles.ButtonContainer}>
                <RCButton onClick={handleExportClick} disabled={isPreparingPdf}>
                  {isPreparingPdf ? (
                    <span>
                      <Spinner className={styles.PdfSpinner} /> Preparing...
                    </span>
                  ) : (
                    t('application.export')
                  )}
                </RCButton>
              </div>
            </div>
          </div>
        </BModal.Body>
      </>
    </AgentModal>
  );
};
