import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { logoutSuccess, validatePassword } from 'redux/slices/authSlice';
import { AppThunkDispatch } from 'redux/store';
import { Login2FASchema } from 'schema/authSchema';

import { ReactComponent as PasswordIcon } from 'assets/svgs/PasswordIcon.svg';
import { ReactComponent as PasswordSlashIcon } from 'assets/svgs/PasswordSlashIcon.svg';
import RCButton from 'components/shared/Button/Button';
import Spinner from 'components/shared/Spinner/Spinner';
import FormikField from 'components/shared/TextField/FormikField';
import { StepsOptions2FA } from 'constants/agentConstants';
import { routes } from 'shared/routes';
import { TwoFactorComponentProps } from 'shared/types/sharedTypes';

import styles from '../Modal2FA.module.scss';

export const ConfirmPassword = memo(({ setModalSteps, setIsModalOpen }: TwoFactorComponentProps): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const handleForgotPasswordClick = (): void => {
    dispatch(logoutSuccess());
  };
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.ModalBodyText}>
        <p className={styles.FontStyle2}>{t('otpModal.2FASetup')}</p>
        <p className={styles.FontStyle3}>{t('otpModal.2FApassword')}</p>
      </div>
      <Formik
        initialValues={{ password: '' }}
        validationSchema={Login2FASchema}
        onSubmit={({ password }, { setSubmitting, setErrors }) => {
          dispatch(
            validatePassword({
              password,
            })
          )
            .unwrap()
            .then(() => {
              setModalSteps(StepsOptions2FA.NUMBER);
            })
            .catch((error) => {
              setErrors(error);
            })
            .finally(() => setSubmitting(false));
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <FormikField
              className={classNames('inputBackgroundColor', styles.PasswordField)}
              name="password"
              type={isPasswordVisible ? 'text' : 'password'}
              label="Password"
              placeholder="Password"
              onIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
              customIcon={isPasswordVisible ? <PasswordSlashIcon /> : <PasswordIcon />}
            />
            <div className={styles.ForgotPassword}>
              <Link
                className={styles.ForgotPasswordLink}
                onClick={handleForgotPasswordClick}
                to={routes.forgotPassword}
              >
                {t('otpModal.2FAforgot')}
              </Link>
            </div>
            <div className={styles.ButtonContainer2FA}>
              <RCButton
                onClick={() => {
                  setIsModalOpen(false);
                }}
                variant="outline"
                disabled={isSubmitting}
                className={styles.CancelButton}
              >
                {t('renter.phoneNumber.cancel')}
              </RCButton>
              <RCButton variant="primary" type="submit" disabled={isSubmitting || !isValid} className={styles.Continue}>
                {isSubmitting ? (
                  <span>
                    <Spinner />
                  </span>
                ) : (
                  t('renter.guarantorInformationForm.continue')
                )}
              </RCButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
});
