import { Defs, G, Path, Rect, Svg } from '@react-pdf/renderer';

export const CheckedOptionLogo = (): JSX.Element => (
  <Svg width="14" height="15" viewBox="0 0 14 15" fill="none">
    <G clip-path="url(#clip0_11757_100970)">
      <Path
        d="M10.5 2.8335H3.49998C2.85831 2.8335 2.33331 3.3585 2.33331 4.00016V11.0002C2.33331 11.6418 2.85831 12.1668 3.49998 12.1668H10.5C11.1416 12.1668 11.6666 11.6418 11.6666 11.0002V4.00016C11.6666 3.3585 11.1416 2.8335 10.5 2.8335ZM10.5 11.0002H3.49998V4.00016H10.5V11.0002Z"
        fill="#5E6566"
      />
      <G clip-path="url(#clip1_11757_100970)">
        <Path
          d="M2.91669 4.4165C2.91669 3.86422 3.3644 3.4165 3.91669 3.4165H10.0834C10.6356 3.4165 11.0834 3.86422 11.0834 4.4165V10.5832C11.0834 11.1355 10.6356 11.5832 10.0834 11.5832H3.91669C3.3644 11.5832 2.91669 11.1355 2.91669 10.5832V4.4165Z"
          fill="#8D9899"
        />
        <Path
          d="M5.97918 8.91899L4.56022 7.50003L4.07703 7.97982L5.97918 9.88197L10.0625 5.79864L9.58272 5.31885L5.97918 8.91899Z"
          fill="white"
        />
      </G>
    </G>
    <Defs>
      <clipPath id="clip0_11757_100970">
        <Rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
      </clipPath>
      <clipPath id="clip1_11757_100970">
        <rect width="8.16667" height="8.16667" fill="white" transform="translate(2.91669 3.4165)" />
      </clipPath>
    </Defs>
  </Svg>
);
