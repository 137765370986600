import { isBefore, isEqual } from 'date-fns';
import { t } from 'i18next';
import * as Yup from 'yup';

export const RentalFormSchema = [
  Yup.object().shape({
    fullName: Yup.string().optional().nullable(),
    companyName: Yup.string()
      .optional()
      .nullable()
      .min(2, t('renter.verifier.companyNameLength'))
      .max(30, t('renter.verifier.companyNameLength')),
    emailAddress: Yup.string().email().optional().nullable(),
    phoneNumber: Yup.string().optional().nullable(),
    rejectedReason: Yup.string().required(t('renter.relationship.rejectedReason')),
    privacyConsent: Yup.bool().oneOf([true], t('renter.verifier.privacyConsent')),
  }),
  Yup.object().shape({
    fullName: Yup.string().optional().nullable(),
    titleOfVerifier: Yup.string()
      .optional()
      .nullable()
      .min(2, t('renter.verifier.companyNameLength'))
      .max(30, t('renter.verifier.companyNameLength')),
    emailAddress: Yup.string().email().optional().nullable(),
    phoneNumber: Yup.string().optional().nullable(),
    rejectedReason: Yup.string().required(t('renter.relationship.rejectedReason')),
    privacyConsent: Yup.bool().oneOf([true], t('renter.verifier.privacyConsent')),
  }),
];
export const AddressHistoryQuestionnaireSchema = Yup.object().shape({
  verificationQuestions: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().optional(),
      answer: Yup.string().min(1).required('Answer is required'),
      meta: Yup.object().when(['answer', 'acceptedReason'], {
        is: (answer: string, acceptedReason: string) =>
          answer && answer.toLowerCase() !== acceptedReason?.toLowerCase(),
        then: Yup.object().test('meta-values', (value, context) => {
          if (
            context.parent?.answer &&
            context.parent?.answer?.toLowerCase() !== context.parent.acceptedReason?.toLowerCase() &&
            context.parent?.answer?.toLowerCase() !== 'na' &&
            context.parent.questionType === 'DATE'
          ) {
            if (!value || !value.fromDate || !value.toDate) {
              return context.createError({
                path: `verificationQuestions.${context.parent.id - 1}.meta.fromDate`,
                message: t('renter.verification.validDateValidation'),
              });
            }

            const fromDate = new Date(value.fromDate);
            const toDate = new Date(value.toDate);
            const isValidDateRange = isBefore(fromDate, toDate) || isEqual(fromDate, toDate);

            if (!isValidDateRange) {
              return context.createError({
                path: `verificationQuestions.${context.parent.id - 1}.meta.fromDate`,
                message: t('renter.verification.dateValidation'),
              });
            }
          }

          if (
            context.parent?.answer &&
            context.parent.answer?.toLowerCase() !== context.parent.acceptedReason?.toLowerCase() &&
            context.parent.answer?.toLowerCase() !== 'na' &&
            context.parent.questionType === 'DD_NUM' &&
            !value?.numberDetails
          ) {
            return context.createError({
              path: `verificationQuestions.${context.parent.id - 1}.meta.numberDetails`,
              message: t('renter.verification.selectValidation'),
            });
          }

          if (
            context.parent?.answer &&
            context.parent.answer?.toLowerCase() !== context.parent.acceptedReason?.toLowerCase() &&
            context.parent.answer?.toLowerCase() !== 'na' &&
            context.parent.questionType === 'TEXT' &&
            !value?.textDetails
          ) {
            return context.createError({
              path: `verificationQuestions.${context.parent.id - 1}.meta.textDetails`,
              message: t('renter.verification.textDateValidation'),
            });
          }

          if (
            context.parent?.answer &&
            context.parent.answer?.toLowerCase() !== context.parent.acceptedReason?.toLowerCase() &&
            context.parent.answer?.toLowerCase() === 'na' &&
            !value?.textDetails
          ) {
            return context.createError({
              path: `verificationQuestions.${context.parent.id - 1}.meta.textDetails`,
              message: t('renter.verification.textDateValidation'),
            });
          }

          return true;
        }),
        otherwise: Yup.object().strip(),
      }),
    })
  ),
  submitVerifier: Yup.object().shape({
    phoneNumber: Yup.string().required(t('renter.verifier.phoneNumber')),
    fullName: Yup.string().required(t('renter.verifier.contactFullName')),
    emailAddress: Yup.string()
      .email(t('renter.verifier.invalidEmail'))
      .required(t('renter.verifier.contactEmailAddress')),
    companyName: Yup.string().optional(),
    relationshipProperty: Yup.string().required(t('renter.verifier.relationshipProperty')),
    privacyConsent: Yup.bool().oneOf([true], t('renter.verifier.privacyConsent')),
  }),
});

export const EmploymentHistoryQuestionnaireSchema = Yup.object({
  verificationQuestions: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().optional(),
      answer: Yup.string().min(1).required('Answer is required'),
      meta: Yup.object().when(['answer', 'acceptedReason'], {
        is: (answer: string, acceptedReason: string) =>
          answer && answer.toLowerCase() !== acceptedReason?.toLowerCase(),
        then: Yup.object().test('meta-values', (value, context) => {
          if (
            context.parent?.answer &&
            context.parent?.answer?.toLowerCase() !== context.parent.acceptedReason?.toLowerCase() &&
            context.parent?.answer?.toLowerCase() !== 'na' &&
            context.parent.questionType === 'DATE'
          ) {
            if (!value || !value.fromDate || !value.toDate) {
              return context.createError({
                path: `verificationQuestions.${context.parent.id - 9}.meta.fromDate`,
                message: t('renter.verification.validDateValidation'),
              });
            }

            const fromDate = new Date(value.fromDate);
            const toDate = new Date(value.toDate);
            const isValidDateRange = isBefore(fromDate, toDate) || isEqual(fromDate, toDate);

            if (!isValidDateRange) {
              return context.createError({
                path: `verificationQuestions.${context.parent.id - 9}.meta.fromDate`,
                message: t('renter.verification.dateValidation'),
              });
            }
          }

          if (
            context.parent?.answer &&
            context.parent.answer?.toLowerCase() !== context.parent.acceptedReason?.toLowerCase() &&
            context.parent.answer?.toLowerCase() !== 'na' &&
            context.parent.questionType === 'TEXT' &&
            !value?.textDetails
          ) {
            return context.createError({
              path: `verificationQuestions.${context.parent.id - 9}.meta.textDetails`,
              message: t('renter.verification.textDateValidation'),
            });
          }

          if (
            context.parent?.answer &&
            context.parent.answer?.toLowerCase() !== context.parent.acceptedReason?.toLowerCase() &&
            context.parent.answer?.toLowerCase() === 'na' &&
            !value?.textDetails
          ) {
            return context.createError({
              path: `verificationQuestions.${context.parent.id - 9}.meta.textDetails`,
              message: t('renter.verification.textDateValidation'),
            });
          }

          return true;
        }),
        otherwise: Yup.object().strip(),
      }),
    })
  ),
  submitVerifier: Yup.object().shape({
    phoneNumber: Yup.string().required(t('renter.verifier.phoneNumber')),
    fullName: Yup.string().required(t('renter.verifier.contactFullName')),
    emailAddress: Yup.string()
      .email(t('renter.verifier.invalidEmail'))
      .required(t('renter.verifier.contactEmailAddress')),
    companyName: Yup.string().optional(),
    relationshipProperty: Yup.string().required(t('renter.verifier.relationshipProperty')),
    privacyConsent: Yup.bool().oneOf([true], t('renter.verifier.privacyConsent')),
  }),
});
