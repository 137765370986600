import { PropertyApplications } from 'redux/slices/agentSlice';

import {
  AddressVerificationResponse,
  AgentProfileInformationProps,
  LegacyApplicantsProps,
  LegacyApplicantsTotalCount,
} from 'shared/types/agentTypes';
import { ApplicationType } from 'shared/types/applicantsType';
import { PaginationType } from 'shared/types/paginationTypes';
import { AddPropertyParams } from 'shared/types/propertyType';
import { AddressInformationResponse, RenterProfileInformationProps } from 'shared/types/renterTypes';
import {
  BackgroundData,
  CreditProfileReport,
  CriminalReportItem,
  EvictionReportItem,
  NovaReport,
} from 'shared/types/reportTypes';

import { RootState } from '../types';

export const isAgentLoading = (state: RootState): boolean => state.agentSlice.isLoading;
export const agentId = (state: RootState): number | undefined => state.agentSlice.profileInformation.agentId;
export const selectAgentProfileInformation = (state: RootState): AgentProfileInformationProps =>
  state.agentSlice.profileInformation;

//Selected Renter Selector
//All the Details
export const selectedRenterDetails = (state: RootState): RenterProfileInformationProps =>
  state.agentSlice.selectedRenter;
export const selectRenterNovaReport = (state: RootState): NovaReport => state.agentSlice.renterNovaReport;
//Renter Addresses
export const selectedRenterAddress = (state: RootState): AddressInformationResponse[] | undefined =>
  state.agentSlice.selectedRenter.addresses;

//First Application Guarantor
export const selectPropertyApplications = (state: RootState): PropertyApplications =>
  state.agentSlice.propertyApplications;
// Experian BackGround Report For Super Admin
export const selectBackgroundReportForSuperadmin = (state: RootState): BackgroundData =>
  state.agentSlice?.renterExperianReports?.reports?.BackgroundData;
//Renter Experian Credit Profile
export const selectRenterCreditProfile = (state: RootState): CreditProfileReport =>
  state.agentSlice.renterExperianReports?.reports?.CreditProfile;
//Renter Experian Background Data Report
export const selectRenterBackgroundDataReport = (state: RootState): BackgroundData =>
  state.agentSlice.renterExperianReports?.reports?.BackgroundData;
//Renter Experian Eviction Report
export const selectRenterEvictionReport = (state: RootState): EvictionReportItem =>
  state.agentSlice.renterExperianReports?.reports?.BackgroundData?.report?.evictionReport ?? ({} as EvictionReportItem);
//Renter Experian Criminal Report
export const selectRenterCriminalReport = (state: RootState): CriminalReportItem =>
  state.agentSlice.renterExperianReports?.reports?.BackgroundData?.report?.criminalReport ?? ({} as CriminalReportItem);

export const selectRenterApplication = (state: RootState): ApplicationType =>
  state.agentSlice.selectedRenterApplication;

export const selectCurrentProperty = (state: RootState): AddPropertyParams => state.agentSlice.selectedProperty;

export const selectVerifierAddressInformation = (state: RootState): AddressVerificationResponse[] =>
  state.agentSlice.verifierAddressInformation;

export const selectVerifierEmploymentInformation = (state: RootState): AddressVerificationResponse[] =>
  state.agentSlice.verifierEmploymentInformation;
export const selectLegacyApplicants = (state: RootState): LegacyApplicantsProps[] => state.agentSlice.legacyApllicants;
export const selectLegacyApplicantsCount = (state: RootState): LegacyApplicantsTotalCount =>
  state.agentSlice.leagacyCount;
export const paginatedLegacyValues = (state: RootState): PaginationType => state.agentSlice.pageInfo;
// export const paginatedLegacyValues = (
//   state: RootState,
//   legacyType?: string // Add the propertyType parameter
// ): {
//   legacyApllicants: LegacyApplicantsProps[];
//   pageInfo: PaginationType;
// } => {
//   // Get all properties from the state
//   let filteredLegacyApplicants = state.agentSlice.legacyApllicants;

//   // If propertyType is provided, filter properties by propertyType
//   if (legacyType && filteredLegacyApplicants) {
//     filteredLegacyApplicants = filteredLegacyApplicants.filter((legacy) => legacy.status === legacyType);
//   }

//   // Return the filtered Legacy Applicants and pageInfo
//   return {
//     legacyApllicants: filteredLegacyApplicants,
//     pageInfo: state.agentSlice.pageInfo,
//   };
// };
