import { encryptData } from 'helpers/encrypt';
import {
  AuthValues,
  Enable2FAValues,
  GoogleAuthValues,
  ResentOtpValues,
  ResetPassworValues,
  Submit2FAValues,
  Verify2FAValues,
} from 'shared/types/authType';
import { AxiosPromise } from 'shared/types/axiosPromise';

import baseService from './baseService';

export const signup = (values: AuthValues): AxiosPromise =>
  baseService.post<unknown>('/user/sign-up', {
    ...values,
    password: encryptData(values.password),
  });

export const login = (values: AuthValues): AxiosPromise =>
  baseService.post<unknown>('/user/log-in', {
    ...values,
    password: encryptData(values.password),
  });
export const logout = (): AxiosPromise => baseService.post<string>('/user/log-out');

export const requestChangePassword = (email: string): AxiosPromise =>
  baseService.post<unknown>('/user/forget-password', { email });

export const resetPassword = (values: ResetPassworValues): AxiosPromise =>
  baseService.put('/user/reset-password', {
    ...values,
    password: encryptData(values.password),
  });

export const googleAuth = (values: GoogleAuthValues): AxiosPromise =>
  baseService.post<unknown>('/user/google-oauth', values);

export const getPosts = (): AxiosPromise => baseService.get<unknown>('https://jsonplaceholder.typicode.com/posts');

export const validatePassword = (password: string): AxiosPromise =>
  baseService.post<unknown>('/user/password', { password: encryptData(password) });

export const enable2FA = (values: Enable2FAValues): AxiosPromise =>
  baseService.post<unknown>('/user/enable-2fa', values);

export const submit2faCode = (values: Submit2FAValues): AxiosPromise =>
  baseService.post<unknown>('/user/submit-otp', values);

export const verify2faCode = (values: Verify2FAValues): AxiosPromise =>
  baseService.post<unknown>('/user/verify-2fa-code', values);
export const resend2faCode = (values: ResentOtpValues): AxiosPromise =>
  baseService.post<unknown>('/user/resend-otp', values);
export const resend2faCodeViaToken = (values: ResentOtpValues): AxiosPromise =>
  baseService.post<unknown>('/user/confirm/resend-otp', values);
